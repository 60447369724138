import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_ACCOUNT_CUSTOMER_SUMMARY, MARKETING_PLATFORM_ACCOUNT_DETAILS } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type MarketingPlatformAccountDetails = {
	id: number;
	totalSubscribers: number;
	totalCampaignsSent: number;
	totalFailedValidations: number;
	totalSubscriberFailedValidations: number;
	totalProducts: number;
	activeChargeAmount: number;
	totalConversions: number;
	totalConversionsRevenue: number;
};

export function useMarketingPlatformAccountDetails(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<MarketingPlatformAccountDetails>;
	const { data: details, refetch: onLoadDetails } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_DETAILS, id), () =>
		get(`/marketing-platform-accounts/${id}/details`)
	);

	const customerSummary = useMarketingPlatformCustomerSummary(id);

	return { details, onLoadDetails, customerSummary };
}

export type CustomerSummary = {
	id: number;
	totalCustomers: number;
	totalOrders: number;
	totalRevenue: number;
};

function useMarketingPlatformCustomerSummary(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<CustomerSummary>;
	const { data: customerSummary } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_CUSTOMER_SUMMARY, id), () =>
		get(`/marketing-platform-accounts/${id}/customer-summary`)
	);
	return customerSummary;
}
