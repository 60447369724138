import { Loading, TopBar } from '@shopify/polaris';
import { AlertCircleIcon, CalendarTimeIcon, CashDollarIcon, DeleteIcon, ExitIcon, ForkliftIcon } from '@shopify/polaris-icons';
import { useIsFetching } from '@tanstack/react-query';
import md5 from 'md5';
import { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAccessToken } from '../AccessTokenProvider';
import ToastProvider from '../ToastProvider';
import { useUser } from '../hooks/useUser';
import { isLeadOrAdmin, isSupport } from './AccountView';
import AppFrame from './AppFrame';
import Dashboard from './Dashboard';
import EraseCustomerDataModal from './EraseCustomerDataModal';
import ShopView from './ShopView';
import BannerAccountView from './banner/BannerAccountView';
import BulkAccountView from './bulk/BulkAccountView';
import CanvaAccountView from './canva/CanvaAccountView';
import CanvaAnnouncements from './canva/CanvaAnnouncements';
import MailchimpConnectorAccountView from './mailchimpconnector/MailchimpConnectorAccountView';
import AllManualJobs from './marketingplatform/AllManualJobs';
import GlobalAppPurchases from './marketingplatform/GlobalAppPurchases';
import MarketingPlatformAccountView from './marketingplatform/MarketingPlatformAccountView';
import MarketingPlatformAnnouncements from './marketingplatform/MarketingPlatformAnnouncements';
import SendMonitoring from './marketingplatform/SendMonitoring';
import PopupAccountView from './popups/PopupAccountView';
import ReviewAccountView from './reviews/ReviewAccountView';

export default function Layout() {
	const isFetching = useIsFetching();
	const user = useUser();
	const { onLogout } = useAccessToken();
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [showCustomerDataErasureModal, setShowCustomerDataErasureModal] = useState(false);

	const onCloseCustomerDataErasureModal = useCallback(() => setShowCustomerDataErasureModal(false), []);

	const isLoading = isFetching > 0;

	function onToggleUserMenu() {
		setUserMenuOpen(userMenuOpen => !userMenuOpen);
	}

	const actionItems = [
		{
			content: 'Purchases',
			icon: CashDollarIcon,
			url: '/app-purchases'
		},
		{
			content: 'Manual jobs',
			icon: ForkliftIcon,
			url: '/manual-jobs'
		},
		{
			content: 'Send monitoring',
			icon: CalendarTimeIcon,
			url: '/send-monitoring'
		}
	];
	if (user && isSupport(user)) {
		actionItems.unshift({
			content: 'Customer erasure',
			icon: DeleteIcon,
			disabled: !isSupport(user),
			onAction: () => setShowCustomerDataErasureModal(true)
		});
	}

	const adminActionItems = [];
	if (user && isLeadOrAdmin(user)) {
		adminActionItems.push({
			content: 'Email announcements',
			icon: AlertCircleIcon,
			url: '/marketing-platform-announcements'
		});
		adminActionItems.push({
			content: 'Canva announcements',
			icon: AlertCircleIcon,
			url: '/canva-announcements'
		});
	}

	const userMenu = user && (
		<TopBar.UserMenu
			actions={[
				{ items: adminActionItems },
				{ items: actionItems },
				{ items: [{ content: 'Log out', icon: ExitIcon, onAction: onLogout }] }
			]}
			name={`${user.firstName} ${user.lastName}`}
			detail={user.role.split('_').join(' ')}
			initials={`${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`}
			avatar={`https://gravatar.com/avatar/${md5(user.email.toLowerCase())}?d=404`}
			onToggle={onToggleUserMenu}
			open={userMenuOpen}
		/>
	);

	return (
		<AppFrame topBar={<TopBar userMenu={userMenu} />}>
			{isLoading && <Loading />}
			<ToastProvider>
				{user && (
					<Routes>
						<Route path="accounts" element={<ShopView />} />
						<Route path="banner-accounts/:accountId" element={<BannerAccountView />} />
						<Route path="bulk-accounts/:accountId" element={<BulkAccountView />} />
						<Route path="marketing-platform-accounts/:accountId" element={<MarketingPlatformAccountView />} />
						<Route path="popup-accounts/:accountId" element={<PopupAccountView />} />
						<Route path="review-accounts/:accountId" element={<ReviewAccountView />} />
						<Route path="canva-accounts/:accountId" element={<CanvaAccountView />} />
						<Route path="mailchimp-connector-accounts/:accountId" element={<MailchimpConnectorAccountView />} />
						<Route path="app-purchases" element={<GlobalAppPurchases />} />
						<Route path="marketing-platform-announcements" element={<MarketingPlatformAnnouncements />} />
						<Route path="canva-announcements" element={<CanvaAnnouncements />} />
						<Route path="send-monitoring" element={<SendMonitoring />} />
						<Route path="manual-jobs" element={<AllManualJobs />} />
						<Route path="" element={<Dashboard />} />
					</Routes>
				)}
			</ToastProvider>
			<EraseCustomerDataModal open={showCustomerDataErasureModal} onClose={onCloseCustomerDataErasureModal} />
		</AppFrame>
	);
}
