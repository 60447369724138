import { Card, FormatMoney, TextStyle } from '@segunosoftware/equinox';
import { DataTable, Pagination, Tooltip } from '@shopify/polaris';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { APP_PURCHASE_BILLING_STATUS, APP_PURCHASE_TYPE } from '../../hooks/marketingplatform/useGlobalAppPurchases';
import AppPurchaseFilters from '../AppPurchaseFilters';

const MAX_ITEMS = 10;

MarketingPlatformAccountAppPurchases.propTypes = {
	appPurchases: PropTypes.array.isRequired,
	isLoading: PropTypes.bool
};

export default function MarketingPlatformAccountAppPurchases({ appPurchases, isLoading = false }) {
	const [page, setPage] = useState(0);
	const emptySearch = { identifier: null, type: null, status: null };
	const [purchaseSearch, setPurchaseSearch] = useState(emptySearch);

	function formatMoney(amount) {
		return <FormatMoney amount={amount / 100} />;
	}

	function onSetFilterProperty(property, value = null) {
		setPurchaseSearch({ ...purchaseSearch, [property]: value });
	}

	const filteredPurchases = appPurchases.filter(({ purchaseIdentifier, purchaseType, billingStatus }) => {
		if (purchaseSearch.identifier && !purchaseIdentifier.toLowerCase().includes(purchaseSearch.identifier.toLowerCase())) {
			return false;
		}
		if (purchaseSearch.type && purchaseSearch.type[0] !== purchaseType) {
			return false;
		}
		if (purchaseSearch.status && purchaseSearch.status[0] !== billingStatus) {
			return false;
		}
		return true;
	});

	const { totalRevenue, totalTemplates, totalCampaigns } = filteredPurchases
		.filter(p => p.billingStatus === APP_PURCHASE_BILLING_STATUS.ACTIVE)
		.reduce(
			({ totalRevenue, totalTemplates, totalCampaigns }, curr) => {
				totalRevenue += curr.price;
				curr.purchaseType === APP_PURCHASE_TYPE.CAMPAIGN ? totalCampaigns++ : totalTemplates++;
				return { totalRevenue, totalTemplates, totalCampaigns };
			},
			{ totalRevenue: 0, totalTemplates: 0, totalCampaigns: 0 }
		);

	const rows = filteredPurchases
		.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS)
		.map(({ purchaseIdentifier, purchaseType, billingStatus, price, billingActivatedAt }) => {
			return [
				purchaseIdentifier,
				purchaseType === APP_PURCHASE_TYPE.CAMPAIGN ? 'Campaign' : 'Template',
				billingStatus === APP_PURCHASE_BILLING_STATUS.ACTIVE ? 'Active' : 'Pending',
				billingActivatedAt && (
					<Tooltip content={format(billingActivatedAt, 'eeee, MMMM do yyyy, h:mm:ss a')}>
						<TextStyle>{format(billingActivatedAt, 'yyyy-MM-dd')}</TextStyle>
					</Tooltip>
				),
				formatMoney(price)
			];
		});

	return (
		!isLoading &&
		appPurchases.length > 0 && (
			<Card
				title="Purchases"
				actions={[
					{
						content: (
							<Pagination
								hasPrevious={page > 0}
								hasNext={page < Math.ceil(filteredPurchases.length / MAX_ITEMS) - 1}
								onNext={() => setPage(page + 1)}
								onPrevious={() => setPage(page - 1)}
							/>
						)
					}
				]}>
				<Card.Section>
					<AppPurchaseFilters
						identifier={purchaseSearch.identifier}
						type={purchaseSearch.type}
						status={purchaseSearch.status}
						onChangeFilter={onSetFilterProperty}
						onClearAll={() => setPurchaseSearch(emptySearch)}
					/>
					<DataTable
						columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
						headings={['Name', 'Type', 'Status', 'Activated at', 'Price']}
						rows={rows}
						totals={['', '', `${totalCampaigns} campaign(s)`, `${totalTemplates} template(s)`, formatMoney(totalRevenue)]}
						verticalAlign="middle"
						hideScrollIndicator
						showTotalsInFooter
						truncate
					/>
				</Card.Section>
			</Card>
		)
	);
}
