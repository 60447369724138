import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_SENDING_DOMAINS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useMarketingPlatformAccountSendingDomains(id) {
	const { get } = useAuthenticatedFetch();
	const { data: sendingDomains, isFetching: isLoading } = useQuery(queryKey(MARKETING_PLATFORM_SENDING_DOMAINS, id), () =>
		get(`/marketing-platform-accounts/${id}/sending-domains`)
	);
	return { sendingDomains, isLoading };
}
