import { ActionList, Button, Popover } from '@shopify/polaris';
import { useState } from 'react';
import type { App } from '../hooks/useApps';
import { getAppDetails, useApps } from '../hooks/useApps';

export type AppSwitcherProps = {
	shop: string;
	fromApp: App;
};

export default function AppSwitcher({ shop, fromApp }: AppSwitcherProps) {
	const [open, setOpen] = useState(false);
	const { apps, isLoading } = useApps(shop);

	const otherApps = apps.filter(install => install.app !== fromApp);

	if (otherApps.length === 0) {
		return null;
	}

	return (
		<Popover
			activator={
				<Button onClick={() => setOpen(!open)} disabled={isLoading} variant="tertiary" disclosure>
					Other apps
				</Button>
			}
			active={open}
			onClose={() => setOpen(false)}
			preferredAlignment="right">
			<ActionList
				items={otherApps
					.sort((a, b) => getAppDetails(a).name.localeCompare(getAppDetails(b).name))
					.map(install => ({
						content: `${getAppDetails(install).name} - ${install.billingStatus}`,
						url: `${getAppDetails(install).urlPrefix}/${install.id}`
					}))}
			/>
		</Popover>
	);
}
