export const USER = 'user';

export const APPS = 'apps';

export const DELEGATE_ACCESS_TOKEN = 'delegate-access-token';

export const INTERCOM_COMPANY = 'intercom-company';

export const BANNER_ACCOUNTS = 'banner-account';
export const BANNER_ACCOUNT_JOBS = 'banner-account-jobs';
export const BANNER_ACCOUNT_SCRIPT_TAG = 'banner-account-script-tag';
export const RECENT_BANNER_ACCOUNTS = 'recent-banner-accounts';

export const BULK_ACCOUNTS = 'bulk-account';
export const BULK_ACCOUNT_JOBS = 'bulk-account-jobs';
export const BULK_ACCOUNT_BILLING_DATE = 'bulk-account-billing-date';
export const BULK_ACCOUNT_DETAILS = 'bulk-account-details';
export const RECENT_BULK_ACCOUNTS = 'recent-bull-accounts';
export const DISCOUNT_SETS = 'discount-sets';
export const DISCOUNT_SET_PRICE_RULE = 'discount-set-price-rule';

export const MARKETING_PLATFORM_ACCOUNTS_AUTOMATIONS = 'marketing-platform-accounts-automations';
export const MARKETING_PLATFORM_ACCOUNTS_RECENT_NEWSLETTERS = 'marketing-platform-accounts-recent-newsletters';
export const MARKETING_PLATFORM_ACCOUNTS_SCHEDULED_NEWSLETTERS = 'marketing-platform-accounts-scheduled-newsletters';
export const MARKETING_PLATFORM_ACCOUNTS_DRAFT_NEWSLETTERS = 'marketing-platform-accounts-draft-newsletters';
export const MARKETING_PLATFORM_ACCOUNTS_RECENT_NEWSLETTER_CUSTOMER_GROUPS =
	'marketing-platform-accounts-recent-newsletters-customer-groups';
export const MARKETING_PLATFORM_ACCOUNTS_IP_POOLS = 'marketing-platform-accounts-ip-pools';
export const MARKETING_PLATFORM_ACCOUNT = 'marketing-platform-account';
export const MARKETING_PLATFORM_ACCOUNT_JOBS = 'marketing-platform-account-jobs';
export const MARKETING_PLATFORM_ACCOUNT_MAILCHIMP_DETAILS = 'marketing-platform-accounts-mailchimp-details';
export const MARKETING_PLATFORM_ACCOUNT_KLAVIYO_DETAILS = 'marketing-platform-accounts-klaviyo-details';
export const MARKETING_PLATFORM_ACCOUNT_DETAILS = 'marketing-platform-account-details';
export const MARKETING_PLATFORM_ACCOUNT_FACEBOOK_DETAILS = 'marketing-platform-account-facebook-details';
export const MARKETING_PLATFORM_ACCOUNT_BILLING_DATE = 'marketing-platform-account-billing-date';
export const MARKETING_PLATFORM_ACCOUNT_CUSTOMER_SUMMARY = 'marketing-platform-account-customer-summary';
export const MARKETING_PLATFORM_ACCOUNT_THEME_SUPPORT = 'marketing-platform-account-theme-support';
export const MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS = 'marketing-platform-account-manual-jobs';
export const MARKETING_PLATFORM_ACCOUNT_SUPPRESSION_TAGS = 'marketing-platform-account-suppression-tags';

export const MARKETING_PLATFORM_ANNOUNCEMENTS = 'marketing-platform-announcements';
export const MARKETING_PLATFORM_SENDING_DOMAINS = 'marketing-platform-sending-domains';
export const MARKETING_PLATFORM_SENDING_STATISTICS = 'marketing-platform-sending-statistics';
export const MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK = 'marketing-platform-account-resource-feedback';
export const RECENT_MARKETING_PLATFORM_ACCOUNTS = 'recent-marketing-platform-accounts';
export const MARKETING_PLATFORM_MANUAL_JOBS = 'marketing-platform-manual-jobs';

export const SEND_MONITORING_SENDING_USAGE = 'send-monitoring-sending-usage';
export const SEND_MONITORING_NEWSLETTER_PROGRESS = 'send-monitoring-newsletter-progress';
export const SEND_MONITORING_DELAYED_NEWSLETTERS = 'send-monitoring-delayed-newsletters';
export const SEND_MONITORING_MISSED_SCHEDULE = 'send-monitoring-missed-schedule';

export const POPUP_ACCOUNT = 'popup-account';
export const POPUP_ACCOUNT_JOBS = 'popup-account-jobs';
export const POPUP_ACCOUNT_DETAILS = 'popup-account-details';
export const POPUP_ACCOUNT_POPUPS = 'popup-account-popups';
export const POPUP_ACCOUNT_VERIFY = 'popup-account-verify';
export const POPUP_ACCOUNT_BILLING_DATE = 'popup-account-billing-date';
export const RECENT_POPUP_ACCOUNTS = 'recent-popup-accounts';
export const POPUP_BILLING_USAGE_SUMMARY = 'popup-billing-usage-summary';
export const POPUPS = 'popups';
export const POPUP_ENGAGEMENTS = 'popup-engagements';

export const MAILCHIMP_CONNECTOR_ACCOUNT = 'mailchimp-connector-account';
export const MAILCHIMP_CONNECTOR_ACCOUNT_JOBS = 'mailchimp-connector-account-jobs';
export const MAILCHIMP_CONNECTOR_ACCOUNT_DETAILS = 'mailchimp-connector-account-details';
export const RECENT_MAILCHIMP_CONNECTOR_ACCOUNTS = 'recent-mailchimp-connector-accounts';

export const MARKETING_PLATFORM_GLOBAL_APP_PURCHASES = 'marketing-platform-global-app-purchases';
export const MARKETING_PLATFORM_ACCOUNT_APP_PURCHASES = 'marketing-platform-account-app-purchases';

export const CANVA_ANNOUNCEMENTS = 'canva-announcements';
export const CANVA_ACCOUNT = 'canva-account';
export const CANVA_ACCOUNT_USERS = 'canva-account-users';
export const CANVA_ACCOUNT_JOBS = 'canva-account-jobs';
export const RECENT_CANVA_ACCOUNTS = 'recent-canva-accounts';

export const REVIEW_ACCOUNT = 'review-account';
export const REVIEW_ACCOUNT_JOBS = 'review-account-jobs';
export const RECENT_REVIEW_ACCOUNTS = 'recent-review-accounts';
export const REVIEW_ACCOUNT_IMPORT_JOBS = 'review-account-import-jobs';
export const REVIEW_ACCOUNT_SUMMARY = 'review-account-summary';
export const REVIEW_ACCOUNT_PUBLISHED_STATUS = 'review-account-published-status';
