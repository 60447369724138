import { queryKey, type Timestamp } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import {
	SEND_MONITORING_DELAYED_NEWSLETTERS,
	SEND_MONITORING_MISSED_SCHEDULE,
	SEND_MONITORING_NEWSLETTER_PROGRESS,
	SEND_MONITORING_SENDING_USAGE
} from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

const REFETCH_OPTIONS = {
	refetchInterval: 10_000,
	refetchIntervalInBackground: false,
	refetchOnWindowFocus: true,
	refetchOnReconnect: true
};

const SENDING_USAGE_REFETCH_INTERVAL = 60_000;

export type UsageLimit = {
	limit: number;
	used: number;
	start: Date;
	end: Date;
};

type DehydratedUsageLimit = UsageLimit & {
	start: Timestamp;
	end: Timestamp;
};

function hydrateUsageLimit(usageLimit: DehydratedUsageLimit): UsageLimit {
	return {
		...usageLimit,
		start: new Date(usageLimit.start),
		end: new Date(usageLimit.end)
	};
}

export type SendingUsage = {
	timestamp: Date;
	day: UsageLimit;
	month: UsageLimit;
};

type DehydratedSendingUsage = SendingUsage & {
	day: DehydratedUsageLimit;
	month: DehydratedUsageLimit;
	timestamp: Timestamp;
};

function hydrateSendingUsage(usage: DehydratedSendingUsage): SendingUsage {
	return {
		...usage,
		day: hydrateUsageLimit(usage.day),
		month: hydrateUsageLimit(usage.month),
		timestamp: new Date(usage.timestamp)
	};
}

export function useSendingUsage() {
	const { get } = useAuthenticatedFetch() as Get<DehydratedSendingUsage>;
	const { data, refetch, isLoading } = useQuery(
		queryKey(SEND_MONITORING_SENDING_USAGE),
		() => get('/marketing-platform-send-monitoring/sending-usage'),
		{
			...REFETCH_OPTIONS,
			refetchInterval: SENDING_USAGE_REFETCH_INTERVAL,
			select: hydrateSendingUsage
		}
	);

	return {
		sendingUsage: data,
		isLoadingSendingUsage: isLoading,
		refetchSendingUsage: refetch
	};
}

export type CampaignProgressSummary = {
	id: number;
	accountId: number;
	name: string;
	shop: string;
	scheduledAt: Date;
	billedSubscribers: number;
	totalDeliveries: number;
};

type DehydratedCampaignProgressSummary = CampaignProgressSummary & {
	scheduledAt: Timestamp;
};

function hydrateCampaignProgressSummary(summary: DehydratedCampaignProgressSummary): CampaignProgressSummary {
	return {
		...summary,
		scheduledAt: new Date(summary.scheduledAt)
	};
}

export function useNewsletterProgress() {
	const { get } = useAuthenticatedFetch() as Get<DehydratedCampaignProgressSummary[]>;
	const { data, refetch, isLoading } = useQuery(
		queryKey(SEND_MONITORING_NEWSLETTER_PROGRESS),
		() => get('/marketing-platform-send-monitoring/newsletter-progress'),
		{
			...REFETCH_OPTIONS,
			select: data => data.map(hydrateCampaignProgressSummary)
		}
	);

	return {
		newsletterProgressSummaries: data ?? [],
		isLoadingNewsletterProgress: isLoading,
		refetchNewsletterProgress: refetch
	};
}

export type DelayedCampaignSummary = {
	id: number;
	accountId: number;
	name: string;
	shop: string;
	scheduledAt: Date;
	sendCount: number;
	deliveryCount: number;
	bounceCount: number;
};

type DehydratedDelayedCampaignSummary = DelayedCampaignSummary & {
	scheduledAt: Timestamp;
};

function hydrateDelayedCampaignSummary(summary: DehydratedDelayedCampaignSummary): DelayedCampaignSummary {
	return {
		...summary,
		scheduledAt: new Date(summary.scheduledAt)
	};
}

export function useDelayedNewsletters() {
	const { get } = useAuthenticatedFetch() as Get<DehydratedDelayedCampaignSummary[]>;
	const { data, refetch, isLoading } = useQuery(
		queryKey(SEND_MONITORING_DELAYED_NEWSLETTERS),
		() => get('/marketing-platform-send-monitoring/delayed-newsletters'),
		{
			...REFETCH_OPTIONS,
			select: data => data.map(hydrateDelayedCampaignSummary)
		}
	);

	return {
		delayedNewsletters: data ?? [],
		isLoadingDelayedNewsletters: isLoading,
		refetchDelayedNewsletters: refetch
	};
}

export type MissedScheduleCampaign = {
	id: number;
	accountId: number;
	name: string;
	shop: string;
	scheduledAt: Date;
};

type DehydratedMissedScheduleCampaign = MissedScheduleCampaign & {
	scheduledAt: Timestamp;
};

function hydrateMissedScheduleCampaign(campaign: DehydratedMissedScheduleCampaign): MissedScheduleCampaign {
	return {
		...campaign,
		scheduledAt: new Date(campaign.scheduledAt)
	};
}

export function useMissedScheduleNewsletters() {
	const { get } = useAuthenticatedFetch() as Get<DehydratedMissedScheduleCampaign[]>;
	const { data, refetch, isLoading } = useQuery(
		queryKey(SEND_MONITORING_MISSED_SCHEDULE),
		() => get('/marketing-platform-send-monitoring/missed-schedule'),
		{
			...REFETCH_OPTIONS,
			select: data => data.map(hydrateMissedScheduleCampaign)
		}
	);

	return {
		missedScheduleNewsletters: data ?? [],
		isLoadingMissedScheduleNewsletters: isLoading,
		refetchMissedScheduleNewsletters: refetch
	};
}
