import { useCallback, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSetAccessToken } from '../AccessTokenProvider';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export function useLogin() {
	const { post } = useAuthenticatedFetch(false);
	const setAccessToken = useSetAccessToken();

	const { mutate, data, error, isLoading } = useMutation(({ email, password }) =>
		post(
			'/auth/login',
			`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
			{}, // No body sent for this since it's x-www-form-urlencoded
			{ 'Content-Type': 'application/x-www-form-urlencoded' }
		)
	);

	useEffect(() => {
		if (data) {
			setAccessToken(data.accessToken);
		}
	}, [data, setAccessToken]);

	const login = useCallback((email, password) => mutate({ email, password }), [mutate]);

	return { login, error: error?.body?.error, isLoading };
}
