import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { Route, Routes } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import Layout from './components/Layout';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';

export default function App() {
	return (
		<AppProvider i18n={translations} linkComponent={CustomLinkComponent}>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/*" element={<ProtectedRoute element={<Layout />} />} />
			</Routes>
		</AppProvider>
	);
}
