import { FormatMoney, FormatNumber, FormatPercent, getCurrencyCode, TextStyle, type Timestamp } from '@segunosoftware/equinox';
import styled from 'styled-components';

export type DiscountCodeType = 'NONE' | 'SINGLE_CODE' | 'GENERATED' | 'INHERITED';
export type DiscountEligibility = 'ALL' | 'NEW_PURCHASERS';
export type TargetType = 'line_item' | 'shipping_line';
export type TargetSelection = 'all' | 'entitled';
export type AllocationMethod = 'across' | 'each';
export type ValueType = 'fixed_amount' | 'percentage';
export type CustomerSelection = 'all' | 'prerequisite';
export type priceRuleItem = { id: number | string; type: string };
export type PriceRule = {
	id: number;
	entitledItems: priceRuleItem[];
	prerequisiteItems: priceRuleItem[];
	title: string;
	targetType: TargetType;
	targetSelection: TargetSelection;
	allocationMethod: AllocationMethod;
	valueType: ValueType;
	value: number;
	allocationLimit: number | null;
	oncePerCustomer: boolean;
	usageLimit: number | null;
	customerSelection: CustomerSelection;
	subtotalMinimum: number | null;
	shippingMaximum: number | null;
	quantityMinimum: number | null;
	prerequisiteQuantity: number;
	entitledQuantity: number;
	prerequisiteAmount: number;
	startsAt: Date;
	endsAt: Date | null;
	createdAt: Timestamp;
	updatedAt: Timestamp;
};

export const generateDiscountSummary = (priceRule: PriceRule, currency: string): JSX.Element | undefined => {
	if (!priceRule) {
		return;
	}

	const currencyCode = getCurrencyCode(currency);

	const isBuyOneGetOne =
		priceRule &&
		priceRule.targetType === 'line_item' &&
		priceRule.prerequisiteItems.length > 0 &&
		priceRule.entitledItems.length > 0 &&
		priceRule.targetSelection === 'entitled' &&
		(Number(priceRule.prerequisiteQuantity) > 0 || Number(priceRule.prerequisiteAmount) > 0) &&
		Number(priceRule.entitledQuantity) > 0;

	if (isBuyOneGetOne) {
		if (Number(priceRule.prerequisiteAmount) > 0) {
			return (
				<span>
					Spend <FormatMoney amount={priceRule.prerequisiteAmount} code={currencyCode} />, get{' '}
					<FormatNumber value={priceRule.entitledQuantity} /> for{' '}
					{Math.abs(priceRule.value) === 100 ? (
						'free'
					) : (
						<span>
							<FormatPercent value={Math.abs(priceRule.value)} /> off
						</span>
					)}
				</span>
			);
		}
		return (
			<span>
				Buy <FormatNumber value={priceRule.prerequisiteQuantity} />, get <FormatNumber value={priceRule.entitledQuantity} /> for{' '}
				{Math.abs(priceRule.value) === 100 ? (
					'free'
				) : (
					<span>
						<FormatPercent value={Math.abs(priceRule.value)} /> off
					</span>
				)}
			</span>
		);
	}
	if (priceRule.targetType === 'shipping_line') {
		return <span>Free shipping</span>;
	}
	if (priceRule.valueType === 'fixed_amount') {
		return (
			<span>
				<FormatMoney amount={Math.abs(priceRule.value)} code={currencyCode} /> off
			</span>
		);
	} else if (priceRule.valueType === 'percentage') {
		return (
			<span>
				<FormatPercent value={Math.abs(priceRule.value)} /> off
			</span>
		);
	}
	return;
};

type DiscountSummaryProps = {
	currency: string;
	priceRule: PriceRule;
	discountCode: string;
	discountCodeType: DiscountCodeType;
	discountEligibility: DiscountEligibility;
	resourceName?: string;
	inherited?: boolean;
};

export default function DiscountSummary({
	currency,
	priceRule,
	discountCode,
	discountCodeType,
	discountEligibility,
	resourceName,
	inherited = false
}: DiscountSummaryProps): JSX.Element | null {
	const summary = generateDiscountSummary(priceRule, currency);
	switch (discountCodeType) {
		case 'NONE':
			return <div>No discount is set for this {resourceName}.</div>;
		case 'SINGLE_CODE':
		case 'GENERATED':
			return (
				<div>
					{summary}
					<span> {priceRule.targetSelection === 'entitled' ? 'entitled items' : 'the entire order'}</span>
					{discountEligibility === 'NEW_PURCHASERS' && <span>, for new purchasers only,</span>}
					{discountCodeType === 'SINGLE_CODE' && (
						<span>
							{' '}
							using discount code{' '}
							<DiscountCodeStyle>
								<WordBreakAllSpan>{discountCode}</WordBreakAllSpan>
							</DiscountCodeStyle>
						</span>
					)}
					{!inherited && discountCodeType === 'GENERATED' && (
						<span>
							{' '}
							using unique codes from the{' '}
							<TextStyle variation="strong">
								<WordBreakAllSpan>{discountCode}</WordBreakAllSpan>
							</TextStyle>{' '}
							discount
						</span>
					)}
					{inherited && discountCodeType === 'GENERATED' && <span> using the same unique discount codes as last time</span>}
				</div>
			);
		default:
			return null;
	}
}

const WordBreakAllSpan = styled.span`
	word-break: break-all;
`;

const DiscountCodeStyle = styled.span`
	font-family: 'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono', 'Droid Sans Mono', 'Source Code Pro', monospace;
	text-transform: uppercase;
`;
