import { Card } from '@segunosoftware/equinox';
import styled from 'styled-components';
import { StarFilledIcon, StarIcon } from '@shopify/polaris-icons';
import { Badge, ProgressBar, Icon, SkeletonBodyText } from '@shopify/polaris';
import { useReviewPublishedStatus, useReviewSummary } from '../hooks/reviews/useReviewAccount';

const getCalcs = (ratings: any) => {
	const total = Object.keys(ratings).reduce((acc, key) => acc + ratings[key], 0);
	const sum = Object.keys(ratings).reduce((acc, key) => acc + Number(key) * ratings[key], 0);

	return {
		total: total,
		average: (total > 0 ? sum / total : 0).toFixed(1)
	};
};

const StarIconRating = ({ rating }: { rating: number }) => {
	const filledStar = <Icon source={StarFilledIcon} />;
	const unfilledStar = <Icon source={StarIcon} />;
	const stars = [];

	for (let i = 0; i < 5; i++) {
		if (i < rating) {
			stars.push(filledStar);
		} else {
			stars.push(unfilledStar);
		}
	}

	return <div style={{ display: 'flex' }}>{stars}</div>;
};

export const ReviewDetailsSummary = ({ id }: { id: string }) => {
	const { ratings, isLoading, isSuccess } = useReviewSummary(id);
	const { publishedStatus, isLoading: pubLoading, isSuccess: pubSuccess } = useReviewPublishedStatus(id);

	if (isLoading || pubLoading || !isSuccess || !pubSuccess) {
		return (
			<Card title="Review summary" sectioned>
				<SkeletonBodyText />
			</Card>
		);
	}
	const { total, average } = getCalcs(ratings);
	const { PUBLISHED, UNPUBLISHED } = publishedStatus;

	return (
		<Card title="Review summary" sectioned key={id + '-review-summary'}>
			<ReviewsAverageContainer>
				<ReviewsAverageBadge>
					<ReviewsAverageBadgeContent>
						<AverageRatingHeader>{average}</AverageRatingHeader>
						<div style={{ display: 'flex' }}>
							<Icon source={StarFilledIcon} />
							{total.toString() + ' total reviews'}
						</div>
					</ReviewsAverageBadgeContent>
					<PublishedBadge>
						{PUBLISHED && <Badge progress="complete">{PUBLISHED + ' published'}</Badge>}
						{UNPUBLISHED && <Badge progress="incomplete">{UNPUBLISHED + ' unpublished'}</Badge>}
					</PublishedBadge>
				</ReviewsAverageBadge>
				<RatingItems>
					{Object.keys(ratings)
						.map(key => (
							<RatingItem key={key}>
								<span style={{ width: '.5rem' }}>{key}</span>
								<StarIconRating rating={Number(key)} />
								<div style={{ width: '200px' }}>
									<ProgressBar progress={Math.round((ratings[key] / total) * 100)} />
								</div>
								{ratings[key].toString()}
							</RatingItem>
						))
						.sort((a, b) => Number(b.key) - Number(a.key))}
				</RatingItems>
			</ReviewsAverageContainer>
		</Card>
	);
};

const ReviewsAverageBadge = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	white-space: nowrap;
`;

const ReviewsAverageBadgeContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	align-items: center;
	gap: 1.5rem;
`;

const RatingItem = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const RatingItems = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const ReviewsAverageContainer = styled.div`
	display: flex;
	gap: 5rem;
	align-items: center;
	justify-items: center;
	width: 100%;
	padding: 2rem;
	box-sizing: border-box;
	justify-content: center;
`;

const PublishedBadge = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const AverageRatingHeader = styled.span`
	font-weight: 500;
	font-size: 3.5rem;
`;
