import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { BULK_ACCOUNT_DETAILS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useBulkAccountDetails(id) {
	const { get } = useAuthenticatedFetch();
	const { data: details, refetch: onLoadDetails } = useQuery(queryKey(BULK_ACCOUNT_DETAILS, id), () => get(`/bulk-accounts/${id}/details`));
	return { details, onLoadDetails };
}
