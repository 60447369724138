import { Button } from '@shopify/polaris';
import { useState } from 'react';
import { useRecentNewsletterCustomerGroups, type Newsletter } from '../../hooks/marketingplatform/useNewsletters';
import { CustomerGroupsModal } from './CustomerGroupsModal';

export type TargetRecipientsDescriptionProps = {
	accountId: number;
	newsletter: Newsletter;
};

export default function TargetRecipientsDescription({ accountId, newsletter }: TargetRecipientsDescriptionProps) {
	const { customerGroups, isLoadingCustomerGroups } = useRecentNewsletterCustomerGroups(accountId, newsletter.id);
	const [showCustomerGroupModal, setShowCustomerGroupModal] = useState(false);

	function getTargetRecipientsDescription() {
		if (isLoadingCustomerGroups) {
			return 'Loading...';
		}
		switch (newsletter.targetRecipients) {
			case 'ALL':
			case 'CUSTOMER_SAVED_SEARCHES':
				return customerGroups.length > 0 || newsletter.targetRecipients === 'CUSTOMER_SAVED_SEARCHES' ? (
					<Button onClick={() => setShowCustomerGroupModal(true)} variant="plain">
						Segments
					</Button>
				) : (
					'All'
				);
			case 'INHERITED_DELIVERIES':
				return 'Delivered parent';
			case 'INHERITED_OPENS':
				return 'Opened parent';
			case 'INHERITED_CLICKS':
				return 'Clicked parent';
			case 'INHERITED_NON_OPENS':
				return 'Non-opens (Remail)';
			case 'INHERITED_NON_CLICKS':
				return 'Non-clicks';
			default:
				return 'Unknown';
		}
	}

	return (
		<>
			{getTargetRecipientsDescription()}
			<CustomerGroupsModal
				open={showCustomerGroupModal}
				onClose={() => setShowCustomerGroupModal(false)}
				accountId={accountId}
				newsletter={newsletter}
			/>
		</>
	);
}
