import { useMemo } from 'react';
import { useAccessToken } from '../AccessTokenProvider';
import { useAPI } from '../APIProvider';
import HttpClient from '../lib/HttpClient';

const SUPPORTED_FUNCTIONS = ['get', 'post', 'put', 'patch', 'delete'];

export function useAuthenticatedFetch(authenticated = true) {
	const api = useAPI();
	const { accessToken } = useAccessToken();

	const fetchFunctions = useMemo(() => {
		const client = new HttpClient({ baseURL: api });
		if (authenticated) {
			client.setBearerAuth(accessToken);
		}
		return SUPPORTED_FUNCTIONS.reduce((acc, curr) => {
			acc[curr] = (...args) => client[curr](...args);
			return acc;
		}, {});
	}, [api, accessToken, authenticated]);

	return {
		...fetchFunctions
	};
}
