import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_GLOBAL_APP_PURCHASES } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export const APP_PURCHASE_TYPE = Object.freeze({ TEMPLATE: 'TEMPLATE', CAMPAIGN: 'GROUP' });
export const APP_PURCHASE_BILLING_STATUS = Object.freeze({ ACTIVE: 'ACTIVE', PENDING: 'PENDING' });

export default function useGlobalAppPurchases() {
	const { get } = useAuthenticatedFetch();

	function getActionPath(path) {
		return `/global-app-purchases/${path}`;
	}

	const { data: marketingPlatformPurchases, isFetching: isMarketingPlatformPurchasesLoading } = useQuery(
		queryKey(MARKETING_PLATFORM_GLOBAL_APP_PURCHASES),
		() => get(getActionPath('marketing-platform'))
	);

	return {
		marketingPlatformPurchases: marketingPlatformPurchases ?? [],
		isMarketingPlatformPurchasesLoading
	};
}
