import { NumberedLabel, Stack, getNumber } from '@segunosoftware/equinox';
import { Autocomplete, Button, Labelled, Tag } from '@shopify/polaris';
import { useMemo, useState } from 'react';
import { useMarketingPlatformAccountSuppressionTags } from '../../hooks/marketingplatform/useMarketingPlatformAccount';

export type SuppressionTagsFieldProps = {
	accountId: number | string;
	suppressionTags: string[];
	onChange: (suppressionTags: string[]) => void;
};

export default function SuppressionTagsField({ accountId, suppressionTags, onChange }: SuppressionTagsFieldProps) {
	const { suppressionTags: allSuppressionTags, isLoading } = useMarketingPlatformAccountSuppressionTags(accountId);
	const [inputValue, setInputValue] = useState('');

	const trimmedOptions = useMemo(
		() =>
			allSuppressionTags
				.filter(t => t.tag.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))
				.map(t => ({ value: t.tag, label: `${t.tag} (${getNumber(t.total)} emails)` })),
		[allSuppressionTags, inputValue]
	);

	return (
		<Labelled
			id=""
			label="Suppression groups"
			helpText={
				<Stack alignment="center" spacing="tight">
					<p>Select the groups to suppress for this account. </p>
					<Button onClick={() => onChange(allSuppressionTags.map(t => t.tag))} variant="plain">
						Select all
					</Button>
					<Button onClick={() => onChange([])} variant="plain">
						Deselect all
					</Button>
				</Stack>
			}
			labelHidden>
			<Autocomplete
				options={trimmedOptions}
				selected={suppressionTags}
				textField={
					<Autocomplete.TextField
						onChange={setInputValue}
						label="Groups"
						value={inputValue}
						placeholder=""
						verticalContent={suppressionTags.sort().map(tag => (
							<Tag key={tag} onRemove={() => onChange(suppressionTags.filter(t => t !== tag))}>
								{tag} (<NumberedLabel value={allSuppressionTags.find(t => t.tag === tag)?.total ?? 0} singular="email" />)
							</Tag>
						))}
						autoComplete="off"
						disabled={isLoading}
					/>
				}
				onSelect={onChange}
				listTitle="Suppression groups"
				allowMultiple
			/>
		</Labelled>
	);
}
