import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_SENDING_STATISTICS } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type ConversionRevenue = {
	conversions: number;
	revenue: number;
};

export type SendingStatistics = {
	totalNewsletterSends: number;
	totalNewsletterSendsLast30Days: number;
	totalNewsletterSendsLast365Days: number;
	firstNewsletterSendDate: Date;
	lastNewsletterSendDate: Date;
	avgNewsletterSendsPerDay: number;
	avgNewsletterSendsPerWeek: number;
	avgNewsletterSendsPerMonth: number;
	totalAutomationsEnabled: number;
	totalConversionRevenue: ConversionRevenue;
	totalConversionRevenueLast30Days: ConversionRevenue;
	totalConversionRevenueLast365Days: ConversionRevenue;
	avgConversionRevenuePerSend: ConversionRevenue;
	avgNewsletterDeliveryRateLast7Days: number;
	avgNewsletterDeliveryRateLast30Days: number;
	avgNewsletterOpenRateLast7Days: number;
	avgNewsletterOpenRateLast30Days: number;
	avgNewsletterClickRateLast7Days: number;
	avgNewsletterClickRateLast30Days: number;
	avgNewsletterBounceRateLast7Days: number;
	avgNewsletterBounceRateLast30Days: number;
	avgNewsletterSpamRateLast7Days: number;
	avgNewsletterSpamRateLast30Days: number;
	avgNewsletterUnsubscribeRateLast7Days: number;
	avgNewsletterUnsubscribeRateLast30Days: number;
};

export function useMarketingPlatformSendingStatistics(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<SendingStatistics>;
	const {
		data: sendingStatistics,
		refetch: refetchSendingStatistics,
		isFetching: isLoading
	} = useQuery(queryKey(MARKETING_PLATFORM_SENDING_STATISTICS, id), () => get(`/marketing-platform-accounts/${id}/sending-statistics`), {
		enabled: false
	});
	return {
		sendingStatistics,
		refetchSendingStatistics,
		isLoading
	};
}
