import { Caption, Card, FormatNumber, Stack, Subheading } from '@segunosoftware/equinox';
import { DataTable, Layout, Link, ProgressBar, Spinner, type ProgressBarProps } from '@shopify/polaris';
import { format, formatDuration } from 'date-fns';
import styled from 'styled-components';
import {
	useDelayedNewsletters,
	useMissedScheduleNewsletters,
	useNewsletterProgress,
	useSendingUsage,
	type UsageLimit
} from '../../hooks/marketingplatform/useSendMonitoring';
import AdminPage from '../AdminPage';

const MILLIS_PER_SECOND = 1_000;
const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
const TIME_ONLY_FORMAT = 'h:mm:ss a';
const DATE_FORMAT = `yyyy-MM-dd' at '${TIME_ONLY_FORMAT}`;
const USAGE_DATE_FORMAT = 'MMM d yyyy';

function formatDate(date: Date) {
	const today = new Date();
	const dateFormat = today.getTime() - date.getTime() > MILLIS_PER_HOUR * 12 ? DATE_FORMAT : TIME_ONLY_FORMAT;
	return format(date, dateFormat);
}

export default function SendMonitoring() {
	const { sendingUsage, isLoadingSendingUsage, refetchSendingUsage } = useSendingUsage();
	const { newsletterProgressSummaries, isLoadingNewsletterProgress, refetchNewsletterProgress } = useNewsletterProgress();
	const { delayedNewsletters, isLoadingDelayedNewsletters, refetchDelayedNewsletters } = useDelayedNewsletters();
	const { missedScheduleNewsletters, isLoadingMissedScheduleNewsletters, refetchMissedScheduleNewsletters } =
		useMissedScheduleNewsletters();

	function onRefreshAll() {
		refetchSendingUsage();
		refetchNewsletterProgress();
		refetchDelayedNewsletters();
		refetchMissedScheduleNewsletters();
	}

	return (
		<AdminPage
			title="Send monitoring"
			backAction={{ content: 'Dashboard', url: '/' }}
			secondaryActions={[
				{
					content: 'Refresh',
					onAction: onRefreshAll,
					loading: isLoadingSendingUsage || isLoadingNewsletterProgress || isLoadingDelayedNewsletters || isLoadingMissedScheduleNewsletters
				}
			]}>
			<Layout>
				<Layout.Section>
					<Card title="Sending usage limits" sectioned>
						{sendingUsage && (
							<Stack vertical>
								<SendingUsage title="Daily usage" usageLimit={sendingUsage.day} />
								<SendingUsage title="Monthly usage" usageLimit={sendingUsage.month} showDateRange />
							</Stack>
						)}
						{!sendingUsage && (
							<EmptyStateMessage>
								<Spinner size="small" />
							</EmptyStateMessage>
						)}
					</Card>
					<Card title="Newsletters in progress">
						<TopPadded>
							{isLoadingNewsletterProgress && (
								<EmptyStateMessage>
									<Spinner size="small" />
								</EmptyStateMessage>
							)}
							{!isLoadingNewsletterProgress && newsletterProgressSummaries.length === 0 && (
								<EmptyStateMessage>No newsletters are currently sending.</EmptyStateMessage>
							)}
							{!isLoadingNewsletterProgress && newsletterProgressSummaries.length > 0 && (
								<DataTable
									verticalAlign="middle"
									columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'text']}
									headings={['Account', 'Newsletter name', 'Scheduled send time', 'Subscribers', 'Deliveries', 'Est. progress']}
									rows={newsletterProgressSummaries.map(newsletter => [
										<Link url={`/marketing-platform-accounts/${newsletter.accountId}`}>{newsletter.shop}</Link>,
										newsletter.name,
										formatDate(newsletter.scheduledAt),
										<FormatNumber value={newsletter.billedSubscribers} />,
										<FormatNumber value={newsletter.totalDeliveries} />,
										<ProgressBar size="small" progress={(newsletter.totalDeliveries / newsletter.billedSubscribers) * 100.0} />
									])}
									fixedFirstColumns={1}
									hideScrollIndicator
								/>
							)}
						</TopPadded>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card title="Newsletters with incomplete metrics">
						<TopPadded>
							{isLoadingDelayedNewsletters && (
								<EmptyStateMessage>
									<Spinner size="small" />
								</EmptyStateMessage>
							)}
							{!isLoadingDelayedNewsletters && delayedNewsletters.length === 0 && (
								<EmptyStateMessage>No newsletters have delayed metrics.</EmptyStateMessage>
							)}
							{!isLoadingDelayedNewsletters && delayedNewsletters.length > 0 && (
								<DataTable
									verticalAlign="middle"
									columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'numeric']}
									headings={['Account', 'Newsletter name', 'Scheduled send time', 'Sends', 'Deliveries', 'Bounces']}
									rows={delayedNewsletters.map(newsletter => [
										<Link url={`/marketing-platform-accounts/${newsletter.accountId}`}>{newsletter.shop}</Link>,
										newsletter.name,
										formatDate(newsletter.scheduledAt),
										<FormatNumber value={newsletter.sendCount} />,
										<FormatNumber value={newsletter.deliveryCount} />,
										<FormatNumber value={newsletter.bounceCount} />
									])}
									fixedFirstColumns={1}
									hideScrollIndicator
								/>
							)}
						</TopPadded>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card title="Newsletters that missed schedule">
						<TopPadded>
							{isLoadingMissedScheduleNewsletters && (
								<EmptyStateMessage>
									<Spinner size="small" />
								</EmptyStateMessage>
							)}
							{!isLoadingMissedScheduleNewsletters && missedScheduleNewsletters.length === 0 && (
								<EmptyStateMessage>No newsletters have missed their scheduled send time.</EmptyStateMessage>
							)}
							{!isLoadingMissedScheduleNewsletters && missedScheduleNewsletters.length > 0 && (
								<DataTable
									verticalAlign="middle"
									columnContentTypes={['text', 'text', 'text', 'text']}
									headings={['Account', 'Newsletter name', 'Scheduled send time', 'Delayed time']}
									rows={missedScheduleNewsletters.map(newsletter => [
										<Link url={`/marketing-platform-accounts/${newsletter.accountId}`}>{newsletter.shop}</Link>,
										newsletter.name,
										formatDate(newsletter.scheduledAt),
										formatDuration({ minutes: Math.round((Date.now() - newsletter.scheduledAt.getTime()) / MILLIS_PER_MINUTE) })
									])}
									fixedFirstColumns={1}
									hideScrollIndicator
								/>
							)}
						</TopPadded>
					</Card>
				</Layout.Section>
			</Layout>
		</AdminPage>
	);
}

function SendingUsage({ title, usageLimit, showDateRange = false }: { title: string; usageLimit: UsageLimit; showDateRange?: boolean }) {
	return (
		<Stack spacing="extraTight" vertical>
			<Subheading>{title}</Subheading>
			<ColorCodedProgress progress={(usageLimit.used / usageLimit.limit) * 100.0} />
			<Stack alignment="center" distribution="equalSpacing">
				<Caption>
					{showDateRange && (
						<span>
							{format(usageLimit.start, USAGE_DATE_FORMAT)} - {format(usageLimit.end, USAGE_DATE_FORMAT)}
						</span>
					)}
				</Caption>
				<Caption>
					<FormatNumber value={usageLimit.used} /> / <FormatNumber value={usageLimit.limit} />
				</Caption>
			</Stack>
		</Stack>
	);
}

type ColorCodeProgressProps = Pick<ProgressBarProps, 'progress' | 'size'>;

function ColorCodedProgress({ progress, size }: ColorCodeProgressProps) {
	return <ProgressBar progress={progress} size={size} tone={!progress ? undefined : progress > 85 ? 'critical' : 'highlight'} />;
}

const EmptyStateMessage = styled.p`
	text-align: center;
	padding-bottom: 1rem;
`;

const TopPadded = styled.div`
	padding-top: 1rem;
`;
