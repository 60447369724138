import { getDateFromTimestamp, queryKey, type Optional, type Timestamp } from '@segunosoftware/equinox';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { BaseBillableAccount, DehydratedJob, Job } from '../../lib/shared-types';
import {
	MARKETING_PLATFORM_ACCOUNT,
	MARKETING_PLATFORM_ACCOUNT_APP_PURCHASES,
	MARKETING_PLATFORM_ACCOUNT_BILLING_DATE,
	MARKETING_PLATFORM_ACCOUNT_FACEBOOK_DETAILS,
	MARKETING_PLATFORM_ACCOUNT_JOBS,
	MARKETING_PLATFORM_ACCOUNT_KLAVIYO_DETAILS,
	MARKETING_PLATFORM_ACCOUNT_MAILCHIMP_DETAILS,
	MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS,
	MARKETING_PLATFORM_ACCOUNT_SUPPRESSION_TAGS,
	MARKETING_PLATFORM_MANUAL_JOBS
} from '../query-keys';
import type { Delete, Get, Post } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type BillingPlan = 'LEGACY' | 'STANDARD' | 'ADVANCED';

export type ComplianceHold =
	| 'NONE'
	| 'SUBSCRIBER_IMPORT'
	| 'AWAITING_SURVEY'
	| 'PENDING_REVIEW'
	| 'AWAITING_CUSTOMER'
	| 'UNUSUAL_ACTIVITY'
	| 'UNTRUSTED_CONTENT';

export type ListCleaningLevel = 'ALL' | 'ALL_EXCEPT_ROLE' | 'NONE';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';

export type ReviewServiceType = 'NONE' | 'SHOPIFY' | 'JUDGEME' | 'STAMPED' | 'YOTPO' | 'JUNIP' | 'LOOX' | 'FERA';

export type ConnectionType = 'FACEBOOK' | 'INSTAGRAM' | 'MAILCHIMP' | 'KLAVIYO' | 'POSTSCRIPT' | 'REVIEW_SERVICE';

export type ShopifyReview = {
	id: string;
	company: string;
	rating: number;
	date: string;
	comments: string;
};

export type CsmNoteStatus = 'success' | 'info' | 'warning' | 'critical';

export type CsmNote = {
	title: string;
	note: string;
	status: CsmNoteStatus;
	dismissedAt?: Date;
};

type DehydratedCsmNote = CsmNote & {
	dismissedAt?: Timestamp;
};

export type MarketingPlatformAccount = BaseBillableAccount & {
	currency: string;
	complianceHold: boolean;
	trusted: boolean;
	risky: boolean;
	suspicious: boolean;
	trustedImporter: boolean;
	complianceHoldStatus: ComplianceHold;
	sendingDisabledFromPlan: boolean;
	allowSendingOverride: boolean;
	mailChimpConnected: boolean;
	klaviyoConnected: boolean;
	agreedToCleanList: boolean;
	listCleaningComplete: boolean;
	listCleaningLevel: ListCleaningLevel;
	suppressionTags: string[];
	trialRestarted: boolean;
	backfillComplete: boolean;
	backfillPercentComplete: number;
	billingGracePeriodEndsAt?: Date;
	ipPool: number;
	sendingDomain: string;
	sendingDomainWarmed: boolean;
	allowSendingWhileWarming: boolean;
	billedSubscribers: number;
	billingPlan: BillingPlan;
	freeTierCap: number;
	billingUnit: number;
	includedBillingUnits: number;
	pricePerUnitInDollars: number;
	basePriceInDollars: number;
	monthlyPrice: number;
	appPurchaseCredits: number;
	espAccountId: string;
	espApiKey: string;
	testEmails: string[];
	fromEmail: string;
	newShop: boolean;
	fastInstall: boolean;
	shouldRecordScreen: boolean;
	shopifyReviewServiceType: ReviewServiceType;
	facebookConnected: boolean;
	facebookExternalId?: string;
	hubspotId?: string;
	instagramConnected: boolean;
	postscriptConnected: boolean;
	replyEmail: string;
	csmNote?: CsmNote;
	supportsResourceFeedback: boolean;
	languageToUnsubscribeText: Record<string, string>;
};

export type DehydratedMarketingPlatformAccount = MarketingPlatformAccount & {
	trialEndsAt?: Timestamp;
	billingGracePeriodEndsAt?: Timestamp;
	shopCreatedAt: Timestamp;
	createdAt: Timestamp;
	updatedAt: Timestamp;
	csmNote?: DehydratedCsmNote;
};

function hydrateAccount(account: DehydratedMarketingPlatformAccount): MarketingPlatformAccount {
	return {
		...account,
		trialEndsAt: getDateFromTimestamp(account.trialEndsAt),
		billingGracePeriodEndsAt: getDateFromTimestamp(account.billingGracePeriodEndsAt),
		shopCreatedAt: new Date(account.shopCreatedAt),
		createdAt: new Date(account.createdAt),
		updatedAt: new Date(account.updatedAt),
		csmNote: account.csmNote
			? {
					...account.csmNote,
					dismissedAt: getDateFromTimestamp(account.csmNote.dismissedAt)
				}
			: undefined
	};
}

export function useUpdateAccount(id: string | number) {
	const queryClient = useQueryClient();
	return (account: DehydratedMarketingPlatformAccount) =>
		queryClient.setQueryData(queryKey(MARKETING_PLATFORM_ACCOUNT, id), hydrateAccount(account));
}

export function useMarketingPlatformAccount(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedMarketingPlatformAccount>;
	const { data: account } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT, id), () =>
		get(`/marketing-platform-accounts/${id}`).then(hydrateAccount)
	);
	const { jobs, onLoadJobs, isJobsLoading } = useMarketingPlatformJobs(id);
	const { appPurchases, isAppPurchasesLoading } = useMarketingPlatformPurchases(id);

	const billingDate = useMarketingPlatformBillingDate(id);

	return {
		account,
		jobs,
		onLoadJobs,
		isJobsLoading,
		appPurchases:
			appPurchases?.sort((a, b) =>
				b.billingActivatedAt && a.billingActivatedAt ? b.billingActivatedAt.getTime() - a.billingActivatedAt.getTime() : 0
			) ?? [],
		isAppPurchasesLoading,
		billingDate
	};
}

function hydrateJob(job: DehydratedJob): Job {
	return {
		...job,
		schedule: {
			...job.schedule,
			previousFireTime: getDateFromTimestamp(job.schedule.previousFireTime),
			nextFireTime: getDateFromTimestamp(job.schedule.nextFireTime)
		}
	};
}

function useMarketingPlatformJobs(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedJob[]>;
	const {
		data: jobs,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_JOBS, id), () => get(`/marketing-platform-accounts/${id}/jobs`), {
		select: data => data.map(hydrateJob)
	});

	return {
		jobs,
		onLoadJobs,
		isJobsLoading
	};
}

export type AppPurchaseType = 'TEMPLATE' | 'GROUP';
export type AppPurchaseBillingStatus = 'PENDING' | 'ACTIVE';

export type AppPurchase = {
	purchaseType: AppPurchaseType;
	purchaseIdentifier: string;
	price: number;
	billingStatus: AppPurchaseBillingStatus;
	billingActivatedAt?: Date;
};

type DehydratedAppPurchase = AppPurchase & {
	billingActivatedAt?: Timestamp;
};

function hydrateAppPurchase(purchase: DehydratedAppPurchase): AppPurchase {
	return {
		...purchase,
		billingActivatedAt: getDateFromTimestamp(purchase.billingActivatedAt)
	};
}

function useMarketingPlatformPurchases(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedAppPurchase[]>;
	const { data: appPurchases, isFetching: isAppPurchasesLoading } = useQuery(
		queryKey(MARKETING_PLATFORM_ACCOUNT_APP_PURCHASES, id),
		() => get(`/marketing-platform-accounts/${id}/app-purchases`),
		{
			select: data => data.map(hydrateAppPurchase)
		}
	);
	return { appPurchases, isAppPurchasesLoading };
}

function useMarketingPlatformBillingDate(id: string | number): Optional<Date> {
	const { get } = useAuthenticatedFetch() as Get<Timestamp>;
	const { data: billingDate } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_BILLING_DATE, id), () =>
		get(`/marketing-platform-accounts/${id}/billing-date`)
	);
	return getDateFromTimestamp(billingDate);
}

export type MemberListStats = {
	memberCount: number;
	unsubscribeCount: number;
	cleanedCount: number;
	memberCountSinceSend: number;
	unsubscribeCountSinceSend: number;
	cleanedCountSinceSend: number;
	campaignCount: number;
	campaignLastSent?: Date;
	mergeFieldCount: number;
	avgSubRate: number;
	avgUnsubRate: number;
	targetSubRate: number;
	openRate: number;
	clickRate: number;
	lastSubDate?: Date;
	lastUnsubDate?: Date;
};

type DehydratedMemberListStats = MemberListStats & {
	campaignLastSent?: Timestamp;
	lastSubDate?: Timestamp;
	lastUnsubDate?: Timestamp;
};

export type MemberListVisibility = 'pub' | 'prv';

export type MailchimpMemberList = {
	webId: string;
	name: string;
	dateCreated: Date;
	listRating: number;
	emailTypeOption: boolean;
	doubleOptin: boolean;
	notifyOnSubscribe: string;
	notifyOnUnsubscribe: string;
	hasWelcome: boolean;
	marketingPermissions: boolean;
	stats: MemberListStats;
	visibility: MemberListVisibility;
};

type DehydratedMailchimpMemberList = MailchimpMemberList & {
	dateCreated: Timestamp;
	stats: DehydratedMemberListStats;
};

function hydrateMailchimpMemberList(list: DehydratedMailchimpMemberList): MailchimpMemberList {
	return {
		...list,
		dateCreated: new Date(list.dateCreated),
		stats: {
			...list.stats,
			campaignLastSent: getDateFromTimestamp(list.stats.campaignLastSent),
			lastSubDate: getDateFromTimestamp(list.stats.lastSubDate),
			lastUnsubDate: getDateFromTimestamp(list.stats.lastUnsubDate)
		}
	};
}

export function useMarketingPlatformMailchimpDetails(id: string | number) {
	const { account } = useMarketingPlatformAccount(id);
	const { get } = useAuthenticatedFetch() as Get<DehydratedMailchimpMemberList>;
	const { data: mailchimpDetails, isFetching: isMailchimpDetailsLoading } = useQuery(
		queryKey(MARKETING_PLATFORM_ACCOUNT_MAILCHIMP_DETAILS, id),
		() => get(`/marketing-platform-accounts/${id}/mailchimp-details`).then(hydrateMailchimpMemberList),
		{
			enabled: account?.mailChimpConnected
		}
	);
	return { mailchimpDetails, isMailchimpDetailsLoading };
}

export type KlaviyoConnectionDetails = {
	siteId: string;
	apiKey: string;
	listIds: string[];
	syncStartTime: Date;
	syncEndTime: Date;
	isSyncComplete: boolean;
};

export function useMarketingPlatformKlaviyoDetails(id: string | number) {
	const { account } = useMarketingPlatformAccount(id);
	const { get } = useAuthenticatedFetch() as Get<KlaviyoConnectionDetails>;
	const { data: klaviyoDetails, isFetching: isKlaviyoDetailsLoading } = useQuery(
		queryKey(MARKETING_PLATFORM_ACCOUNT_KLAVIYO_DETAILS, id),
		() => get(`/marketing-platform-accounts/${id}/klaviyo-details`),
		{
			enabled: account?.klaviyoConnected
		}
	);
	return { klaviyoDetails, isKlaviyoDetailsLoading };
}

export type FacebookPage = {
	id: string;
	enabled: boolean;
	tokenPresent: boolean;
	numImports: number;
	tags?: string[];
	forms: FacebookForm[];
	createdAt: number;
	updatedAt: number;
};

export type FacebookForm = {
	id: number;
	formId: string;
	numImports: number;
	tags?: string[];
	testPassed: boolean;
	testedAt: number;
	testPassedAt: number;
	createdAt: number;
	updatedAt: number;
};

export function useMarketingPlatformFacebookDetails(id: number | string) {
	const { account } = useMarketingPlatformAccount(id);
	const { get } = useAuthenticatedFetch() as Get<FacebookPage[]>;
	const { data, isFetching: isFacebookPagesLoading } = useQuery(
		queryKey(MARKETING_PLATFORM_ACCOUNT_FACEBOOK_DETAILS, id),
		() => get(`/marketing-platform-accounts/${id}/facebook-details`),
		{ enabled: Boolean(account?.facebookExternalId) }
	);
	return { facebookPages: data ?? [], isFacebookPagesLoading };
}

export type ConnectionDescription = {
	id: ConnectionType;
	name: string;
	connected: boolean;
	hasDetails?: boolean;
};

export function useMarketingPlatformConnectionList(id: string | number) {
	const { account } = useMarketingPlatformAccount(id);

	if (!account) {
		return;
	}

	const {
		instagramConnected,
		facebookConnected,
		facebookExternalId,
		mailChimpConnected,
		klaviyoConnected,
		postscriptConnected,
		shopifyReviewServiceType
	} = account;
	return [
		{
			id: 'MAILCHIMP',
			name: 'Mailchimp',
			connected: mailChimpConnected,
			hasDetails: mailChimpConnected
		} as ConnectionDescription,
		{
			id: 'KLAVIYO',
			name: 'Klaviyo',
			connected: klaviyoConnected,
			hasDetails: klaviyoConnected
		} as ConnectionDescription,
		{
			id: 'FACEBOOK',
			name: 'Facebook leads',
			connected: facebookConnected,
			hasDetails: facebookExternalId
		} as ConnectionDescription,
		{
			id: 'INSTAGRAM',
			name: 'Instagram',
			connected: instagramConnected
		} as ConnectionDescription,
		{
			id: 'POSTSCRIPT',
			name: 'Postscript',
			connected: postscriptConnected
		} as ConnectionDescription,
		{
			id: 'REVIEW_SERVICE',
			name: 'Review service',
			connected: Boolean(shopifyReviewServiceType)
		} as ConnectionDescription
	];
}

export type ManualJobType = 'SUPPRESSED_EMAILS_IMPORT';

export type ManualJobStatus = 'CREATED' | 'RUNNING' | 'CANCELLING' | 'CANCELLED' | 'FAILED' | 'COMPLETED';

export type ManualJob = {
	id: number;
	accountId: number;
	jobType: ManualJobType;
	jobStatus: ManualJobStatus;
	percentComplete: number;
	jobInput?: string;
	jobOutput?: string;
	startTime?: Date;
	endTime?: Date;
	createdAt: Date;
	updatedAt: Date;
};

type DehydratedManualJob = ManualJob & {
	startTime?: Timestamp;
	endTime: Timestamp;
	createdAt: Timestamp;
	updatedAt: Timestamp;
};

function hydrateManualJob(manualJob: DehydratedManualJob): ManualJob {
	return {
		...manualJob,
		startTime: getDateFromTimestamp(manualJob.startTime),
		endTime: getDateFromTimestamp(manualJob.endTime),
		createdAt: new Date(manualJob.createdAt),
		updatedAt: new Date(manualJob.updatedAt)
	};
}

export function useMarketingPlatformManualJobs(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedManualJob[]>;

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS, id), () => get(`/marketing-platform-accounts/${id}/manual-jobs`), {
		select: data => data.map(hydrateManualJob)
	});

	return {
		manualJobs: data ?? [],
		isLoading,
		refetch
	};
}

const MARKETING_PLATFORM_MANUAL_JOBS_LIMIT = 10;

export function useAllMarketingPlatformManualJobs() {
	const { get } = useAuthenticatedFetch() as Get<DehydratedManualJob[]>;

	const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery(
		queryKey(MARKETING_PLATFORM_MANUAL_JOBS),
		({ pageParam = 0 }) => get(`/marketing-platform-accounts/manual-jobs?page=${pageParam}`),
		{
			getNextPageParam: (lastPage, pages) => (lastPage.length === MARKETING_PLATFORM_MANUAL_JOBS_LIMIT ? pages.length : undefined),
			select: data => ({
				...data,
				pages: data?.pages.map(page => page.map(hydrateManualJob))
			})
		}
	);

	return {
		manualJobs: data?.pages ?? [],
		isLoading: isFetching,
		refetch,
		hasNextPage,
		fetchNextPage
	};
}

export function useCreateMarketingPlatformManualJob(accountId?: string | number) {
	const queryClient = useQueryClient();
	const { post } = useAuthenticatedFetch() as Post<FormData, DehydratedManualJob>;

	const {
		mutate: createManualJob,
		data,
		isLoading,
		reset
	} = useMutation({
		mutationFn: ({ jobType, file }: { jobType: ManualJobType; file: File }) => {
			const body = new FormData();
			body.append('file', file);
			return post(`/marketing-platform-accounts/${accountId}/manual-jobs/${jobType}`, body);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(queryKey(MARKETING_PLATFORM_MANUAL_JOBS));
			queryClient.invalidateQueries(queryKey(MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS, accountId));
		}
	});

	const createdJob = useMemo(() => (data ? hydrateManualJob(data) : undefined), [data]);

	return {
		createManualJob,
		createdJob,
		reset,
		isLoading
	};
}

export function useCancelMarketingPlatformManualJob() {
	const queryClient = useQueryClient();
	const { delete: del } = useAuthenticatedFetch() as Delete<DehydratedManualJob>;

	const { mutate: cancelManualJob, isLoading } = useMutation({
		mutationFn: ({ accountId, jobId }: { accountId: number; jobId: number }) =>
			del(`/marketing-platform-accounts/${accountId}/manual-jobs/${jobId}`),
		onSuccess: () => {
			queryClient.invalidateQueries(queryKey(MARKETING_PLATFORM_MANUAL_JOBS));
			queryClient.invalidateQueries({
				queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS),
				exact: false
			});
		}
	});

	return {
		cancelManualJob,
		isLoading
	};
}

export type SuppressionTag = {
	tag: string;
	total: number;
};

export function useMarketingPlatformAccountSuppressionTags(accountId: string | number) {
	const { get } = useAuthenticatedFetch() as Get<SuppressionTag[]>;
	const { data, isFetching: isLoading } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_SUPPRESSION_TAGS, accountId), () =>
		get(`/marketing-platform-accounts/${accountId}/suppression-tags`)
	);
	return {
		suppressionTags: data ?? [],
		isLoading
	};
}
