import PropTypes from 'prop-types';
import { useRecentBulkAccounts } from '../../hooks/bulk/useRecentBulkAccounts';
import RecentAccounts, { BILLING_STATUS_FILTER } from '../RecentAccounts';

const BULK_FILTERS = [BILLING_STATUS_FILTER];

RecentBannerAccounts.propTypes = {
	searchParameters: PropTypes.shape({
		appliedFilters: PropTypes.object.isRequired,
		searchText: PropTypes.string.isRequired,
		recentPage: PropTypes.number.isRequired
	}).isRequired,
	setSearchParameters: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
};

export default function RecentBannerAccounts({ searchParameters, setSearchParameters, limit }) {
	const { accounts, count, load, isLoading } = useRecentBulkAccounts(
		searchParameters.appliedFilters,
		searchParameters.searchText,
		searchParameters.recentPage,
		limit
	);

	return (
		<RecentAccounts
			accountType="bulk"
			recentLimit={limit}
			filters={BULK_FILTERS}
			onSearchParametersChange={setSearchParameters}
			searchParameters={searchParameters}
			onLoadRecentAccounts={load}
			getAccountViewUrl={id => `/bulk-accounts/${id}`}
			recentAccounts={accounts}
			isRecentAccountsLoading={isLoading}
			recentAccountsCount={count}
		/>
	);
}
