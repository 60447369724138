import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { POPUP_ACCOUNT } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function usePopupAccountActions(id) {
	const { post } = useAuthenticatedFetch();
	const queryClient = useQueryClient();
	const mutationOptions = { onSuccess: data => queryClient.setQueryData(queryKey(POPUP_ACCOUNT, id), data) };

	function getActionPath(path) {
		return `/popup-accounts/${id}${path}`;
	}

	const { mutate: updateTrial, isLoading: isUpdatingTrial } = useMutation(
		newTrialDate => post(getActionPath('/update-trial'), newTrialDate),
		mutationOptions
	);

	const { mutate: setSuspended, isLoading: isSettingSuspended } = useMutation(
		suspended =>
			post(getActionPath('/set-suspended'), {
				suspended
			}),
		mutationOptions
	);

	const { mutate: cancelCharge, isLoading: isCancellingCharge } = useMutation(() => post(getActionPath('/cancel-charge')), mutationOptions);

	return {
		updateTrial,
		isUpdatingTrial,
		setSuspended,
		isSettingSuspended,
		cancelCharge,
		isCancellingCharge
	};
}
