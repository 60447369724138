import { Card, ClipboardButton, Stack, useCopyToClipboard } from '@segunosoftware/equinox';
import { Button, Layout, Modal, SkeletonBodyText, Tooltip } from '@shopify/polaris';
import { ClipboardIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../ToastProvider';
import { useCanvaAccount, useCanvaAccountJobs, useCanvaAccountUsers, type CanvaUser } from '../../hooks/canva/useCanvaAccount';
import { renderDate } from '../../utils/dateUtils';
import AccountView, { AlignCenter } from '../AccountView';
import AdminPage from '../AdminPage';
import JobsView from '../JobsView';

export default function CanvaAccountView() {
	const { accountId } = useParams();
	const { account } = useCanvaAccount(accountId);
	const { users, isCanvaUsersLoading } = useCanvaAccountUsers(accountId);
	const { jobs, onLoadJobs, isJobsLoading } = useCanvaAccountJobs(accountId);
	const copyToClipboard = useCopyToClipboard();
	const showToast = useToast();

	function renderCanvaUsers() {
		if (!users || isCanvaUsersLoading) {
			return <SkeletonBodyText lines={3} />;
		}
		if (users.length === 0) {
			<Card.Section>No Canva users found</Card.Section>;
		}
		return users.map(user => <CanvaUserView user={user} />);
	}

	function onCopySyncToken() {
		if (!account) {
			return;
		}
		copyToClipboard(account.syncToken);
		showToast('Sync token copied!');
	}

	return (
		<AdminPage title={account?.name ?? 'Canva account'} backAction={{ content: 'Dashboard', url: '/' }}>
			<Layout sectioned>
				{account && <AccountView account={account} app="CANVA" />}
				{account && (
					<Card title="Sync token" sectioned>
						<Stack alignment="center">
							<div>{account.syncToken}</div>
							<Button icon={ClipboardIcon} onClick={onCopySyncToken}>
								Copy
							</Button>
						</Stack>
					</Card>
				)}
				<Card title="Canva users">{renderCanvaUsers()}</Card>
				<JobsView jobs={jobs} onLoad={onLoadJobs} isLoading={isJobsLoading} />
			</Layout>
		</AdminPage>
	);
}

type CanvaUserViewProps = {
	user: CanvaUser;
};

function CanvaUserView({ user: { userId, loginAt, createdAt, exports } }: CanvaUserViewProps) {
	const showToast = useToast();
	const [showExports, setShowExports] = useState(false);

	function onHideExports() {
		setShowExports(false);
	}

	return (
		<Card.Section
			title={
				<div>
					<strong>User ID</strong>: {userId}
				</div>
			}
			actions={[{ content: 'View exports', onAction: () => setShowExports(true) }]}>
			<div>
				<strong>Last login</strong>: {renderDate(loginAt)}
			</div>
			<div>
				<strong>Created at</strong>: {renderDate(createdAt)}
			</div>
			<Modal
				title="Canva exports"
				open={showExports}
				onClose={onHideExports}
				secondaryActions={[{ content: 'Close', onAction: onHideExports }]}>
				{exports.length === 0 && <Modal.Section>No exports found</Modal.Section>}
				{exports.map(({ gid, mediaType, url, createdAt, updatedAt }) => (
					<Modal.Section>
						<AlignCenter>
							<div>
								<strong>Media type</strong>: {mediaType}
							</div>
							<Tooltip content="Copy export URL">
								<ClipboardButton icon={ClipboardIcon} content={url} onCopy={() => showToast('Export URL copied!')} variant="plain" />
							</Tooltip>
						</AlignCenter>
						<div>
							<strong>Global ID</strong>: {gid}
						</div>
						<div>
							<strong>Created at</strong>: {renderDate(createdAt)}
						</div>
						<div>
							<strong>Last updated</strong>: {renderDate(updatedAt)}
						</div>
					</Modal.Section>
				))}
			</Modal>
		</Card.Section>
	);
}
