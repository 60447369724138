import {
	Card,
	DateTimePicker,
	FormatMoney,
	FormatNumber,
	MILLIS_PER_DAY,
	Stack,
	Subheading,
	TextStyle,
	TypedSelect,
	capitalize,
	usePrevious
} from '@segunosoftware/equinox';
import { Button, Checkbox, FormLayout, Modal, RangeSlider, SkeletonBodyText, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useMarketingPlatformAccount, type BillingPlan } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { useMarketingPlatformAccountActions, type BillingDetails } from '../../hooks/marketingplatform/useMarketingPlatformAccountActions';
import { useMarketingPlatformAccountDetails } from '../../hooks/marketingplatform/useMarketingPlatformAccountDetails';
import { useUser } from '../../hooks/useUser';
import { renderDate } from '../../utils/dateUtils';
import { isLeadOrAdmin, isSupport } from '../AccountView';

const DEFAULT_GRACE_PERIOD_IN_MILLIS = MILLIS_PER_DAY * 10;

const BILLING_PLAN_DETAILS: Record<BillingPlan, BillingDetails> = Object.freeze({
	LEGACY: Object.freeze({
		billingPlan: 'LEGACY',
		trialDays: 10,
		freeTierCap: 250,
		basePrice: 1000,
		includedBillingUnits: 1000,
		billingUnit: 1000,
		pricePerUnit: 1000
	}),
	STANDARD: Object.freeze({
		billingPlan: 'STANDARD',
		trialDays: 10,
		freeTierCap: 250,
		basePrice: 3500,
		includedBillingUnits: 2500,
		billingUnit: 2500,
		pricePerUnit: 2000
	}),
	ADVANCED: Object.freeze({
		billingPlan: 'ADVANCED',
		trialDays: 10,
		freeTierCap: 250,
		basePrice: 24500,
		includedBillingUnits: 25000,
		billingUnit: 5000,
		pricePerUnit: 3000
	})
});

export type MarketingPlatformBillingDetailsProps = {
	accountId: string;
};

export default function MarketingPlatformBillingDetails({ accountId }: MarketingPlatformBillingDetailsProps) {
	const user = useUser();
	const { account, billingDate } = useMarketingPlatformAccount(accountId);
	const { details, onLoadDetails } = useMarketingPlatformAccountDetails(accountId);
	const {
		confirmDowngrade,
		isConfirmingDowngrade,
		cancelCharge,
		isCancellingCharge,
		refreshBilling,
		isRefreshingBilling,
		adjustBillingDetails,
		isAdjustingBillingDetails,
		adjustStoreCredits,
		isAdjustingStoreCredits
	} = useMarketingPlatformAccountActions(accountId);

	const [showConfirmDowngradeModal, setShowConfirmDowngradeModal] = useState(false);

	const [showCancelChargeModal, setShowCancelChargeModal] = useState(false);

	const [showStoreCreditModal, setShowStoreCreditModal] = useState(false);
	const [rangeValue, setRangeValue] = useState(0);

	const [showEditBillingModal, setShowEditBillingModal] = useState(false);
	const [billingDetails, setBillingDetails] = useState<BillingDetails>({
		billingPlan: 'LEGACY',
		trialDays: 10,
		freeTierCap: 250,
		basePrice: 1000,
		includedBillingUnits: 1000,
		billingUnit: 1000,
		pricePerUnit: 1000
	});
	const [gracePeriodDate, setGracePeriodDate] = useState<Date | undefined>(account?.billingGracePeriodEndsAt);

	const [billedSubscribersEstimate, setBilledSubscribersEstimate] = useState(0);

	const previousIsConfirmingDowngrade = usePrevious(isConfirmingDowngrade);
	const previousIsCancellingCharge = usePrevious(isCancellingCharge);
	const previousIsAdjustingBillingDetails = usePrevious(isAdjustingBillingDetails);
	const previousIsAdjustingStoreCredits = usePrevious(isAdjustingStoreCredits);

	useEffect(() => {
		if (!isConfirmingDowngrade && previousIsConfirmingDowngrade) {
			setShowConfirmDowngradeModal(false);
			onLoadDetails();
		}
		if (!isCancellingCharge && previousIsCancellingCharge) {
			setShowCancelChargeModal(false);
			onLoadDetails();
		}
		if (!isAdjustingBillingDetails && previousIsAdjustingBillingDetails) {
			setShowEditBillingModal(false);
		}
		if (!isAdjustingStoreCredits && previousIsAdjustingStoreCredits) {
			setShowStoreCreditModal(false);
		}
	}, [
		onLoadDetails,
		isCancellingCharge,
		isConfirmingDowngrade,
		previousIsConfirmingDowngrade,
		previousIsCancellingCharge,
		isAdjustingStoreCredits,
		isAdjustingBillingDetails,
		previousIsAdjustingBillingDetails,
		previousIsAdjustingStoreCredits
	]);

	if (!account || !details) {
		return (
			<Card title="Billing" sectioned>
				<SkeletonBodyText />
			</Card>
		);
	}

	const showDowngradeLink = account.upgradedEarly && account.billedSubscribers <= account.freeTierCap && account.billingStatus === 'active';

	function onShowStoreCreditModal() {
		setRangeValue(0);
		setShowStoreCreditModal(true);
	}

	function onShowEditBillingModal() {
		if (!account) {
			return;
		}
		setBillingDetails({
			billingPlan: account.billingPlan,
			trialDays: account.trialDays,
			freeTierCap: account.freeTierCap,
			basePrice: account.basePrice,
			includedBillingUnits: account.includedBillingUnits,
			billingUnit: account.billingUnit,
			pricePerUnit: account.pricePerUnit
		});
		setGracePeriodDate(account.billingGracePeriodEndsAt);
		setBilledSubscribersEstimate(account.billedSubscribers);
		setShowEditBillingModal(true);
	}

	function onSaveBilling() {
		adjustBillingDetails({ billingDetails, gracePeriodDate });
	}

	const isLeadOrAdminRole = isLeadOrAdmin(user);

	const billingDetailsForSelectedPlan = BILLING_PLAN_DETAILS[billingDetails.billingPlan];

	return (
		<Card title="Billing" actions={isLeadOrAdminRole ? [{ content: 'Edit', onAction: onShowEditBillingModal }] : undefined} sectioned>
			<Stack spacing="none" vertical>
				<div>
					<strong>Trial days</strong>: <FormatNumber value={account.trialDays} />
				</div>
				<div>
					<strong>Billing plan</strong>: {capitalize(account.billingPlan.toLowerCase())}
				</div>
				<div>
					<strong>Free tier cap</strong>: <FormatNumber value={account.freeTierCap} />
				</div>
				<div>
					<strong>Base price</strong>: <FormatMoney amount={account.basePriceInDollars} /> (Includes:{' '}
					<FormatNumber value={account.includedBillingUnits} /> subscribers)
				</div>
				<div>
					<strong>Unit size/cost</strong>: <FormatMoney amount={account.pricePerUnitInDollars} /> per{' '}
					<FormatNumber value={account.billingUnit} /> subscribers
				</div>
				<div>
					<strong>Upgraded Early:</strong> {account.upgradedEarly ? 'Yes' : 'No'}{' '}
					{showDowngradeLink && (
						<Button onClick={() => setShowConfirmDowngradeModal(true)} variant="plain" tone="critical">
							(downgrade)
						</Button>
					)}{' '}
				</div>
				{account.upgradedEarly && account.upgradedReason && (
					<div>
						<strong>Upgrade reason:</strong> {account.upgradedReason}
					</div>
				)}
				<div>
					<strong>Billed Subscribers</strong>: <FormatNumber value={account.billedSubscribers} /> (
					<FormatMoney amount={account.monthlyPrice} />){' '}
					{isSupport(user) && (
						<Button onClick={refreshBilling} loading={isRefreshingBilling} variant="plain">
							Refresh
						</Button>
					)}
				</div>
				<div>
					<strong>Active Monthly Charge</strong>: <FormatMoney amount={details.activeChargeAmount} />{' '}
					{!showDowngradeLink && details.activeChargeAmount > 0 && (
						<Button onClick={() => setShowCancelChargeModal(true)} variant="plain" tone="critical">
							(cancel charge)
						</Button>
					)}
				</div>
				{account.billingGracePeriodEndsAt && (
					<div>
						<strong>Grace period ends</strong>: {renderDate(account.billingGracePeriodEndsAt)}
					</div>
				)}
				{billingDate && (
					<div>
						<strong>Next billing date</strong>: {renderDate(billingDate)}
					</div>
				)}
				<div>
					<strong>Trial restarted</strong>: {account.trialRestarted ? 'Yes' : 'No'}
				</div>
				<div>
					<strong>Store credit:</strong> <FormatNumber value={account.appPurchaseCredits} />{' '}
					<Button onClick={onShowStoreCreditModal} variant="plain">
						Change
					</Button>
				</div>
			</Stack>
			<Modal
				title="Confirm downgrade"
				primaryAction={{
					content: 'Downgrade',
					onAction: confirmDowngrade,
					destructive: true,
					loading: isConfirmingDowngrade
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmDowngradeModal(false) }]}
				onClose={() => setShowConfirmDowngradeModal(false)}
				open={showConfirmDowngradeModal}>
				<Modal.Section>
					<p>
						This will cancel the recurring charge in Shopify. If the trial period is still active, the merchant will continue to have access
						to paid features for the remainder of the trial. If the trial is already ended, this will also immediately downgrade the account
						back to the free tier and all paid features will be disabled.
					</p>
				</Modal.Section>
			</Modal>
			<Modal
				title="Confirm charge cancellation"
				primaryAction={{
					content: 'Confirm',
					onAction: cancelCharge,
					destructive: true,
					loading: isCancellingCharge
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowCancelChargeModal(false) }]}
				onClose={() => setShowCancelChargeModal(false)}
				open={showCancelChargeModal}>
				<Modal.Section>
					<p>
						This will cancel the charge with Shopify. If the merchant is in the free trial, the charge will be cancelled. If not in the free
						trial, the charge will be cancelled and the grace period will be set 24 hours from when the cancellation was confirmed.
					</p>
				</Modal.Section>
			</Modal>
			<Modal
				title="Adjust store credits"
				primaryAction={{
					content: 'Adjust',
					onAction: () => adjustStoreCredits(rangeValue),
					loading: isAdjustingStoreCredits,
					disabled: rangeValue === 0
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowStoreCreditModal(false) }]}
				onClose={() => setShowStoreCreditModal(false)}
				open={showStoreCreditModal}>
				<Modal.Section>
					<RangeSlider
						output
						label={`Credit adjustment: ${rangeValue > 0 ? '+' : ''}${rangeValue}`}
						min={-account?.appPurchaseCredits}
						max={5}
						value={rangeValue}
						onChange={value => setRangeValue(Number(value))}
						helpText="Specify the credit amount to be added or subtracted"
					/>
				</Modal.Section>
			</Modal>
			<Modal
				title="Edit billing"
				primaryAction={{
					content: 'Save billing',
					onAction: onSaveBilling,
					loading: isAdjustingBillingDetails
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowEditBillingModal(false) }]}
				onClose={() => setShowEditBillingModal(false)}
				open={showEditBillingModal}>
				<Modal.Section>
					<FormLayout>
						<TypedSelect<BillingPlan>
							label="Billing plan"
							options={[
								{ value: 'LEGACY', label: 'Legacy' },
								{ value: 'STANDARD', label: 'Standard' },
								{ value: 'ADVANCED', label: 'Advanced' }
							]}
							value={billingDetails.billingPlan}
							onChange={billingPlan => setBillingDetails({ ...billingDetails, billingPlan })}
							helpText={
								<span>
									Set the{' '}
									<Button onClick={() => setBillingDetails(BILLING_PLAN_DETAILS[billingDetails.billingPlan])} variant="plain">
										default billing
									</Button>{' '}
									for this plan.
								</span>
							}
						/>
						<TextField
							label="Trial days"
							type="number"
							autoComplete="off"
							min={0}
							value={billingDetails.trialDays.toString()}
							onChange={value => setBillingDetails({ ...billingDetails, trialDays: Number(value) })}
							helpText={getDiffString(billingDetailsForSelectedPlan.trialDays, billingDetails.trialDays)}
						/>
						<TextField
							label="Free tier cap"
							type="number"
							autoComplete="off"
							min={0}
							value={billingDetails.freeTierCap.toString()}
							onChange={value => setBillingDetails({ ...billingDetails, freeTierCap: Number(value) })}
							helpText={getDiffString(billingDetailsForSelectedPlan.freeTierCap, billingDetails.freeTierCap)}
						/>
						<FormLayout.Group>
							<TextField
								label="Base price"
								type="number"
								autoComplete="off"
								prefix="$"
								suffix="USD"
								min={0}
								value={(billingDetails.basePrice / 100).toString()}
								onChange={value => setBillingDetails({ ...billingDetails, basePrice: Number(value) * 100 })}
								helpText={getDiffString(billingDetailsForSelectedPlan.basePrice / 100, billingDetails.basePrice / 100)}
							/>
							<TextField
								label="Included subscribers"
								type="number"
								autoComplete="off"
								min={0}
								value={billingDetails.includedBillingUnits.toString()}
								onChange={value => setBillingDetails({ ...billingDetails, includedBillingUnits: Number(value) })}
								helpText={getDiffString(billingDetailsForSelectedPlan.includedBillingUnits, billingDetails.includedBillingUnits)}
							/>
						</FormLayout.Group>
						<FormLayout.Group>
							<TextField
								label="Price per unit"
								type="number"
								autoComplete="off"
								prefix="$"
								suffix="USD"
								min={0}
								value={(billingDetails.pricePerUnit / 100).toString()}
								onChange={value => setBillingDetails({ ...billingDetails, pricePerUnit: Number(value) * 100 })}
								helpText={getDiffString(billingDetailsForSelectedPlan.pricePerUnit / 100, billingDetails.pricePerUnit / 100)}
							/>
							<TextField
								label="Unit size"
								type="number"
								autoComplete="off"
								min={0}
								value={billingDetails.billingUnit.toString()}
								onChange={value => setBillingDetails({ ...billingDetails, billingUnit: Number(value) })}
								helpText={getDiffString(billingDetailsForSelectedPlan.billingUnit, billingDetails.billingUnit)}
							/>
						</FormLayout.Group>
					</FormLayout>
				</Modal.Section>
				<Modal.Section>
					<FormLayout>
						<Checkbox
							label="Set grace period"
							onChange={value =>
								setGracePeriodDate(
									value ? account.billingGracePeriodEndsAt ?? new Date(Date.now() + DEFAULT_GRACE_PERIOD_IN_MILLIS) : undefined
								)
							}
							checked={Boolean(gracePeriodDate)}
						/>
						{Boolean(gracePeriodDate) && (
							<DateTimePicker
								dateLabel="End date"
								timeLabel="End time"
								timezone={account.timezone}
								disableDatesBefore={new Date()}
								onChange={setGracePeriodDate}
								value={gracePeriodDate}
							/>
						)}
					</FormLayout>
				</Modal.Section>
				<Modal.Section>
					<Stack vertical>
						<Subheading>Estimate</Subheading>
						<TextField
							label="Subscribers"
							type="number"
							autoComplete="off"
							min={0}
							value={billedSubscribersEstimate.toString()}
							onChange={value => setBilledSubscribersEstimate(Number(value))}
							helpText={<EstimatedCost billingDetails={billingDetails} billedSubscribers={billedSubscribersEstimate} />}
						/>
					</Stack>
				</Modal.Section>
			</Modal>
		</Card>
	);
}

type EstimatedCostProps = {
	billingDetails: BillingDetails;
	billedSubscribers: number;
};

function EstimatedCost({ billingDetails, billedSubscribers }: EstimatedCostProps) {
	function getCost() {
		if (billedSubscribers > billingDetails.freeTierCap) {
			const remainingBilledSubscribers = billedSubscribers - billingDetails.includedBillingUnits;
			if (remainingBilledSubscribers > 0) {
				const chargeableBillingUnits = Math.ceil(remainingBilledSubscribers / billingDetails.billingUnit);
				return billingDetails.basePrice + chargeableBillingUnits * billingDetails.pricePerUnit;
			}
			return billingDetails.basePrice;
		}
		return 0;
	}

	return (
		<TextStyle variation="subdued">
			Estimated monthly cost is <FormatMoney amount={getCost() / 100} /> USD.
		</TextStyle>
	);
}

function getDiffString(origValue: number, newValue: number) {
	if (origValue === newValue) {
		return undefined;
	}
	return `This value differs with the standard value: ${origValue}`;
}
