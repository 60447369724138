import { FormatNumber, Stack, TextStyle } from '@segunosoftware/equinox';
import { Button, DatePicker, Filters, ResourceItem, ResourceList } from '@shopify/polaris';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { createBasicFilter, createMultipleChoiceListFilter } from '../lib/filter-creator';
import Pager from './Pager';

const DATE_FORMAT = 'yyyy-MM-dd';
const TODAY = new Date();

function parseDateOrUndefined(date) {
	if (!date) {
		return undefined;
	}
	try {
		return new Date(`${date} 00:00:00`);
	} catch (e) {
		return undefined;
	}
}

function isMinDateLessThanOrEqualMax(minDate, maxDate) {
	try {
		return new Date(minDate).getTime() <= new Date(maxDate).getTime();
	} catch (e) {
		return false;
	}
}

function DateRangePicker({ selected, onChange }) {
	const now = new Date();
	const [month, setMonth] = useState(now.getMonth());
	const [year, setYear] = useState(now.getFullYear());

	return (
		<DatePicker
			year={year}
			month={month}
			selected={selected}
			onChange={onChange}
			onMonthChange={(month, year) => {
				setMonth(month);
				setYear(year);
			}}
			disableDatesAfter={now}
			allowRange
			multiMonth
		/>
	);
}

export const BILLING_STATUS_FILTER = createMultipleChoiceListFilter(
	'billingStatus',
	'Account status',
	basicFilterChoiceMapper(['active', 'unbilled', 'cancelled', 'frozen']),
	{
		shortcut: true,
		pinned: true
	}
);

export const SHOP_PLAN_FILTER = createMultipleChoiceListFilter(
	'shopPlan',
	'Platform plan',
	basicFilterChoiceMapper([
		'basic',
		'professional',
		'unlimited',
		'shopify_plus',
		'plus_partner_sandbox',
		'partner_test',
		'affiliate',
		'frozen',
		'cancelled',
		'paused',
		'staff',
		'staff_business',
		'trial',
		'paid_trial',
		'fraudulent',
		'npo_full',
		'npo_lite',
		'business',
		'sales_training',
		'comped',
		'dormant',
		'custom',
		'enterprise',
		'starter',
		'shopify_alumni',
		'open_learning',
		'grandfather',
		'starter_2022',
		'retail'
	])
);

export const INSTALL_DATE_FILTER = createBasicFilter('installDateMin', 'Install date', {
	filterParameters: ['installDateMin', 'installDateMax'],
	appliedLabel: appliedFilters =>
		appliedFilters.installDateMin &&
		appliedFilters.installDateMax &&
		isMinDateLessThanOrEqualMax(appliedFilters.installDateMin, appliedFilters.installDateMax) &&
		`Install date between ${format(parseDateOrUndefined(appliedFilters.installDateMin), DATE_FORMAT)} and ${format(
			parseDateOrUndefined(appliedFilters.installDateMax),
			DATE_FORMAT
		)}`,
	filter: (appliedFilters, onChange) => (
		<DateRangePicker
			selected={{
				start: parseDateOrUndefined(appliedFilters.installDateMin) ?? TODAY,
				end: parseDateOrUndefined(appliedFilters.installDateMax) ?? TODAY
			}}
			onChange={range => onChange({ installDateMin: format(range.start, DATE_FORMAT), installDateMax: format(range.end, DATE_FORMAT) })}
		/>
	)
});

const SEARCH_DEBOUNCE = 500;

const ACCOUNT_PROP_TYPE = PropTypes.arrayOf(
	PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
		ownerName: PropTypes.string,
		ownerEmail: PropTypes.string,
		shop: PropTypes.string.isRequired,
		domain: PropTypes.string,
		platformPlan: PropTypes.string,
		billingStatus: PropTypes.string.isRequired,
		shopCreatedAt: PropTypes.number,
		createdAt: PropTypes.number.isRequired
	})
);

RecentAccounts.propTypes = {
	accountType: PropTypes.string.isRequired,
	onLoadRecentAccounts: PropTypes.func.isRequired,
	getAccountViewUrl: PropTypes.func.isRequired,
	onSearchParametersChange: PropTypes.func.isRequired,
	recentLimit: PropTypes.number.isRequired,
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			options: PropTypes.array,
			shortcut: PropTypes.bool
		})
	),
	searchParameters: PropTypes.shape({
		appliedFilters: PropTypes.object.isRequired,
		searchText: PropTypes.string.isRequired,
		recentPage: PropTypes.number.isRequired
	}),
	recentAccounts: ACCOUNT_PROP_TYPE,
	isRecentAccountsLoading: PropTypes.bool,
	recentAccountsCount: PropTypes.number
};

export function basicFilterChoiceMapper(choices) {
	return choices.map(c => ({ label: c, value: c }));
}

export default function RecentAccounts({
	accountType,
	recentLimit,
	onSearchParametersChange,
	onLoadRecentAccounts,
	filters,
	searchParameters = {
		appliedFilters: [],
		recentPage: 1,
		searchText: ''
	},
	getAccountViewUrl,
	recentAccounts = [],
	recentAccountsCount = 0,
	isRecentAccountsLoading = false,
	children = item => (
		<Stack alignment="center">
			<TextStyle variation="strong">
				{item.name} ({item.shop})
			</TextStyle>
			<div>{item.platformPlan}</div>
			<div>{item.billingStatus}</div>
		</Stack>
	)
}) {
	const { appliedFilters, recentPage, searchText } = searchParameters;
	const [stagedSearchText, setStagedSearchText] = useState(searchText);

	const onChangeSearchParameters = useCallback(
		newSearchParameters => {
			onSearchParametersChange({
				...searchParameters,
				...newSearchParameters
			});
		},
		[onSearchParametersChange, searchParameters]
	);

	function onRecentPageChanged(recentPage) {
		onChangeSearchParameters({
			recentPage
		});
	}

	function onFiltersChanged(appliedFilters) {
		onChangeSearchParameters({
			appliedFilters,
			recentPage: 1
		});
	}

	useEffect(() => {
		setStagedSearchText(searchText);
	}, [searchText]);

	const onSearchTextChanged = useCallback(
		searchText =>
			onChangeSearchParameters({
				searchText,
				recentPage: 1
			}),
		[onChangeSearchParameters]
	);

	useEffect(() => {
		if (stagedSearchText === searchText) {
			return;
		}
		const debounce = setTimeout(() => {
			onSearchTextChanged(stagedSearchText);
		}, SEARCH_DEBOUNCE);
		return () => clearTimeout(debounce);
	}, [onSearchTextChanged, stagedSearchText, searchText]);

	function renderFilterControl() {
		const filterControls = filters.map(f => ({
			...f,
			filter: f.filter(appliedFilters, newValues => onFiltersChanged({ ...appliedFilters, ...newValues }))
		}));
		const appliedFilterValues = Object.keys(appliedFilters)
			.map(key => ({
				key,
				label: filters.find(f => f.key === key)?.appliedLabel(appliedFilters),
				onRemove: () => {
					const newFilters = { ...appliedFilters };
					(filters.find(f => f.key === key)?.filterParameters ?? []).forEach(param => delete newFilters[param]);
					onFiltersChanged(newFilters);
				}
			}))
			.filter(filter => Boolean(filter.label));
		return (
			<Filters
				queryPlaceholder={`Search ${accountType} accounts`}
				filters={filterControls}
				appliedFilters={appliedFilterValues}
				queryValue={stagedSearchText}
				onQueryChange={setStagedSearchText}
				onQueryClear={() => onSearchTextChanged('')}
				onClearAll={() => onFiltersChanged({})}>
				<Button onClick={onLoadRecentAccounts} loading={isRecentAccountsLoading}>
					Refresh
				</Button>
			</Filters>
		);
	}

	const hasResults = recentAccounts.length > 0 || recentPage > 1;
	const currentStartIndex = (recentPage - 1) * recentLimit + 1;
	const currentEndIndex = currentStartIndex + (recentAccounts.length > 0 ? recentAccounts.length : recentLimit) - 1;
	return (
		<Stack spacing="loose" vertical>
			<ResourceList
				resourceName={{ singular: `${accountType} account`, plural: `${accountType} accounts` }}
				filterControl={renderFilterControl()}
				items={recentAccounts}
				totalItemsCount={recentAccountsCount}
				loading={isRecentAccountsLoading}
				renderItem={item => (
					<ResourceItem url={getAccountViewUrl(item.id)} persistActions>
						{children(item)}
					</ResourceItem>
				)}
				flushFilters
			/>
			{hasResults && (
				<Pager
					onChange={onRecentPageChanged}
					page={recentPage}
					limit={recentLimit}
					isLoading={isRecentAccountsLoading}
					totalLoaded={recentAccounts.length}
				/>
			)}
			{hasResults && (
				<CurrentPage>
					<TextStyle variation="subdued">
						Showing {currentStartIndex}-{currentEndIndex} of <FormatNumber value={recentAccountsCount} />
					</TextStyle>
				</CurrentPage>
			)}
		</Stack>
	);
}

const CurrentPage = styled.div`
	text-align: center;
	margin-bottom: 2rem;
`;
