import styled from 'styled-components';

const SlimPolarisTableButtons = styled.div`
	& .Polaris-Button {
		padding-top: 0;
		padding-bottom: 0;
		min-height: auto !important;
		max-height: 0.5rem !important;
	}
`;

export default SlimPolarisTableButtons;
