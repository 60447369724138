import { Page, type PageProps } from '@shopify/polaris';
import { useEffect } from 'react';

export type AdminPageProps = PageProps & {
	pageTitle?: string;
};

export default function AdminPage({ pageTitle, title, children, ...rest }: AdminPageProps) {
	useEffect(() => {
		const calculatedTitle = pageTitle || title;
		document.title = `${calculatedTitle} - Seguno Admin`;
	}, [pageTitle, title]);

	return (
		<Page title={title} {...rest}>
			{children}
		</Page>
	);
}
