import { Stack, TextStyle } from '@segunosoftware/equinox';
import { Checkbox, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useEraseCustomerData } from '../hooks/useEraseCustomerData';

export type EraseCustomerDataModalProps = {
	open: boolean;
	onClose: () => void;
};

export default function EraseCustomerDataModal({ open, onClose }: EraseCustomerDataModalProps) {
	const { eraseCustomerData, isCustomerDataErasing, isCustomerDataErasureSuccessful } = useEraseCustomerData();
	const [customerDataErasureEmail, setCustomerDataErasureEmail] = useState('');
	const [customerVerified, setCustomerVerified] = useState(false);
	const [customerErasureError, setCustomerErasureError] = useState<string>();
	const [showEraseConfirmed, setShowEraseConfirmed] = useState(false);

	useEffect(() => {
		if (!open) {
			setCustomerVerified(false);
			setCustomerDataErasureEmail('');
			setCustomerErasureError(undefined);
		}
	}, [open]);

	function onEraseData() {
		if (customerDataErasureEmail.trim() === '') {
			setCustomerErasureError('An email must be provided.');
			return;
		}
		if (!customerVerified) {
			return;
		}
		setCustomerErasureError(undefined);
		eraseCustomerData(customerDataErasureEmail);
	}

	useEffect(() => {
		if (isCustomerDataErasureSuccessful) {
			setShowEraseConfirmed(true);
			onClose();
		}
	}, [isCustomerDataErasureSuccessful, onClose]);

	return (
		<>
			<Modal
				title="Erase customer data"
				primaryAction={{
					content: 'Erase data',
					onAction: onEraseData,
					destructive: true,
					disabled: customerDataErasureEmail.trim() === '' || !customerVerified,
					loading: isCustomerDataErasing
				}}
				secondaryActions={[{ content: 'Cancel', onAction: onClose, disabled: isCustomerDataErasing }]}
				onClose={onClose}
				open={open}>
				<Modal.Section>
					<Stack vertical>
						<div>
							This will delete all customer data for this email address in <TextStyle variation="strong">all accounts</TextStyle>. This
							action cannot be undone. Please verify with the owner of the email that they want this to be done.
						</div>
						<div>
							After erasure is complete, a file will be downloaded to your computer with a list of domains their email was associated with.
							Please provide that list to the customer.
						</div>
						<Form onSubmit={onEraseData}>
							<FormLayout>
								<TextField
									type="email"
									label="Email address"
									autoComplete="off"
									value={customerDataErasureEmail}
									onChange={setCustomerDataErasureEmail}
									error={customerErasureError}
									autoFocus
								/>
								<Checkbox
									label="I have verified with the customer that they want their data erased"
									checked={customerVerified}
									onChange={setCustomerVerified}
								/>
							</FormLayout>
						</Form>
					</Stack>
				</Modal.Section>
			</Modal>
			<Modal
				title="Customer data erasure completed"
				primaryAction={{ content: 'Close', onAction: () => setShowEraseConfirmed(false) }}
				onClose={() => setShowEraseConfirmed(false)}
				open={showEraseConfirmed}>
				<Modal.Section>
					<p>
						The customer data has been successfully erased and a file has been downloaded to your computer containing the domains that the
						customer was associated with. Please contact the customer with that information to complete the process.
					</p>
				</Modal.Section>
			</Modal>
		</>
	);
}
