import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CANVA_ANNOUNCEMENTS } from '../query-keys';
import type { Delete, Get, Post, Put } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type AnnouncementStatus = 'CRITICAL' | 'INFO' | 'NEUTRAL' | 'POSITIVE' | 'WARN';

export type Announcement = {
	id: number;
	enabled: boolean;
	status: AnnouncementStatus;
	description: string;
	linkUrl: string;
	linkTitle: string;
};

const BASE_PATH = '/canva-announcements';

export function useAnnouncements() {
	const {
		get,
		post,
		put,
		delete: del
	} = useAuthenticatedFetch() as Get<Announcement[]> &
		Post<Partial<Announcement>, Announcement> &
		Put<Partial<Announcement>, Announcement> &
		Delete<number>;
	const queryClient = useQueryClient();

	const { data, isFetching: isLoading } = useQuery(queryKey(CANVA_ANNOUNCEMENTS), () => get(BASE_PATH));

	const { mutate: createAnnouncement, isLoading: isCreating } = useMutation(
		(announcement: Partial<Announcement>) => post(BASE_PATH, announcement),
		{
			onSuccess: () => queryClient.removeQueries(queryKey(CANVA_ANNOUNCEMENTS))
		}
	);
	const { mutate: setAnnouncementEnabled, isLoading: isSettingEnablement } = useMutation(
		({ id, enabled }: { id: number; enabled: boolean }) => post(`${BASE_PATH}/${id}/set-enabled`, { enabled }),
		{
			onSuccess: () => queryClient.removeQueries(queryKey(CANVA_ANNOUNCEMENTS))
		}
	);

	const { mutate: updateAnnouncement, isLoading: isUpdating } = useMutation(
		(announcement: Partial<Announcement>) => put(`${BASE_PATH}/${announcement.id}`, announcement),
		{
			onSuccess: () => queryClient.removeQueries(queryKey(CANVA_ANNOUNCEMENTS))
		}
	);

	const { mutate: deleteAnnouncement, isLoading: isDeleting } = useMutation((id: number) => del(`${BASE_PATH}/${id}`), {
		onSuccess: () => queryClient.removeQueries(queryKey(CANVA_ANNOUNCEMENTS))
	});

	return {
		announcements: data ?? [],
		isLoading,
		createAnnouncement,
		isCreating,
		setAnnouncementEnabled,
		isSettingEnablement,
		updateAnnouncement,
		isUpdating,
		deleteAnnouncement,
		isDeleting
	};
}
