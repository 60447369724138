import { Card, FormatPercent, getLanguage, getNumber, Heading, LocalDate, Stack, TextStyle, useIsMobile } from '@segunosoftware/equinox';
import { Popup } from '@segunosoftware/popup-shared';
import { Badge, Button, Pagination, SkeletonBodyText, Tag } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import { usePopupEngagements } from '../../hooks/popups/usePopupEngagements';
import PopupEngagementStats from './PopupEngagementStats';

const TRIGGER_TYPES = Object.freeze({ LOAD: 'LOAD', EXIT: 'EXIT', SCROLL: 'SCROLL' });

function getDate(dateTime) {
	const date = new Date(dateTime);
	return (
		<span>
			{date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}{' '}
			<TextStyle variation="subdued">at {date.toLocaleTimeString('en-US', { timeStyle: 'long' })}</TextStyle>
		</span>
	);
}

function getStatusBadge(enabled, prefix = '') {
	if (enabled) {
		return <Badge tone="success">{prefix}enabled</Badge>;
	}
	return <Badge tone="new">{prefix}disabled</Badge>;
}

function getActiveBadge(startDate, endDate) {
	if (startDate && endDate) {
		const now = new Date();
		if (now < startDate || now > endDate) {
			return <Badge tone="warning">Inactive due to date</Badge>;
		}
	}
	return null;
}

const DurationUnits = Object.freeze({
	SECONDS: 'SECONDS',
	MINUTES: 'MINUTES',
	HOURS: 'HOURS',
	DAYS: 'DAYS',
	WEEKS: 'WEEKS'
});

const DurationUnitValues = Object.freeze({
	[DurationUnits.SECONDS]: {
		labels: { singular: 'second', plural: 'seconds' },
		conversion: 1
	},
	[DurationUnits.MINUTES]: {
		labels: { singular: 'minute', plural: 'minutes' },
		conversion: 60
	},
	[DurationUnits.HOURS]: {
		labels: { singular: 'hour', plural: 'hours' },
		conversion: 60 * 60
	},
	[DurationUnits.DAYS]: {
		labels: { singular: 'day', plural: 'days' },
		conversion: 60 * 60 * 24
	},
	[DurationUnits.WEEKS]: {
		labels: { singular: 'week', plural: 'weeks' },
		conversion: 60 * 60 * 24 * 7
	}
});

const ARROW_KEY_SHORTCUTS = {
	LEFT: 37,
	RIGHT: 39
};

function getValueInUnit(value, unit) {
	return Math.floor(value / DurationUnitValues[unit].conversion);
}

function getNumericLabelInUnit(value, unit, zeroString) {
	if (value === 0 && zeroString) {
		return zeroString;
	}
	const durationInUnit = getValueInUnit(value, unit);
	const unitLabels = DurationUnitValues[unit].labels;
	const suffix = durationInUnit === 1 ? unitLabels.singular : unitLabels.plural;
	return `${getNumber(durationInUnit)} ${suffix}`;
}

const conversionSort = (a, b) => DurationUnitValues[b].conversion - DurationUnitValues[a].conversion;

function getBestUnit(value, availableUnits = Object.keys(DurationUnits)) {
	if (value === 0) {
		return availableUnits.includes(DurationUnits.DAYS) ? DurationUnits.DAYS : availableUnits[0];
	}
	const unitOrders = availableUnits.sort(conversionSort);
	return unitOrders.find(currentUnit => value % DurationUnitValues[currentUnit].conversion === 0) ?? unitOrders[unitOrders.length - 1];
}

PopupsView.propTypes = {
	account: PropTypes.shape({
		id: PropTypes.number.isRequired
	}).isRequired,
	popups: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			config: PropTypes.shape({
				tags: PropTypes.arrayOf(PropTypes.string).isRequired
			}).isRequired,
			triggerConfig: PropTypes.shape({
				enabled: PropTypes.bool.isRequired,
				activeStartDate: PropTypes.number,
				activeEndDate: PropTypes.number,
				triggerType: PropTypes.string.isRequired,
				priority: PropTypes.number.isRequired,
				delay: PropTypes.number.isRequired,
				restPeriodMillis: PropTypes.number.isRequired,
				viewsRequired: PropTypes.number.isRequired,
				scrollPercentage: PropTypes.number.isRequired,
				invalidateClosesBefore: PropTypes.number.isRequired,
				inclusionPaths: PropTypes.array.isRequired,
				exclusionPaths: PropTypes.array.isRequired
			}).isRequired,
			createdAt: PropTypes.number.isRequired,
			updatedAt: PropTypes.number.isRequired
		})
	),
	isLoading: PropTypes.bool,
	installVerified: PropTypes.bool,
	verifyInstallError: PropTypes.bool
};

export default function PopupsView({ account, popups = [], isLoading = false, installVerified = false, verifyInstallError = false }) {
	const dismissablePopups = popups.filter(popup => !popup.embedded);
	const embeddedPopups = popups.filter(popup => popup.embedded);
	return (
		<>
			<Card
				title={
					<Stack alignment="center">
						<Heading>Popups</Heading>
						{getStatusBadge(installVerified && !verifyInstallError, 'App block: ')}
					</Stack>
				}>
				{dismissablePopups.length === 0 && isLoading && (
					<Card.Section>
						<SkeletonBodyText />
					</Card.Section>
				)}
				{dismissablePopups.length === 0 && !isLoading && (
					<Card.Section>
						<Stack alignment="center">
							<p>No popups found.</p>
						</Stack>
					</Card.Section>
				)}
				{dismissablePopups.map(popup => (
					<PopupView key={popup.id} account={account} popup={popup} />
				))}
			</Card>
			<Card
				title={
					<Stack alignment="center">
						<Heading>Forms</Heading>
						{getStatusBadge(account.paidPlan)}
					</Stack>
				}>
				{embeddedPopups.length === 0 && isLoading && (
					<Card.Section>
						<SkeletonBodyText />
					</Card.Section>
				)}
				{embeddedPopups.length === 0 && !isLoading && (
					<Card.Section>
						<Stack alignment="center">
							<p>No forms found.</p>
						</Stack>
					</Card.Section>
				)}
				{embeddedPopups.map(popup => (
					<PopupView key={popup.id} account={account} popup={popup} />
				))}
			</Card>
		</>
	);
}

function PopupView({ account, popup: { id, name, embedded, config, triggerConfig, createdAt, updatedAt } }) {
	const { engagements, isLoading: isEngagementsLoading } = usePopupEngagements(
		account.id,
		LocalDate.from(account?.createdAt ?? LocalDate.now()),
		LocalDate.now(),
		'all',
		[id]
	);
	const [showPreview, setShowPreview] = useState(false);
	const [stage, setStage] = useState(0);
	const isMobile = useIsMobile();
	const {
		enabled,
		activeStartDate,
		activeEndDate,
		triggerType,
		priority,
		delay,
		restPeriodMillis,
		viewsRequired,
		scrollPercentage,
		inclusionPaths,
		exclusionPaths,
		targetedLanguages,
		targetDeviceType
	} = triggerConfig;

	const restPeriodInSeconds = restPeriodMillis / 1000;
	const onHidePreview = () => setShowPreview(false);

	return (
		<Card.Section
			title={
				<Stack alignment="center" spacing="tight">
					<Heading>{name}</Heading>
					{getStatusBadge(enabled)}
					{enabled ? getActiveBadge(activeStartDate, activeEndDate) : undefined}
				</Stack>
			}
			actions={[{ content: 'Preview', onAction: () => setShowPreview(true), disabled: showPreview }]}>
			<Stack vertical>
				<div>
					{config.tags.length > 0 && (
						<Stack alignment="center" spacing="tight">
							<strong>Tags: </strong>{' '}
							{config.tags.map(tag => (
								<Tag key={tag}>{tag}</Tag>
							))}
						</Stack>
					)}
					{activeStartDate && (
						<div>
							<strong>Start date: </strong>
							{getDate(activeStartDate)}
						</div>
					)}
					{activeEndDate && (
						<div>
							<strong>End date: </strong>
							{getDate(activeEndDate)}
						</div>
					)}
					{!embedded && (
						<>
							<div>
								<strong>Trigger type: </strong>
								{triggerType.toLowerCase()}
							</div>
							<div>
								<strong>Priority: </strong>
								{priority}
							</div>
							<div>
								<strong>Delay: </strong>
								{delay / 1000} seconds
							</div>
							<div>
								<strong>Rest period: </strong> {getNumericLabelInUnit(restPeriodInSeconds, getBestUnit(restPeriodInSeconds))}
							</div>
							<div>
								<strong>Views required: </strong>
								{viewsRequired}
							</div>
							{triggerType === TRIGGER_TYPES.SCROLL && (
								<div>
									<strong>Scroll percentage: </strong>
									<FormatPercent value={scrollPercentage} />
								</div>
							)}
							<div>
								<strong>Included pages: </strong>
								{inclusionPaths.length > 0 ? inclusionPaths.join(', ') : 'All'}
							</div>
							<div>
								<strong>Excluded pages: </strong>
								{exclusionPaths.length > 0 ? exclusionPaths.join(', ') : 'None'}
							</div>
							<div>
								<strong>Targeted languages: </strong>
								{targetedLanguages?.length > 0 ? targetedLanguages.map(getLanguage).join(', ') : 'All'}
							</div>
							<div>
								<strong>Target device type: </strong>
								{targetDeviceType === null ? 'All' : targetDeviceType.charAt(0) + targetDeviceType.slice(1).toLowerCase()}
							</div>
						</>
					)}
					<div>
						<strong>Created at: </strong>
						{getDate(createdAt)}
					</div>
					<div>
						<strong>Last updated: </strong>
						{getDate(updatedAt)}
					</div>
				</div>
				<PopupEngagementStats engagements={engagements?.all} isLoading={isEngagementsLoading} />
			</Stack>
			{showPreview && (
				<PopupContainer>
					<Popup
						config={config}
						onClose={onHidePreview}
						onRedirect={onHidePreview}
						onSubmit={onHidePreview}
						stage={stage}
						brandingHideable={account.brandingHideable}
						phonePermissionRequired={account.postscriptConnected}
						open={showPreview}
						fixed={false}
						triggerConfig={triggerConfig}
						embedded={embedded}
					/>
					<StageContainer alignment={!isMobile && config.theme.position === 'TOP_CENTER' ? 'bottom' : 'top'}>
						<StageSelectorWrapper>
							<StageSelector stage={stage} onChange={setStage} onClose={onHidePreview} maxStages={config.stages.length} />
						</StageSelectorWrapper>
					</StageContainer>
				</PopupContainer>
			)}
		</Card.Section>
	);
}

function StageSelector({ stage, onChange, onClose, maxStages = 0, labelHidden = false }) {
	return (
		<Stack alignment="center" distribution="trailing">
			{!labelHidden && (
				<TextStyle variation="subdued">
					Step {stage + 1} / {maxStages}
				</TextStyle>
			)}
			<Pagination
				hasNext={stage < maxStages - 1}
				hasPrevious={stage > 0}
				onNext={() => onChange(stage + 1)}
				onPrevious={() => onChange(stage - 1)}
				nextKeys={[ARROW_KEY_SHORTCUTS.RIGHT]}
				previousKeys={[ARROW_KEY_SHORTCUTS.LEFT]}
			/>
			<Button onClick={onClose} size="slim">
				Close
			</Button>
		</Stack>
	);
}

const PopupContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
`;

const StageContainer = styled.div`
	position: absolute;
	top: ${({ alignment }) => (alignment === 'top' ? '0.5rem' : 'auto')};
	bottom: ${({ alignment }) => (alignment === 'bottom' ? '0.5rem' : 'auto')};
	left: 1rem;
	right: 1rem;
	border-radius: var(--p-border-radius-1);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StageSelectorWrapper = styled.div`
	background-color: white;
	border-radius: inherit;
	padding: 5px 10px;
`;
