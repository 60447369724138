import { Card, FormatPercent, Stack } from '@segunosoftware/equinox';
import { InlineStack, SkeletonBodyText } from '@shopify/polaris';
import { CurrencyCode } from '@shopify/react-i18n';
import type { DehydratedMarketingPlatformAccount } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { type ConversionRevenue, type SendingStatistics } from '../../hooks/marketingplatform/useMarketingPlatformAccountSendingStatistics';
import { renderDate } from '../../utils/dateUtils';
import ConversionFormatMoney from '../ConversionFormatMoney';

export type SendingStatisticsProps = {
	sendingStatistics: SendingStatistics;
	account: DehydratedMarketingPlatformAccount;
};

export default function MarketingPlatformSendingStatistics({ sendingStatistics, account }: SendingStatisticsProps) {
	function formatConversionRevenue(conversionRevenue: ConversionRevenue) {
		return (
			<InlineStack wrap={false}>
				{conversionRevenue.conversions}/
				<ConversionFormatMoney amount={conversionRevenue.revenue} code={account.currency as CurrencyCode} />
			</InlineStack>
		);
	}

	return (
		<Card sectioned>
			{!sendingStatistics && <SkeletonBodyText lines={3} />}
			{sendingStatistics && (
				<>
					<Card.Section fullWidth flush>
						<Stack spacing="none" vertical>
							<div>
								<strong>Total automations enabled</strong>: {sendingStatistics.totalAutomationsEnabled}
							</div>
						</Stack>
					</Card.Section>
					<Card.Section fullWidth>
						<Stack spacing="none" vertical>
							<div>
								<strong>Total newsletter sends</strong>: {sendingStatistics.totalNewsletterSends}
							</div>
							<div>
								<strong>Total newsletter sends (last 30 days)</strong>: {sendingStatistics.totalNewsletterSendsLast30Days}
							</div>
							<div>
								<strong>Total newsletter sends (last 365 days)</strong>: {sendingStatistics.totalNewsletterSendsLast365Days}
							</div>
							<div>
								<strong>First newsletter send date</strong>: {renderDate(sendingStatistics.firstNewsletterSendDate)}
							</div>
							<div>
								<strong>Last newsletter send date</strong>: {renderDate(sendingStatistics.lastNewsletterSendDate)}
							</div>
						</Stack>
					</Card.Section>
					<Card.Section fullWidth flush>
						<Stack spacing="none" vertical>
							<div>
								<strong>Average newsletter sends (per day)</strong>: {sendingStatistics.avgNewsletterSendsPerDay}
							</div>
							<div>
								<strong>Average newsletter sends (per week)</strong>: {sendingStatistics.avgNewsletterSendsPerWeek}
							</div>
							<div>
								<strong>Average newsletter sends (per month)</strong>: {sendingStatistics.avgNewsletterSendsPerMonth}
							</div>
						</Stack>
					</Card.Section>

					<Card.Section fullWidth>
						<Stack spacing="none" vertical>
							<InlineStack>
								<strong>Total conversions/revenue</strong>: {formatConversionRevenue(sendingStatistics.totalConversionRevenue)}
							</InlineStack>
							<InlineStack>
								<strong>Total conversions/revenue (last 30 days)</strong>:{' '}
								{formatConversionRevenue(sendingStatistics.totalConversionRevenueLast30Days)}
							</InlineStack>
							<InlineStack>
								<strong>Total conversions/revenue (last 365 days)</strong>:{' '}
								{formatConversionRevenue(sendingStatistics.totalConversionRevenueLast365Days)}
							</InlineStack>
							<InlineStack>
								<strong>Average conversions/revenue</strong>: {formatConversionRevenue(sendingStatistics.avgConversionRevenuePerSend)}
							</InlineStack>
						</Stack>
					</Card.Section>
					<Card.Section fullWidth flush>
						<Stack spacing="none" vertical>
							<div>
								<strong>Average delivery rate (last 7 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterDeliveryRateLast7Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average delivery rate (last 30 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterDeliveryRateLast30Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average open rate (last 7 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterOpenRateLast7Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average open rate (last 30 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterOpenRateLast30Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average click rate (last 7 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterClickRateLast7Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average click rate (last 30 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterClickRateLast30Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average bounce rate (last 7 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterBounceRateLast7Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average bounce rate (last 30 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterBounceRateLast30Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average spam rate (last 7 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterSpamRateLast7Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average spam rate (last 30 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterSpamRateLast30Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average unsubscribe rate (last 7 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterUnsubscribeRateLast7Days * 100} decimals={2} />
							</div>
							<div>
								<strong>Average unsubscribe rate (last 30 days)</strong>:{' '}
								<FormatPercent value={sendingStatistics.avgNewsletterUnsubscribeRateLast30Days * 100} decimals={2} />
							</div>
						</Stack>
					</Card.Section>
				</>
			)}
		</Card>
	);
}
