import { useMutation } from '@tanstack/react-query';
import type { Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AppCreditRequest = {
	amount: number;
	description: string;
};

type AppCreditResponse = {
	success: boolean;
};

export function useAppCredit(urlPrefix?: string, accountId?: number) {
	const { post } = useAuthenticatedFetch() as Post<AppCreditRequest, AppCreditResponse>;

	const {
		mutate: issueAppCredit,
		isLoading,
		data,
		reset
	} = useMutation((request: AppCreditRequest) => post(`${urlPrefix}/${accountId}/issue-app-credit`, request));

	return {
		issueAppCredit,
		isLoading,
		successful: data?.success,
		reset
	};
}
