import { useRecentCanvaAccounts } from '../../hooks/canva/useCanvaAccount';
import RecentAccounts, { BILLING_STATUS_FILTER } from '../RecentAccounts';

const CANVA_FILTERS = [BILLING_STATUS_FILTER];

export type SearchParameters = {
	appliedFilters: never[];
	searchText: string;
	recentPage: number;
};

export type RecentAccountsProps = {
	searchParameters: SearchParameters;
	setSearchParameters: VoidFunction;
	limit: number;
};

export default function RecentCanvaAccounts({ searchParameters, setSearchParameters, limit }: RecentAccountsProps) {
	const { accounts, count, load, isLoading } = useRecentCanvaAccounts(
		searchParameters.appliedFilters,
		searchParameters.searchText,
		searchParameters.recentPage,
		limit
	);

	return (
		<RecentAccounts
			accountType="canva"
			recentLimit={limit}
			filters={CANVA_FILTERS}
			onSearchParametersChange={setSearchParameters}
			searchParameters={searchParameters}
			onLoadRecentAccounts={load}
			getAccountViewUrl={id => `/canva-accounts/${id}`}
			recentAccounts={accounts}
			isRecentAccountsLoading={isLoading}
			recentAccountsCount={count}
		/>
	);
}
