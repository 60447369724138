import { DEFAULT_TOAST_DURATION, Toast } from '@shopify/polaris';
import { createContext, useContext, useEffect, useState, type ReactNode } from 'react';

const ToastContext = createContext<((content: string) => void) | undefined>(undefined);

export function useToast() {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error('useToast must be used within a ToastProvider');
	}
	return context;
}

export type ToastProviderProps = {
	children: ReactNode;
};

export default function ToastProvider({ children }: ToastProviderProps) {
	const [content, setContent] = useState('');

	useEffect(() => {
		if (!content) {
			return;
		}
		const timeout = setTimeout(() => {
			setContent('');
		}, DEFAULT_TOAST_DURATION);
		return () => clearTimeout(timeout);
	}, [content]);

	return (
		<ToastContext.Provider value={setContent}>
			{children}
			{content && <Toast content={content} onDismiss={() => setContent('')} />}
		</ToastContext.Provider>
	);
}
