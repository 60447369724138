import { Card, FormatNumber, Heading, Stack } from '@segunosoftware/equinox';
import {
	Button,
	ButtonGroup,
	DataTable,
	Form,
	FormLayout,
	Modal,
	Spinner,
	TextField,
	Tooltip,
	type ColumnContentType
} from '@shopify/polaris';
import { ExportIcon, ViewIcon, XCircleIcon } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import {
	useDiscountSetPriceRule,
	useDiscountSets,
	useExportDiscountSets,
	useTerminateDiscountImport,
	type DiscountSet
} from '../../hooks/bulk/useDiscountSets';
import { useUser } from '../../hooks/useUser';
import { isLeadOrAdmin, isSupport } from '../AccountView';

const LIMIT = 20;

export type DiscountSetsProps = {
	accountId: number;
};

type ExportObject = {
	id: number;
	title: string;
};

export default function DiscountSets({ accountId }: DiscountSetsProps) {
	const user = useUser();
	const { discountSets, loadDiscountSets, isLoading } = useDiscountSets(accountId, LIMIT);
	const { exportDiscountSet, isExporting } = useExportDiscountSets(accountId);
	const { terminateDiscountImport } = useTerminateDiscountImport(accountId);
	const [exportObj, setExportObj] = useState<ExportObject>();

	const [showTerminateImportModal, setShowTerminateImportModal] = useState(false);
	const [terminateImportReason, setTerminateImportReason] = useState('');

	const [showPriceRuleDetails, setShowPriceRuleDetails] = useState(false);

	const [selectedDiscountSetId, setSelectedDiscountSetId] = useState<number>();

	const selectedDiscountSet = discountSets.find(d => d.id === selectedDiscountSetId);

	useEffect(() => {
		if (exportObj) {
			exportDiscountSet(exportObj);
		}
	}, [exportObj, exportDiscountSet]);

	useEffect(() => {
		if (!isExporting) {
			setExportObj(undefined);
		}
	}, [isExporting]);

	const showAdminActions = isLeadOrAdmin(user);
	const showSupportActions = isSupport(user);
	const headings = ['Title', 'Type', 'Total codes', 'Imported codes', 'Status', 'Created at', 'Actions'];
	const contentTypes: ColumnContentType[] = ['text', 'text', 'text', 'text', 'text', 'text', 'text'];

	function onShowTerminateImportModal(discountSet: DiscountSet) {
		setSelectedDiscountSetId(discountSet.id);
		setShowTerminateImportModal(true);
	}

	function onHideTerminateImportModal() {
		setShowTerminateImportModal(false);
	}

	function onShowPriceRuleDetails(discountSet: DiscountSet) {
		setSelectedDiscountSetId(discountSet.id);
		setShowPriceRuleDetails(true);
	}

	function handleTerminateImport() {
		if (!selectedDiscountSetId) {
			return;
		}
		terminateDiscountImport({ id: selectedDiscountSetId, reason: terminateImportReason });
		onHideTerminateImportModal();
	}

	function getDate(date: Date) {
		return `${date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}
				at ${date.toLocaleTimeString('en-US', { timeStyle: 'long' })}`;
	}

	return (
		<Card title="Discount sets" actions={[{ content: 'Refresh', onAction: loadDiscountSets, disabled: isLoading }]} sectioned>
			<DataTable
				columnContentTypes={contentTypes}
				headings={headings}
				rows={discountSets.map(discountSet => {
					const { id, title, discountSetType, totalCodes, totalImportedCodes, queuedAt, createdAt } = discountSet;
					const isImporting = totalCodes > totalImportedCodes;
					const isQueued = Boolean(queuedAt);
					const isExportDisabled = isExporting || isImporting || isQueued;
					const isTerminateDisabled = discountSet.interruptImport || !isImporting || isQueued;

					function getDiscountStatus() {
						if (discountSet.queuedAt) {
							return 'Queued';
						} else if (discountSet.totalCodes > discountSet.totalImportedCodes) {
							return discountSet.interruptImport ? 'Terminating' : 'Importing';
						} else {
							return 'Done';
						}
					}

					const columns = [
						title,
						discountSetType,
						<FormatNumber value={totalCodes} />,
						<FormatNumber value={totalImportedCodes} />,
						getDiscountStatus(),
						getDate(createdAt)
					];
					const actions = [];
					if (showSupportActions) {
						actions.push(
							<Tooltip key="terminate-discount-set" content="Terminate import">
								<Button
									onClick={() => onShowTerminateImportModal(discountSet)}
									disabled={isTerminateDisabled}
									icon={XCircleIcon}
									variant="plain"
								/>
							</Tooltip>,
							<Tooltip key="view-details" content="View details">
								<Button onClick={() => onShowPriceRuleDetails(discountSet)} icon={ViewIcon} variant="plain" />
							</Tooltip>
						);
					}
					if (showAdminActions) {
						actions.push(
							<Tooltip key="export-discount-set" content="Export discounts">
								<Button
									onClick={() => setExportObj({ id, title })}
									loading={Number(exportObj?.id) === Number(id)}
									disabled={isExportDisabled}
									icon={ExportIcon}
									variant="plain"
								/>
							</Tooltip>
						);
					}
					if (actions.length > 0) {
						columns.push(<ButtonGroup noWrap>{actions}</ButtonGroup>);
					}
					return columns;
				})}
				hideScrollIndicator
			/>
			<Modal
				title={`Terminate import: ${selectedDiscountSet?.title}`}
				open={showTerminateImportModal}
				onClose={onHideTerminateImportModal}
				primaryAction={{ content: 'Terminate', onAction: handleTerminateImport }}
				secondaryActions={[{ content: 'Close', onAction: onHideTerminateImportModal }]}>
				<Modal.Section>
					<Form onSubmit={handleTerminateImport}>
						<FormLayout>
							<TextField
								label="Reason for termination"
								value={terminateImportReason}
								onChange={value => setTerminateImportReason(value)}
								autoComplete="off"
								maxLength={255}
								autoFocus
							/>
						</FormLayout>
					</Form>
				</Modal.Section>
			</Modal>
			{showPriceRuleDetails && selectedDiscountSet && (
				<PriceRuleModal accountId={accountId} discountSet={selectedDiscountSet} onClose={() => setShowPriceRuleDetails(false)} />
			)}
		</Card>
	);
}

type PriceRuleModalProps = {
	accountId: number;
	discountSet: DiscountSet;
	onClose: () => void;
};

function PriceRuleModal({ accountId, discountSet, onClose }: PriceRuleModalProps) {
	const { priceRule, isLoading } = useDiscountSetPriceRule(accountId, discountSet.id);

	return (
		<Modal title="Price rule details" onClose={onClose} secondaryActions={[{ content: 'Close', onAction: onClose }]} open>
			{!priceRule && isLoading && (
				<Modal.Section>
					<Stack alignment="center" distribution="center">
						<Spinner />
					</Stack>
				</Modal.Section>
			)}
			{!priceRule && !isLoading && (
				<Modal.Section>
					<p>No price rule found, it may have been deleted in Shopify.</p>
				</Modal.Section>
			)}
			{priceRule && (
				<Modal.Section>
					<Stack vertical>
						<Stack spacing="extraTight" vertical>
							<Heading>Discount set details</Heading>
							<div>
								<strong>Type: </strong>
								{discountSet.generatorDefinition.type}
							</div>
							<div>
								<strong>Total characters: </strong>
								{discountSet.generatorDefinition.totalCharacters}
							</div>
							<div>
								<strong>Separator: </strong>"{discountSet.generatorDefinition.separator}"
							</div>
							<div>
								<strong>Separator spacing: </strong>
								{discountSet.generatorDefinition.separatorSpacing}
							</div>
							<div>
								<strong>Prefix: </strong>
								{discountSet.generatorDefinition.prefix ?? 'None'}
							</div>
							<div>
								<strong>Suffix: </strong>
								{discountSet.generatorDefinition.suffix ?? 'None'}
							</div>
						</Stack>
						<Stack spacing="extraTight" vertical>
							<Heading>Price rule details</Heading>
							<div>
								<strong>Target type: </strong>
								{priceRule.targetType}
							</div>
							<div>
								<strong>Target selection: </strong>
								{priceRule.targetSelection}
							</div>
							<div>
								<strong>Allocation method: </strong>
								{priceRule.allocationMethod}
							</div>
							<div>
								<strong>Value type: </strong>
								{priceRule.valueType}
							</div>
							<div>
								<strong>Value: </strong>
								{priceRule.value}
							</div>
							<div>
								<strong>Allocation limit: </strong>
								{priceRule.allocationLimit ?? 'None'}
							</div>
							<div>
								<strong>Once per customer: </strong>
								{priceRule.oncePerCustomer ? 'Yes' : 'No'}
							</div>
							<div>
								<strong>Target type: </strong>
								{priceRule.targetType}
							</div>
							<div>
								<strong>Usage limit: </strong>
								{priceRule.usageLimit ?? 'None'}
							</div>
							<div>
								<strong>Customer selection: </strong>
								{priceRule.customerSelection}
							</div>
							<div>
								<strong>Prerequisite subtotal range: </strong>
								{priceRule.prerequisiteSubtotalRange?.greaterThanOrEqualTo ?? 'None'}
							</div>
							<div>
								<strong>Prerequisite shipping price range: </strong>
								{priceRule.prerequisiteShippingPriceRange?.lessThanOrEqualTo ?? 'None'}
							</div>
							<div>
								<strong>Prerequisite quantity range: </strong>
								{priceRule.prerequisiteQuantityRange?.greaterThanOrEqualTo ?? 'None'}
							</div>
							<div>
								<strong>Prerequisite customer ids: </strong>
								{(priceRule.prerequisiteCustomerIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Prerequisite collection ids: </strong>
								{(priceRule.prerequisiteCollectionIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Prerequisite product ids: </strong>
								{(priceRule.prerequisiteProductIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Prerequisite variant ids: </strong>
								{(priceRule.prerequisiteVariantIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Prerequisite segment ids: </strong>
								{(priceRule.customerSegmentPrerequisiteIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Entitled collection ids: </strong>
								{(priceRule.entitledCollectionIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Entitled product ids: </strong>
								{(priceRule.entitledProductIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Entitled variant ids: </strong>
								{(priceRule.entitledVariantIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Entitled country ids: </strong>
								{(priceRule.entitledCountryIds ?? []).join(', ')}
							</div>
							<div>
								<strong>Buy X, Get Y: </strong>
								{priceRule.prerequisiteToEntitlementQuantityRatio && priceRule.prerequisiteToEntitlementQuantityRatio.prerequisiteQuantity
									? `Buy ${priceRule.prerequisiteToEntitlementQuantityRatio.prerequisiteQuantity}, Get ${priceRule.prerequisiteToEntitlementQuantityRatio.entitledQuantity}`
									: 'N/A'}
							</div>
							<div>
								<strong>Minimum purchase amount: </strong>
								{priceRule.prerequisiteToEntitlementPurchase?.prerequisiteAmount ?? 'None'}
							</div>
							<div>
								<strong>Start date: </strong>
								{priceRule.startsAt.toLocaleDateString()}
							</div>
							<div>
								<strong>End date: </strong>
								{priceRule.endsAt?.toLocaleDateString() ?? 'None'}
							</div>
						</Stack>
					</Stack>
				</Modal.Section>
			)}
		</Modal>
	);
}
