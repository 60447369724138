import { useQueryString } from '@segunosoftware/equinox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_FILTERS = { billingStatus: ['active', 'unbilled'] };

export function useFilterParameters(initialTab, defaultFilters = DEFAULT_FILTERS) {
	const [searchParams, setSearchParams] = useSearchParams();
	const { page, search, selectedTab, ...rest } = useQueryString();

	const [tabId, setTabId] = useState(selectedTab ?? initialTab);
	const [filters, setFilters] = useState(() =>
		Object.keys(rest).reduce(
			(acc, curr) => {
				acc[curr] = rest[curr];
				return acc;
			},
			{ ...defaultFilters }
		)
	);
	const [recentPage, setRecentPage] = useState(() => (page ? Number(page) : 1));
	const [searchText, setSearchText] = useState(search ?? '');

	const setSelectedTab = useCallback(
		tabId => {
			setTabId(tabId);
			setFilters(defaultFilters);
			setRecentPage(1);
			setSearchText('');
		},
		[defaultFilters]
	);

	useEffect(() => {
		const query = new URLSearchParams({ selectedTab: tabId, page: recentPage, search: searchText, ...filters });
		const defaultQuery = new URLSearchParams({ selectedTab: initialTab, page: 1, search: '', ...defaultFilters });
		for (const [key, value] of defaultQuery.entries()) {
			if (value === query.get(key)) {
				query.delete(key);
			}
		}
		query.sort();
		searchParams.sort();
		if (query.toString() === searchParams.toString()) {
			return;
		}
		setSearchParams(query);
	}, [defaultFilters, filters, initialTab, recentPage, searchParams, searchText, setSearchParams, tabId]);

	const searchParameters = useMemo(
		() => ({
			appliedFilters: filters,
			recentPage,
			searchText
		}),
		[filters, recentPage, searchText]
	);

	const setSearchParameters = useCallback(({ appliedFilters, recentPage, searchText }) => {
		setFilters(appliedFilters);
		setRecentPage(recentPage);
		setSearchText(searchText);
	}, []);

	return { searchParameters, setSearchParameters, selectedTab: tabId, setSelectedTab };
}
