import { Card, FormatMoney, FormatNumber, FormatPercent, Stack, TextStyle } from '@segunosoftware/equinox';
import { Box, DataTable, Pagination, Spinner } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { APP_PURCHASE_TYPE } from '../../hooks/marketingplatform/useGlobalAppPurchases';
import AppPurchaseFilters from '../AppPurchaseFilters';

const INITIAL_SORT_INDEX = 2;
const MAX_ITEMS = 10;

MarketingPlatformGlobalAppPurchases.propTypes = {
	marketingPlatformPurchases: PropTypes.array.isRequired,
	isLoading: PropTypes.bool
};

export default function MarketingPlatformGlobalAppPurchases({ marketingPlatformPurchases, isLoading = false }) {
	const [page, setPage] = useState(0);
	const [sortOptions, setSortOptions] = useState({ sortBy: 'numActivePurchases', direction: 'descending' });
	const headings = ['Name', 'Type', 'Active purchases', 'Revenue'];
	const emptySearch = { identifier: null, type: null };
	const [purchaseSearch, setPurchaseSearch] = useState(emptySearch);

	const numUniqueTemplateIdentifiers = marketingPlatformPurchases.filter(
		({ purchaseType }) => purchaseType === APP_PURCHASE_TYPE.TEMPLATE
	).length;

	const totalPendingPurchases = marketingPlatformPurchases.reduce((total, curr) => {
		const numPending = curr.numPurchases - curr.numActivePurchases;
		return total + numPending;
	}, 0);

	const { totalTemplates, templateRevenue, totalCampaigns, campaignRevenue } = marketingPlatformPurchases.reduce(
		({ totalTemplates, templateRevenue, totalCampaigns, campaignRevenue }, curr) => {
			if (curr.purchaseType === APP_PURCHASE_TYPE.TEMPLATE) {
				totalTemplates += curr.numActivePurchases;
				templateRevenue += curr.revenue;
			} else {
				totalCampaigns += curr.numActivePurchases;
				campaignRevenue += curr.revenue;
			}
			return { totalTemplates, templateRevenue, totalCampaigns, campaignRevenue };
		},
		{ totalTemplates: 0, templateRevenue: 0, totalCampaigns: 0, campaignRevenue: 0 }
	);

	function formatMoney(amount) {
		return <FormatMoney amount={amount / 100} />;
	}

	function onSetFilterProperty(property, value = null) {
		setPurchaseSearch({ ...purchaseSearch, [property]: value });
	}

	function onSort(index, direction) {
		setSortOptions({ sortBy: index === INITIAL_SORT_INDEX ? 'numActivePurchases' : 'revenue', direction });
	}

	const filteredPurchases = marketingPlatformPurchases.filter(({ purchaseIdentifier, purchaseType }) => {
		if (purchaseSearch.identifier && !purchaseIdentifier.toLowerCase().includes(purchaseSearch.identifier.toLowerCase())) {
			return false;
		}
		if (purchaseSearch.type && purchaseSearch.type[0] !== purchaseType) {
			return false;
		}
		return true;
	});

	const { filteredTotalTemplates, filteredTemplateRevenue, filteredTotalCampaigns, filteredCampaignRevenue } = filteredPurchases.reduce(
		({ filteredTotalTemplates, filteredTemplateRevenue, filteredTotalCampaigns, filteredCampaignRevenue }, curr) => {
			if (curr.purchaseType === APP_PURCHASE_TYPE.TEMPLATE) {
				filteredTotalTemplates += curr.numActivePurchases;
				filteredTemplateRevenue += curr.revenue;
			} else {
				filteredTotalCampaigns += curr.numActivePurchases;
				filteredCampaignRevenue += curr.revenue;
			}
			return { filteredTotalTemplates, filteredTemplateRevenue, filteredTotalCampaigns, filteredCampaignRevenue };
		},
		{ filteredTotalTemplates: 0, filteredTemplateRevenue: 0, filteredTotalCampaigns: 0, filteredCampaignRevenue: 0 }
	);

	function getSortedRows() {
		const { sortBy, direction } = sortOptions;
		filteredPurchases.sort((a, b) => b[sortBy] - a[sortBy]);
		if (direction === 'ascending') {
			filteredPurchases.reverse();
		}
		return filteredPurchases
			.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS)
			.map(({ purchaseIdentifier, purchaseType, numActivePurchases, revenue }) => [
				purchaseIdentifier,
				purchaseType === APP_PURCHASE_TYPE.TEMPLATE ? 'Template' : 'Campaign',
				<FormatNumber value={numActivePurchases} />,
				formatMoney(revenue)
			]);
	}

	return (
		<Card title="Seguno: Email Marketing">
			{isLoading && (
				<Card.Section>
					<Stack distribution="center">
						<Spinner />
					</Stack>
				</Card.Section>
			)}
			{!isLoading && (
				<>
					<Card.Section>
						<div>
							<strong>Unique templates purchased: </strong>
							<FormatNumber value={numUniqueTemplateIdentifiers} />
						</div>
						<div>
							<strong>Total newsletter template purchases: </strong>
							<FormatNumber value={totalTemplates} />
						</div>
						<div>
							<strong>Total newsletter template revenue: </strong>
							{formatMoney(templateRevenue)}
						</div>
						<div>
							<strong>Unique campaigns purchased: </strong>
							<FormatNumber value={marketingPlatformPurchases.length - numUniqueTemplateIdentifiers} />
						</div>
						<div>
							<strong>Total campaign purchases: </strong>
							<FormatNumber value={totalCampaigns} />
						</div>
						<div>
							<strong>Total campaign revenue: </strong>
							{formatMoney(campaignRevenue)}
						</div>
						<div>
							<strong>Total pending purchases: </strong>
							<TextStyle>
								<FormatNumber value={totalPendingPurchases} /> -{' '}
								<FormatPercent value={(totalPendingPurchases / (totalTemplates + totalCampaigns)) * 100} decimals={2} />
							</TextStyle>
						</div>
					</Card.Section>
					<Card.Section
						title={
							<Box paddingBlock="200" paddingInline="400">
								<Stack alignment="center">
									<Stack.Item fill>
										<TextStyle variation="strong">Top purchases</TextStyle>
									</Stack.Item>
									<Pagination
										hasPrevious={page > 0}
										hasNext={page < Math.ceil(filteredPurchases.length / MAX_ITEMS) - 1}
										onNext={() => setPage(page + 1)}
										onPrevious={() => setPage(page - 1)}
									/>
								</Stack>
							</Box>
						}
						flush>
						<AppPurchaseFilters
							showStatus={false}
							identifier={purchaseSearch.identifier}
							type={purchaseSearch.type}
							onChangeFilter={onSetFilterProperty}
							onClearAll={() => setPurchaseSearch(emptySearch)}
						/>
						<DataTable
							columnContentTypes={['text', 'text', 'numeric', 'numeric']}
							headings={headings}
							rows={getSortedRows()}
							totals={[
								'',
								'',
								<FormatNumber value={filteredTotalTemplates + filteredTotalCampaigns} />,
								formatMoney(filteredTemplateRevenue + filteredCampaignRevenue)
							]}
							sortable={[false, false, true, true]}
							defaultSortDirection="descending"
							initialSortColumnIndex={INITIAL_SORT_INDEX}
							onSort={onSort}
							verticalAlign="middle"
							showTotalsInFooter
							hideScrollIndicator
							truncate
						/>
					</Card.Section>
				</>
			)}
		</Card>
	);
}
