import { Card, Stack } from '@segunosoftware/equinox';
import { Badge } from '@shopify/polaris';
import styled from 'styled-components';
import { type PopupAccount } from '../../hooks/popups/usePopupAccount';

export function PopupConnections({ account }: { account: PopupAccount }) {
	const {
		postscriptConnected,
		postscriptDefaultKeywordId,
		postscriptPropertyNameMapping: { firstName, lastName, birthdate }
	} = account;
	return (
		<Card title="Connections">
			<Card.Section
				title={
					<Stack alignment="center" spacing="tight">
						<div>
							<strong>Postscript</strong>:{' '}
							{postscriptConnected ? <Badge tone="success">connected</Badge> : <Badge tone="new">disabled</Badge>}
						</div>
					</Stack>
				}>
				{postscriptConnected && (
					<Card.Section>
						<GapCloser />
						<div>
							<strong>Default keyword ID</strong>: {postscriptDefaultKeywordId}
						</div>
						<div>
							<strong>Subscriber property mapping</strong>:
							<Card.Section>
								<GapCloser />
								<div>
									<strong>First name</strong>: {firstName ?? 'none'}
								</div>
								<div>
									<strong>Last name</strong>: {lastName ?? 'none'}
								</div>
								<div>
									<strong>Birthdate</strong>: {birthdate ?? 'none'}
								</div>
							</Card.Section>
						</div>
						<GapCloser />
					</Card.Section>
				)}
			</Card.Section>
		</Card>
	);
}

const GapCloser = styled.div`
	margin-top: -0.5rem;
`;
