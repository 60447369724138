import { Card, type Callback } from '@segunosoftware/equinox';
import { Badge, Button, EmptySearchResult, IndexTable, InlineStack, ProgressBar, Text, Tooltip, useBreakpoints } from '@shopify/polaris';
import {
	AlertCircleIcon,
	CheckCircleIcon,
	ChevronDownIcon,
	ChevronRightIcon,
	DuplicateIcon,
	ImageAddIcon,
	UnknownDeviceIcon,
	XCircleIcon
} from '@shopify/polaris-icons';
import { Fragment, useState } from 'react';
import { useReviewImportJobs, type ImportJob, type ImportSource, type ImportStatus } from '../hooks/reviews/useReviewAccount';
import { renderDateShort, renderTime } from '../utils/dateUtils';
import { Duration } from './DateTime';

const IMPORT_JOB_STATUS_TO_VARIANT: Record<ImportStatus, string> = {
	INGESTING: 'info',
	ERROR: 'critical',
	NO_DATA: 'critical',
	DIGESTING: 'info',
	COMPLETED: 'success',
	CANCELLED: 'critical'
};

const IMPORT_JOB_SOURCE_TO_STRING: Record<ImportSource, string> = {
	SHOPIFY: 'Shopify',
	JUDGEME: 'Judge.me',
	FERA: 'Fera',
	JUNIP: 'Junip',
	LOOX: 'Loox',
	YOTPO: 'Yotpo'
};

type ImportJobModalTableProps = {
	id: string;
};

type ImportJobRow = {
	position: number;
} & ImportJob;

type ImportJobGroup = {
	position: number;
	importJobs: ImportJobRow[];
	id: string;
};

type Groups = {
	[key: string]: ImportJobGroup;
};

function getIcon(variant: string) {
	switch (variant) {
		case 'notFound':
			return UnknownDeviceIcon;
		case 'error':
			return AlertCircleIcon;
		case 'duplicate':
			return DuplicateIcon;
		case 'critical':
			return XCircleIcon;
		case 'success':
			return CheckCircleIcon;
		case 'mediaImport':
			return ImageAddIcon;
		case 'mediaFail':
			return XCircleIcon;
		default:
			return undefined;
	}
}

function getTone(variant: string) {
	switch (variant) {
		case 'notFound':
			return 'warning';
		case 'critical':
			return 'critical';
		case 'duplicate':
			return 'warning';
		case 'success':
			return 'success';
		case 'info':
			return 'info';
		default:
			return undefined;
	}
}

function groupRowsByCreatedAtDate(items: ImportJob[]) {
	let position = -1;
	const groups: Groups = items.reduce((groups: Groups, importJob: ImportJob) => {
		const { createdAt } = importJob;
		const shortDate = renderDateShort(createdAt);

		if (!groups[shortDate]) {
			position += 1;

			groups[shortDate] = {
				position,
				importJobs: [],
				id: `order-${shortDate.split(' ').join('-')}`
			};
		}

		groups[shortDate].importJobs.push({
			...importJob,
			position: position + 1
		});

		position += 1;
		return groups;
	}, {});

	return groups;
}

function ImportDurationCell({ createdAt, completedAt }: { createdAt?: Date; completedAt?: Date }) {
	if (completedAt && createdAt) {
		const completed = completedAt.getTime();
		const created = createdAt.getTime();

		return <Duration amount={created - completed} />;
	} else {
		return '--';
	}
}

function FirstRow({
	index,
	id,
	position,
	source,
	createdAt,
	status,
	percentComplete,
	totalImported,
	totalMediaFailed,
	totalMediaImported,
	collapsed,
	setCollapsed,
	totalErrors,
	totalProductNotFound,
	totalDuplicates
}: {
	position: number;
	index: number;
	source: ImportSource;
	id: string;
	createdAt: Date;
	status: 'INGESTING' | 'ERROR' | 'NO_DATA' | 'DIGESTING' | 'COMPLETED' | 'CANCELLED';
	percentComplete: number;
	totalImported: number;
	totalMediaFailed: number;
	totalMediaImported: number;
	collapsed?: boolean;
	setCollapsed: Callback<boolean>;
	totalErrors: number;
	totalProductNotFound: number;
	totalDuplicates: number;
}) {
	const statusCamelCase = (status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()).replace(/_/g, ' ');
	const variant = IMPORT_JOB_STATUS_TO_VARIANT[status];
	return (
		<IndexTable.Row key={index} id={id + '-row' + index} position={position}>
			<IndexTable.Cell>
				<Button
					onClick={() => setCollapsed(!collapsed)}
					icon={collapsed ? ChevronRightIcon : ChevronDownIcon}
					ariaExpanded={collapsed}
					variant="plain"
				/>
			</IndexTable.Cell>
			<IndexTable.Cell>
				<Text variant="bodyLg" fontWeight="medium" as="span" alignment="start">
					{IMPORT_JOB_SOURCE_TO_STRING[source]}
				</Text>
			</IndexTable.Cell>
			<IndexTable.Cell>
				<InlineStack wrap={false} blockAlign="center" gap="100">
					<Text as="span" variant="bodySm" fontWeight="semibold">
						Created at:
					</Text>
					<Text as="span" variant="bodySm">
						{renderTime(createdAt)}
					</Text>
				</InlineStack>
			</IndexTable.Cell>
			<IndexTable.Cell>
				<Badge icon={getIcon(variant)} tone={getTone(variant)}>
					{statusCamelCase}
				</Badge>
			</IndexTable.Cell>
			<ProgressBarCell progress={percentComplete} text={'Imported'} count={totalImported} primary={true} />
			<IndexTable.Cell>
				<InlineStack wrap={false} blockAlign="center" gap="100">
					<Tooltip
						content={
							<Text as="span" alignment="center" numeric breakWord={false}>
								Total media failed
							</Text>
						}>
						<Badge icon={getIcon('mediaFail')} tone={totalMediaFailed > 0 ? 'warning' : undefined}>
							{totalMediaFailed.toString()}
						</Badge>
					</Tooltip>

					<Tooltip
						content={
							<Text as="span" alignment="center" numeric breakWord={false}>
								Total media imported
							</Text>
						}>
						<Badge icon={getIcon('mediaImport')} tone={totalMediaImported > 0 ? 'info' : undefined}>
							{totalMediaImported.toString()}
						</Badge>
					</Tooltip>
				</InlineStack>
			</IndexTable.Cell>
			<IndexTable.Cell>
				<InlineStack wrap={false} blockAlign="center" gap="100">
					<Tooltip
						content={
							<Text as="span" alignment="center" numeric breakWord={false}>
								Total errors
							</Text>
						}>
						<Badge icon={getIcon('error')} tone={totalErrors > 0 ? 'warning' : undefined}>
							{totalErrors.toString()}
						</Badge>
					</Tooltip>

					<Tooltip
						content={
							<Text as="span" alignment="center" numeric breakWord={false}>
								Total product not found
							</Text>
						}>
						<Badge icon={getIcon('notFound')} tone={totalProductNotFound > 0 ? 'warning' : undefined}>
							{totalProductNotFound.toString()}
						</Badge>
					</Tooltip>

					<Tooltip
						content={
							<Text as="span" alignment="center" numeric breakWord={false}>
								Total duplicates
							</Text>
						}>
						<Badge icon={getIcon('duplicate')} tone={totalDuplicates > 0 ? 'warning' : undefined}>
							{totalDuplicates.toString()}
						</Badge>
					</Tooltip>
				</InlineStack>
			</IndexTable.Cell>
		</IndexTable.Row>
	);
}

function SecondRow({
	completedAt,
	position,
	progressBarCount,
	progressBarStatus,
	id
}: Partial<ImportJob> & { totalDuplicates: number; position: number; progressBarStatus: number; progressBarCount: number }) {
	return (
		<IndexTable.Row rowType="data" id={`${id}-child-0`} position={position}>
			<IndexTable.Cell colSpan={2} />
			<IndexTable.Cell>
				<InlineStack wrap={false} blockAlign="center" gap="100">
					<Text as="span" variant="bodySm" fontWeight="semibold">
						Completed at:
					</Text>
					{completedAt ? (
						<Text as="span" variant="bodySm">
							{renderTime(completedAt)}
						</Text>
					) : (
						<Text as="span"> --</Text>
					)}
				</InlineStack>
			</IndexTable.Cell>
			<IndexTable.Cell />
			<ProgressBarCell progress={progressBarStatus} text={'Digested'} count={progressBarCount} />
		</IndexTable.Row>
	);
}

function ThirdRow({
	position,
	id,
	createdAt,
	completedAt,
	progressBarStatus,
	progressBarCount
}: Partial<ImportJob> & { totalErrors: number; position: number; progressBarCount: number; progressBarStatus: number }) {
	return (
		<IndexTable.Row rowType="data" id={id + '-child-1'} position={position}>
			<IndexTable.Cell colSpan={2} />
			<IndexTable.Cell>
				<InlineStack wrap={false} blockAlign="center" gap="100">
					<InlineStack wrap={false} blockAlign="center" gap="100">
						<Text as="span" variant="bodySm" fontWeight="semibold">
							Time elapsed:
						</Text>
						<ImportDurationCell createdAt={createdAt} completedAt={completedAt} />
					</InlineStack>
				</InlineStack>
			</IndexTable.Cell>
			<IndexTable.Cell />
			<ProgressBarCell progress={progressBarStatus} text="Ingested" count={progressBarCount} />
		</IndexTable.Row>
	);
}

function FourthRow({
	position,
	id,
	progressBarStatus,
	progressBarCount
}: Partial<ImportJob> & { totalProductNotFound: number; position: number; progressBarCount: number; progressBarStatus: number }) {
	return (
		<IndexTable.Row rowType="data" id={id + '-child-2'} position={position}>
			<IndexTable.Cell colSpan={4} />
			<ProgressBarCell progress={progressBarStatus} text="Rejected" count={progressBarCount} />
		</IndexTable.Row>
	);
}

function ProgressBarCell({ progress, text, count, primary }: { progress: number; text: string; count: number; primary?: boolean }) {
	return (
		<>
			<IndexTable.Cell>
				<Text as="span" alignment="end" variant="bodyXs" fontWeight={primary ? 'bold' : 'regular'}>
					{text}
				</Text>
			</IndexTable.Cell>
			<IndexTable.Cell colSpan={1}>
				<InlineStack wrap={false} blockAlign="center" gap="200">
					<div style={{ width: 100 }}>
						<ProgressBar progress={progress} size="small" tone={primary ? 'primary' : 'highlight'} />
					</div>
					<Text as="span" alignment="end" numeric variant="bodyXs">
						{count}
					</Text>
				</InlineStack>
			</IndexTable.Cell>
		</>
	);
}

function TableRow({
	id,
	percentComplete,
	source,
	completedAt,
	totalRejected,
	totalIngested,
	totalDigested,
	totalProductNotFound,
	totalDuplicates,
	totalErrors,
	totalImported,
	totalMediaFailed,
	totalMediaImported,
	status,
	position,
	index,
	createdAt
}: ImportJobRow & { index: number }) {
	const [collapsed, setCollapsed] = useState(true);

	return (
		<>
			<FirstRow
				index={index}
				id={id}
				position={position}
				source={source}
				createdAt={createdAt}
				status={status}
				percentComplete={totalImported > 0 && totalIngested > 0 ? (totalImported / totalIngested) * 100 : 0}
				totalImported={totalImported}
				totalMediaFailed={totalMediaFailed}
				totalMediaImported={totalMediaImported}
				totalDuplicates={totalDuplicates}
				totalErrors={totalErrors}
				totalProductNotFound={totalProductNotFound}
				collapsed={collapsed}
				setCollapsed={setCollapsed}
			/>
			{!collapsed && (
				<>
					<SecondRow
						position={position}
						id={id}
						completedAt={completedAt}
						progressBarStatus={percentComplete}
						progressBarCount={totalDigested}
						totalDuplicates={totalDuplicates}
					/>
					<ThirdRow
						position={position}
						id={id}
						createdAt={createdAt}
						completedAt={completedAt}
						totalErrors={totalErrors}
						progressBarStatus={totalIngested > 0 && totalIngested > 0 ? (totalIngested / (totalRejected + totalIngested)) * 100 : 0}
						progressBarCount={totalIngested}
					/>
					<FourthRow
						position={position}
						id={id}
						totalProductNotFound={totalProductNotFound}
						progressBarStatus={totalRejected > 0 && totalIngested > 0 ? (totalRejected / (totalRejected + totalIngested)) * 100 : 0}
						progressBarCount={totalRejected}
					/>
				</>
			)}
		</>
	);
}

export default function ImportJobModelTable({ id }: ImportJobModalTableProps) {
	const { jobs, isLoading, isSuccess } = useReviewImportJobs(id);

	const importJobsGrouped = isSuccess ? groupRowsByCreatedAtDate(jobs) : {};

	const emptyStateMarkup = <EmptySearchResult title={'No import jobs'} withIllustration />;

	const rowMarkup = Object.keys(importJobsGrouped).map((createdAt, index) => {
		const { importJobs = [], position, id: subheaderId } = importJobsGrouped[createdAt];

		return (
			<Fragment key={subheaderId}>
				<IndexTable.Row rowType="subheader" id={subheaderId} key={id} position={position} disabled={index === 1}>
					<IndexTable.Cell />
					<IndexTable.Cell colSpan={10} scope="colgroup" as="th" id={subheaderId}>
						<Text as="span" variant="bodySm" fontWeight="bold">
							{'Last imported: ' + renderDateShort(createdAt)}
						</Text>
					</IndexTable.Cell>
				</IndexTable.Row>
				{importJobs
					.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
					.map(({ ...props }, index) => {
						return <TableRow {...props} index={index} />;
					})}
			</Fragment>
		);
	});

	return (
		<Card title="Import jobs" sectioned key={id + '-import-jobs'}>
			<IndexTable
				loading={isLoading}
				condensed={useBreakpoints().smDown}
				resourceName={{
					singular: 'Import job',
					plural: 'Import jobs'
				}}
				itemCount={isSuccess ? jobs.length : 0}
				emptyState={emptyStateMarkup}
				selectable={false}
				defaultSortDirection="ascending"
				headings={[
					{ title: '' },
					{ title: 'Source', id: 'source' },
					{ title: 'Timestamps', id: 'timestamps' },
					{ title: 'Import status', id: 'status' },
					{ title: '', hidden: true },
					{ title: 'Import progress', id: 'percentComplete' },
					{ title: 'Media status', id: 'mediaStatus' },
					{ title: 'Issues status', id: 'issuesFound' }
				]}>
				{rowMarkup}
			</IndexTable>
		</Card>
	);
}
