import { Navigate, useLocation } from 'react-router-dom';
import { useAccessToken } from '../AccessTokenProvider';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const { accessToken } = useAccessToken();
	const location = useLocation();

	if (!accessToken) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

	return element;
}
