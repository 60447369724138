import { getDateFromTimestamp, queryKey, type Timestamp } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import type { BaseAccount, Job } from '../../lib/shared-types';
import {
	RECENT_REVIEW_ACCOUNTS,
	REVIEW_ACCOUNT,
	REVIEW_ACCOUNT_IMPORT_JOBS,
	REVIEW_ACCOUNT_JOBS,
	REVIEW_ACCOUNT_PUBLISHED_STATUS,
	REVIEW_ACCOUNT_SUMMARY
} from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
import { useRecentAccounts } from '../useRecentAccounts';

export type ReviewAccount = BaseAccount & {};

export type PublishedStatus = {
	PUBLISHED: number;
	UNPUBLISHED: number;
};

export type ReviewsRating = Record<string, number>;

const ACCOUNT_PATH = '/review-accounts';

export function useReviewAccount(id: string = '') {
	const { get } = useAuthenticatedFetch() as Get<ReviewAccount>;

	const { data: account } = useQuery(queryKey(REVIEW_ACCOUNT, id), () => get(`${ACCOUNT_PATH}/${id}`));

	return { account };
}

export function useReviewAccountJobs(id: string = '') {
	const { get } = useAuthenticatedFetch() as Get<Job[]>;

	const {
		data,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery(queryKey(REVIEW_ACCOUNT_JOBS, id), () => get(`${ACCOUNT_PATH}/${id}/jobs`));

	return { jobs: data ?? [], onLoadJobs, isJobsLoading };
}

export function useRecentReviewAccounts(filters: any, search?: string, page?: number, limit?: number) {
	return useRecentAccounts(RECENT_REVIEW_ACCOUNTS, ACCOUNT_PATH, filters, search, page, limit);
}

export type ImportStatus = 'INGESTING' | 'ERROR' | 'NO_DATA' | 'DIGESTING' | 'COMPLETED' | 'CANCELLED';

export type ImportSource = 'SHOPIFY' | 'JUDGEME' | 'LOOX' | 'YOTPO' | 'JUNIP' | 'FERA';

export type ImportJob = {
	id: string;
	status: ImportStatus;
	source: ImportSource;
	totalRejected: number;
	totalIngested: number;
	totalDigested: number;
	totalProductNotFound: number;
	totalDuplicates: number;
	totalErrors: number;
	totalImported: number;
	totalMediaFailed: number;
	totalMediaImported: number;
	percentComplete: number;
	createdAt: Date;
	completedAt?: Date;
};

type DehydratedImportJob = ImportJob & {
	createdAt: Timestamp;
	completedAt?: Timestamp;
};

function hydrateImportJob(importJob: DehydratedImportJob): ImportJob {
	return {
		...importJob,
		createdAt: new Date(importJob.createdAt),
		completedAt: getDateFromTimestamp(importJob.completedAt)
	};
}

export function useReviewImportJobs(id: string) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedImportJob[]>;

	const { data, isLoading, isSuccess } = useQuery(
		queryKey(REVIEW_ACCOUNT_IMPORT_JOBS, id),
		() => get(`/review-accounts/${id}/import-jobs`),
		{ select: data => data.map(hydrateImportJob) }
	);

	return { jobs: data ?? [], isLoading, isSuccess };
}

export function useReviewSummary(id: string) {
	const { get } = useAuthenticatedFetch() as Get<ReviewsRating>;

	const { data, isLoading, isSuccess } = useQuery(queryKey(REVIEW_ACCOUNT_SUMMARY, id), () =>
		get(`/review-accounts/${id}/account-review-summary`)
	);

	return { ratings: data ?? {}, isLoading, isSuccess };
}

export function useReviewPublishedStatus(id: string) {
	const { get } = useAuthenticatedFetch() as Get<PublishedStatus>;

	const { data, isLoading, isSuccess } = useQuery(queryKey(REVIEW_ACCOUNT_PUBLISHED_STATUS, id), () =>
		get(`/review-accounts/${id}/published-status-counts`)
	);

	return { publishedStatus: data ?? { PUBLISHED: null, UNPUBLISHED: null }, isLoading, isSuccess };
}
