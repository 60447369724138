import { Card, Stack, TextStyle } from '@segunosoftware/equinox';
import { Banner } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useMarketingPlatformAccountActions } from '../../hooks/marketingplatform/useMarketingPlatformAccountActions';
import { useMarketingPlatformAccountResourceFeedback } from '../../hooks/marketingplatform/useMarketingPlatformAccountResourceFeedback';

ResourceFeedbackCard.propTypes = {
	account: PropTypes.shape({
		id: PropTypes.number.isRequired
	}).isRequired
};

export default function ResourceFeedbackCard({ account }) {
	const { resourceFeedback } = useMarketingPlatformAccountResourceFeedback(account);
	const { setResourceFeedback, isSettingResourceFeedback } = useMarketingPlatformAccountActions(account.id);

	if (!resourceFeedback || resourceFeedback.state === 'success') {
		return null;
	}

	function onRemoveResourceFeedback() {
		setResourceFeedback(null);
	}

	return (
		<Card
			title="Shopify dashboard notice"
			actions={[{ content: 'Remove', onAction: onRemoveResourceFeedback, loading: isSettingResourceFeedback }]}
			sectioned>
			<Stack vertical>
				<Banner tone="warning">
					<p>Seguno: Email Marketing {resourceFeedback.messages[0]}</p>
				</Banner>
				<TextStyle variation="subdued">
					This message can be dismissed by the merchant. If it is, no further messages will be visible.
				</TextStyle>
			</Stack>
		</Card>
	);
}
