import { Stack, TextStyle } from '@segunosoftware/equinox';
import PropTypes from 'prop-types';
import { useRecentReviewAccounts } from '../../hooks/reviews/useReviewAccount';
import RecentAccounts, { BILLING_STATUS_FILTER, INSTALL_DATE_FILTER, SHOP_PLAN_FILTER } from '../RecentAccounts';

const REVIEWS_FILTERS = [BILLING_STATUS_FILTER, SHOP_PLAN_FILTER, INSTALL_DATE_FILTER];

RecentReviewAccounts.propTypes = {
	searchParameters: PropTypes.shape({
		appliedFilters: PropTypes.object.isRequired,
		searchText: PropTypes.string.isRequired,
		recentPage: PropTypes.number.isRequired
	}).isRequired,
	setSearchParameters: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
};

export default function RecentReviewAccounts({ searchParameters, setSearchParameters, limit }) {
	const { accounts, count, load, isLoading } = useRecentReviewAccounts(
		searchParameters.appliedFilters,
		searchParameters.searchText,
		searchParameters.recentPage,
		limit
	);

	return (
		<RecentAccounts
			accountType="review"
			recentLimit={limit}
			filters={REVIEWS_FILTERS}
			onSearchParametersChange={setSearchParameters}
			searchParameters={searchParameters}
			onLoadRecentAccounts={load}
			getAccountViewUrl={id => `/review-accounts/${id}`}
			recentAccounts={accounts}
			isRecentAccountsLoading={isLoading}
			recentAccountsCount={count}>
			{item => (
				<Stack spacing="tight" vertical>
					<Stack alignment="center">
						<TextStyle variation="strong">
							{item.name} ({item.shop})
						</TextStyle>
						<div>{item.platformPlan}</div>
						<div>{item.billingStatus}</div>
					</Stack>
				</Stack>
			)}
		</RecentAccounts>
	);
}
