import { queryKey, type OmitExact } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { APPS } from './query-keys';
import type { Get } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AppDetail = {
	name: string;
	urlPrefix: string;
};

export function getAppDetails(install: AppInstall): AppDetail {
	switch (install.app) {
		case 'SEGUNO':
			return { name: 'Seguno Email', urlPrefix: '/marketing-platform-accounts' };
		case 'BANNER':
			return { name: 'Seguno Banners', urlPrefix: '/banner-accounts' };
		case 'BULK':
			return { name: 'Bulk Discount Code Bot', urlPrefix: '/bulk-accounts' };
		case 'MAIL_CONNECT':
			return { name: 'Mail Connect', urlPrefix: '/mailchimp-connector-accounts' };
		case 'POPUP':
			return { name: 'Seguno Popups', urlPrefix: '/popup-accounts' };
		case 'CANVA':
			return { name: 'Canva Connect', urlPrefix: '/canva-accounts' };
		case 'REVIEWS':
			return { name: 'Seguno Reviews', urlPrefix: '/review-accounts' };
		default:
			throw new Error('Unknown app type: ' + install.app);
	}
}

export type AppInstall = OmitExact<DehydratedAppInstall, 'installedAt' | 'billingActivatedAt'> & {
	installedAt: Date;
	billingActivatedAt?: Date;
};

export type App = 'SEGUNO' | 'POPUP' | 'BANNER' | 'BULK' | 'MAIL_CONNECT' | 'CANVA' | 'REVIEWS';

type DehydratedAppInstall = {
	app: App;
	id: number;
	billingStatus: 'unbilled' | 'active' | 'frozen' | 'cancelled';
	platformPlan: string;
	billingExternalId?: string;
	billingActivatedAt?: number;
	installedAt: number;
};

export function useApps(shop?: string) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedAppInstall[]>;
	const { data: apps, isFetching: isLoading } = useQuery(queryKey(APPS, shop), () => get(`/apps?shop=${shop}`), {
		select: data =>
			data.map(app => {
				const install: AppInstall = {
					...app,
					installedAt: new Date(app.installedAt),
					billingActivatedAt: typeof app.billingActivatedAt === 'number' ? new Date(app.billingActivatedAt) : undefined
				};
				return install;
			}),
		enabled: Boolean(shop)
	});
	return { apps: apps ?? [], isLoading };
}
