import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MAILCHIMP_CONNECTOR_ACCOUNT, MAILCHIMP_CONNECTOR_ACCOUNT_DETAILS, MAILCHIMP_CONNECTOR_ACCOUNT_JOBS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useMailchimpConnectorAccount(id) {
	const { get } = useAuthenticatedFetch();
	const { data: account } = useQuery(queryKey(MAILCHIMP_CONNECTOR_ACCOUNT, id), () => get(`/mailchimp-connector-accounts/${id}`));
	const {
		data: jobs,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery(queryKey(MAILCHIMP_CONNECTOR_ACCOUNT_JOBS, id), () => get(`/mailchimp-connector-accounts/${id}/jobs`));
	const {
		data: mailchimpDetails,
		refetch: onLoadMailchimpDetails,
		isFetching: isMailchimpDetailsLoading
	} = useQuery(queryKey(MAILCHIMP_CONNECTOR_ACCOUNT_DETAILS, id), () => get(`/mailchimp-connector-accounts/${id}/mailchimp-details`), {
		enabled: false
	});
	return {
		account,
		jobs,
		onLoadJobs,
		isJobsLoading,
		mailchimpDetails,
		onLoadMailchimpDetails,
		isMailchimpDetailsLoading
	};
}
