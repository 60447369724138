import { Frame, type FrameProps } from '@shopify/polaris';

export default function AppFrame({ children, ...rest }: FrameProps) {
	return (
		<Frame
			logo={{
				topBarSource: '/images/seguno.svg',
				url: '/',
				width: 124,
				accessibilityLabel: 'Seguno Admin'
			}}
			{...rest}>
			{children}
		</Frame>
	);
}
