import { Card, Stack, TypedSelect, useCopyToClipboard, usePrevious } from '@segunosoftware/equinox';
import {
	Badge,
	Button,
	ButtonGroup,
	DataTable,
	DropZone,
	FormLayout,
	Labelled,
	Link,
	Modal,
	ProgressBar,
	Text,
	Tooltip,
	type ColumnContentType
} from '@shopify/polaris';
import { IncomingIcon, OutgoingIcon, XCircleIcon } from '@shopify/polaris-icons';
import download from 'downloadjs';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	useCancelMarketingPlatformManualJob,
	useCreateMarketingPlatformManualJob,
	useMarketingPlatformAccount,
	type ManualJob,
	type ManualJobStatus,
	type ManualJobType
} from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { useUser } from '../../hooks/useUser';
import { isLeadOrAdmin } from '../AccountView';
import { DateOrDefault, Duration } from '../DateTime';

const COLUMN_TYPES: ColumnContentType[] = ['text', 'text', 'text', 'text', 'text'];
const COLUMN_TYPES_WITH_ACCOUNTS: ColumnContentType[] = ['text', ...COLUMN_TYPES];

const HEADINGS = ['Type', 'Date', 'Status', 'Total time', 'Actions'];
const HEADINGS_WITH_ACCOUNTS = ['Account', ...HEADINGS];

const IS_CANCELLABLE_JOB: ManualJobStatus[] = ['CREATED', 'RUNNING'];

const FORMATTED_JOB_TYPES: Record<ManualJobType, string> = {
	SUPPRESSED_EMAILS_IMPORT: 'Suppressed emails import'
};

const JOB_TYPE_SAMPLE_FILES: Record<ManualJobType, () => void> = {
	SUPPRESSED_EMAILS_IMPORT: () => download('/manualjobs/samples/suppressed-emails.csv')
};

export type ManualJobsProps = {
	manualJobs: ManualJob[][];
	isLoading: boolean;
	onRefetch: () => void;
	hasNextPage?: boolean;
	onFetchNextPage?: () => void;
	accountId?: string | number;
};

export default function ManualJobs({ manualJobs, isLoading, onRefetch, hasNextPage = false, onFetchNextPage, accountId }: ManualJobsProps) {
	const user = useUser();
	const { cancelManualJob, isLoading: isCancellingManualJob } = useCancelMarketingPlatformManualJob();
	const [currentPage, setCurrentPage] = useState(0);
	const [inputModalShowing, setInputModalShowing] = useState(false);
	const [outputModalShowing, setOutputModalShowing] = useState(false);
	const [createManualJobModalShowing, setCreateManualJobModalShowing] = useState(false);

	const [currentManualJob, setCurrentManualJob] = useState<ManualJob>();
	const [manualJobToCancel, setManualJobToCancel] = useState<ManualJob>();

	const onCopyToClipboard = useCopyToClipboard();

	const currentManualJobs = manualJobs[currentPage] ?? [];

	const onCloseCreateManualJobModal = useCallback(() => setCreateManualJobModalShowing(false), []);

	const actions = [{ content: 'Refresh', onAction: onRefetch, disabled: isLoading }];
	if (accountId && isLeadOrAdmin(user)) {
		actions.unshift({ content: 'Create job', onAction: () => setCreateManualJobModalShowing(true), disabled: isLoading });
	}

	const wasCancellingJob = usePrevious(isCancellingManualJob);

	useEffect(() => {
		if (wasCancellingJob && !isCancellingManualJob) {
			setManualJobToCancel(undefined);
		}
	}, [wasCancellingJob, isCancellingManualJob]);

	return (
		<Card title="Manual jobs" actions={actions} sectioned>
			<Stack vertical>
				<DataTable
					columnContentTypes={accountId ? COLUMN_TYPES : COLUMN_TYPES_WITH_ACCOUNTS}
					headings={accountId ? HEADINGS : HEADINGS_WITH_ACCOUNTS}
					verticalAlign="middle"
					rows={currentManualJobs.map((manualJob: ManualJob) => {
						const row = [
							FORMATTED_JOB_TYPES[manualJob.jobType],
							<DateOrDefault millis={manualJob.createdAt.getTime()} />,
							<JobStatus manualJob={manualJob} />,
							manualJob.startTime && manualJob.endTime ? (
								<Duration amount={manualJob.endTime.getTime() - manualJob.startTime.getTime()} />
							) : (
								'-'
							),
							<ButtonGroup>
								{manualJob.jobInput ? (
									<Tooltip content="View input">
										<Button
											onClick={() => {
												setCurrentManualJob(manualJob);
												setInputModalShowing(true);
											}}
											variant="plain"
											icon={IncomingIcon}
										/>
									</Tooltip>
								) : undefined}
								{IS_CANCELLABLE_JOB.includes(manualJob.jobStatus) && (
									<Tooltip content="Cancel job">
										<Button
											onClick={() => setManualJobToCancel(manualJob)}
											variant="plain"
											disabled={isCancellingManualJob}
											icon={XCircleIcon}
											tone="critical"
										/>
									</Tooltip>
								)}
								{manualJob.jobOutput ? (
									<Tooltip content="View output">
										<Button
											onClick={() => {
												setCurrentManualJob(manualJob);
												setOutputModalShowing(true);
											}}
											variant="plain"
											icon={OutgoingIcon}
										/>
									</Tooltip>
								) : undefined}
							</ButtonGroup>
						];
						if (!accountId) {
							return [<AccountLink accountId={manualJob.accountId} />, ...row];
						}
						return row;
					})}
					pagination={
						onFetchNextPage
							? {
									hasNext: hasNextPage || currentPage !== manualJobs.length - 1,
									hasPrevious: currentPage > 0,
									onPrevious: () => setCurrentPage(currentPage - 1),
									onNext: () => {
										const nextPage = currentPage + 1;
										setCurrentPage(nextPage);
										if (nextPage === manualJobs.length) {
											onFetchNextPage();
										}
									}
								}
							: undefined
					}
				/>
			</Stack>
			<Modal
				title="Manual job input"
				primaryAction={{ content: 'Close', onAction: () => setInputModalShowing(false) }}
				secondaryActions={[{ content: 'Copy input', onAction: () => onCopyToClipboard(currentManualJob?.jobInput ?? '') }]}
				open={inputModalShowing}
				onClose={() => setInputModalShowing(false)}>
				<Modal.Section>
					<pre>
						<code>{currentManualJob?.jobInput ? currentManualJob?.jobInput : 'No job input to show.'}</code>
					</pre>
				</Modal.Section>
			</Modal>
			<Modal
				title="Manual job output"
				primaryAction={{ content: 'Close', onAction: () => setOutputModalShowing(false) }}
				secondaryActions={[{ content: 'Copy output', onAction: () => onCopyToClipboard(currentManualJob?.jobOutput ?? '') }]}
				open={outputModalShowing}
				onClose={() => setOutputModalShowing(false)}>
				<Modal.Section>
					<pre>
						<code>{currentManualJob?.jobOutput ? currentManualJob?.jobOutput : 'No job output to show.'}</code>
					</pre>
				</Modal.Section>
			</Modal>
			<Modal
				title="Confirm cancel job"
				primaryAction={{
					content: 'Cancel job',
					onAction: () =>
						manualJobToCancel ? cancelManualJob({ accountId: manualJobToCancel.accountId, jobId: manualJobToCancel.id }) : undefined,
					destructive: true,
					loading: isCancellingManualJob
				}}
				secondaryActions={[{ content: 'Close', onAction: () => setManualJobToCancel(undefined) }]}
				open={Boolean(manualJobToCancel)}
				onClose={() => setManualJobToCancel(undefined)}>
				<Modal.Section>
					<p>Are you sure you want to cancel this manual job?</p>
				</Modal.Section>
			</Modal>
			{accountId && <CreateManualJobModal accountId={accountId} open={createManualJobModalShowing} onClose={onCloseCreateManualJobModal} />}
		</Card>
	);
}

type AccountLinkProps = {
	accountId: string | number;
};

function AccountLink({ accountId }: AccountLinkProps) {
	const { account } = useMarketingPlatformAccount(accountId);

	return <Link url={`/marketing-platform-accounts/${accountId}`}>{account?.name ?? 'Loading...'}</Link>;
}

type JobStatusProps = {
	manualJob: ManualJob;
};

function JobStatus({ manualJob }: JobStatusProps) {
	switch (manualJob.jobStatus) {
		case 'CANCELLED':
		case 'CANCELLING':
			return <Badge tone="attention">Cancelled</Badge>;
		case 'FAILED':
			return <Badge tone="critical">Failed</Badge>;
		case 'CREATED':
			return <Badge tone="new">Created</Badge>;
		case 'COMPLETED':
			return <Badge tone="success">Completed</Badge>;
		default:
			return <ProgressBar progress={manualJob.percentComplete} size="small" />;
	}
}

function humanFileSize(size: number) {
	var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
	return +(size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
}

type CreateManualJobModalProps = {
	accountId: string | number;
	open: boolean;
	onClose: () => void;
};

function CreateManualJobModal({ accountId, open, onClose }: CreateManualJobModalProps) {
	const { createManualJob, createdJob, reset, isLoading } = useCreateMarketingPlatformManualJob(accountId);
	const [jobType, setJobType] = useState<ManualJobType>('SUPPRESSED_EMAILS_IMPORT');
	const [file, setFile] = useState<File>();

	const handleDropZoneDrop = useCallback(
		(_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => setFile(acceptedFiles[0]),
		[]
	);

	useEffect(() => {
		if (!open) {
			setFile(undefined);
			setJobType('SUPPRESSED_EMAILS_IMPORT');
			reset();
		}
	}, [open, reset]);

	const wasCreating = usePrevious(isLoading);

	useEffect(() => {
		if (wasCreating && !isLoading && createdJob) {
			onClose();
		}
	}, [wasCreating, isLoading, createdJob, onClose]);

	const isValid = !!file;

	function onCreateJob() {
		if (!isValid) {
			return;
		}
		createManualJob({ jobType, file });
	}

	return (
		<Modal
			title="Create manual job"
			primaryAction={{ content: 'Create job', onAction: onCreateJob, disabled: !isValid, loading: isLoading }}
			secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
			open={open}
			onClose={onClose}>
			<Modal.Section>
				<FormLayout>
					<TypedSelect<ManualJobType>
						label="Job type"
						options={[
							{
								value: 'SUPPRESSED_EMAILS_IMPORT',
								label: FORMATTED_JOB_TYPES['SUPPRESSED_EMAILS_IMPORT']
							}
						]}
						value={jobType}
						onChange={setJobType}
						helpText={
							<div>
								Download the <Link onClick={JOB_TYPE_SAMPLE_FILES[jobType]}>sample input file</Link>.
							</div>
						}
					/>
					<Labelled id="" label="Input file">
						<DropZone onDrop={handleDropZoneDrop}>
							{file && (
								<CenteredBoth>
									<Stack alignment="center" spacing="extraTight" vertical>
										<div>{file.name}</div>
										<Text variant="bodySm" as="p">
											{humanFileSize(file.size)}
										</Text>
									</Stack>
								</CenteredBoth>
							)}
							{!file && <DropZone.FileUpload />}
						</DropZone>
					</Labelled>
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
}

const CenteredBoth = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
