import { Card, DisplayText, Stack, useQueryString } from '@segunosoftware/equinox';
import { Button, Grid, Layout, SkeletonBodyText } from '@shopify/polaris';
import { getAppDetails, useApps } from '../hooks/useApps';
import AdminPage from './AdminPage';

const GRID_COLUMNS = { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 };

export default function ShopView() {
	const { shop } = useQueryString();
	const myshopify = shop as string;
	const { apps, isLoading } = useApps(myshopify);

	return (
		<AdminPage title={`Apps for ${myshopify}`} backAction={{ content: 'Dashboard', url: '/' }}>
			<Layout sectioned>
				{isLoading && (
					<Grid columns={GRID_COLUMNS}>
						<Grid.Cell>
							<Card sectioned>
								<SkeletonBodyText lines={8} />
							</Card>
						</Grid.Cell>
						<Grid.Cell>
							<Card sectioned>
								<SkeletonBodyText lines={8} />
							</Card>
						</Grid.Cell>
						<Grid.Cell>
							<Card sectioned>
								<SkeletonBodyText lines={8} />
							</Card>
						</Grid.Cell>
					</Grid>
				)}
				{!isLoading && apps.length === 0 && <DisplayText size="small">No apps found.</DisplayText>}
				{!isLoading && apps.length > 0 && (
					<Grid columns={GRID_COLUMNS}>
						{apps
							.sort((a, b) => a.app.localeCompare(b.app))
							.map(app => (
								<Grid.Cell key={`${app.app}-${app.id}`}>
									<Card title={getAppDetails(app).name} sectioned>
										<Stack vertical>
											<Stack spacing="none" vertical>
												<div>
													<strong>Installed on</strong>: {app.installedAt.toLocaleDateString()}
												</div>
												<div>
													<strong>Billing status</strong>: {app.billingStatus}
												</div>
												<div>
													<strong>Billing activated</strong>:{' '}
													{app.billingActivatedAt ? app.billingActivatedAt.toLocaleDateString() : 'Never'}
												</div>
											</Stack>
											<Button url={`${getAppDetails(app).urlPrefix}/${app.id}`} fullWidth>
												View account
											</Button>
										</Stack>
									</Card>
								</Grid.Cell>
							))}
					</Grid>
				)}
			</Layout>
		</AdminPage>
	);
}
