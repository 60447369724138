export default class Storage {
	map: Map<string, string>;

	constructor() {
		this.map = new Map<string, string>();
	}

	setItem(key: string, value: string) {
		this.map.set(key, value);
	}

	getItem(key: string): string | null {
		const value = this.map.get(key);
		return value || null;
	}

	removeItem(key: string) {
		this.map.delete(key);
	}

	clear() {
		this.map.clear();
	}
}

const inMemoryStorage = new Storage();

export const getStorage = (isSession: boolean = false) => {
	try {
		const testKey = '__seguno_session_test';
		const storage = isSession ? window['sessionStorage'] : window['localStorage'];
		storage.setItem(testKey, testKey);
		storage.removeItem(testKey);
		return storage;
	} catch (e) {
		return inMemoryStorage;
	}
};
