import { createContext, useCallback, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';
import { getStorage } from './Storage';

const AccessTokenGetterContext = createContext<string | null>(null);
const AccessTokenSetterContext = createContext<((accessToken: string | null) => void) | undefined>(undefined);

export function useSetAccessToken() {
	const setAccessToken = useContext(AccessTokenSetterContext);
	return setAccessToken;
}

export function useAccessToken() {
	const accessToken = useContext(AccessTokenGetterContext);
	const setAccessToken = useSetAccessToken();
	const onLogout = useCallback(() => {
		if (setAccessToken) {
			setAccessToken(null);
		}
	}, [setAccessToken]);
	return { accessToken, onLogout };
}

export type AccessTokenProviderProps = {
	tokenKey: string;
	children: ReactNode;
};

export default function AccessTokenProvider({ tokenKey, children }: AccessTokenProviderProps) {
	const storage = useMemo(() => getStorage(false), []);
	const [accessToken, setAccessToken] = useState(storage.getItem(tokenKey));

	useEffect(() => {
		const accessToken = storage.getItem(tokenKey);
		setAccessToken(accessToken);
	}, [storage, tokenKey]);

	useEffect(() => {
		if (accessToken) {
			storage.setItem(tokenKey, accessToken);
		} else {
			storage.removeItem(tokenKey);
		}
	}, [accessToken, storage, tokenKey]);

	return (
		<AccessTokenGetterContext.Provider value={accessToken}>
			<AccessTokenSetterContext.Provider value={setAccessToken}>{children}</AccessTokenSetterContext.Provider>
		</AccessTokenGetterContext.Provider>
	);
}
