import { Stack } from '@segunosoftware/equinox';
import { SkeletonBodyText } from '@shopify/polaris';
import { useMarketingPlatformKlaviyoDetails } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { renderDate } from '../../utils/dateUtils';

export default function KlaviyoDetails({ accountId }: { accountId: string | number }) {
	const { klaviyoDetails, isKlaviyoDetailsLoading } = useMarketingPlatformKlaviyoDetails(accountId);

	return (
		<>
			{(!klaviyoDetails || isKlaviyoDetailsLoading) && <SkeletonBodyText lines={3} />}
			{klaviyoDetails && (
				<Stack spacing="none" vertical>
					<div>
						<strong>Klaviyo account site id</strong>: {klaviyoDetails.siteId}
					</div>
					<div>
						<strong>Lists</strong>: {klaviyoDetails.listIds}
					</div>
					<div>
						<strong>Start date</strong>: {renderDate(klaviyoDetails.syncStartTime)}
					</div>
					<div>
						<strong>End date</strong>: {renderDate(klaviyoDetails.syncEndTime)}
					</div>
					<div>
						<strong>Sync complete</strong>: {klaviyoDetails.isSyncComplete ? 'Yes' : 'No'}
					</div>
				</Stack>
			)}
		</>
	);
}
