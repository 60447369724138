import { Card } from '@segunosoftware/equinox';
import { Button, Form, FormLayout, Layout, TextField, TopBar } from '@shopify/polaris';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccessToken } from '../AccessTokenProvider';
import { useLogin } from '../hooks/useLogin';
import AdminPage from './AdminPage';
import AppFrame from './AppFrame';

export default function Login() {
	const { accessToken } = useAccessToken();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const location = useLocation();
	const { login, isLoading, error } = useLogin();

	const { from } = location.state || { from: { pathname: '/' } };
	if (from.pathname === location.pathname || from.pathname === '/logout') {
		from.pathname = '/';
	}

	if (accessToken) {
		return <Navigate to={from} />;
	}

	const isValid = !(isLoading || email.trim() === '' || password.trim() === '');

	function performLogin() {
		if (isValid) {
			login(email, password);
		}
	}

	return (
		<AppFrame topBar={<TopBar />}>
			<AdminPage title="Login" narrowWidth>
				<Layout sectioned>
					<Card title="Admin Login" sectioned>
						<Form onSubmit={performLogin} preventDefault>
							<FormLayout>
								<TextField
									type="email"
									label="Email"
									autoComplete="email"
									id="email"
									name="email"
									value={email}
									error={error}
									onChange={setEmail}
									autoFocus
								/>
								<TextField
									type="password"
									label="Password"
									id="password"
									name="password"
									autoComplete="current-password"
									value={password}
									onChange={setPassword}
								/>
								<Button onClick={performLogin} disabled={!isValid} loading={isLoading} size="large" variant="primary" fullWidth submit>
									Login
								</Button>
							</FormLayout>
						</Form>
					</Card>
				</Layout>
			</AdminPage>
		</AppFrame>
	);
}
