import { Card, Stack, TextStyle, TypedSelect } from '@segunosoftware/equinox';
import { Banner, Button, Form, FormLayout, Layout, Modal, SkeletonBodyText, TextField, Tooltip, type BannerTone } from '@shopify/polaris';
import { DeleteIcon, EditIcon, HideIcon, ViewIcon } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import type { Announcement, AnnouncementStatus } from '../../hooks/marketingplatform/useAnnouncements';
import { useAnnouncements } from '../../hooks/marketingplatform/useAnnouncements';
import AdminPage from '../AdminPage';

type AnnouncementData = Partial<Announcement>;

const EMPTY_ANNOUNCEMENT: AnnouncementData = {
	enabled: false,
	status: 'DEFAULT',
	title: '',
	description: ''
};

export default function MarketingPlatformAnnouncements() {
	const { announcements, isLoading, createAnnouncement, isCreating, updateAnnouncement, isUpdating } = useAnnouncements();
	const [announcement, setAnnouncement] = useState<AnnouncementData | undefined>();

	const isSaving = isCreating || isUpdating;

	useEffect(() => {
		if (!isSaving) {
			setAnnouncement(undefined);
		}
	}, [isSaving]);

	function onUpdateAnnouncement(properties: AnnouncementData) {
		setAnnouncement({
			...announcement,
			...properties
		});
	}

	function onSaveAnnouncement() {
		if (!announcement) {
			return;
		}
		if (announcement.id) {
			updateAnnouncement(announcement);
		} else {
			createAnnouncement(announcement);
		}
	}

	const currentAnnouncement = announcements.find(announcement => announcement.enabled);

	return (
		<AdminPage title="Marketing platform announcements" backAction={{ content: 'Dashboard', url: '/' }}>
			<Layout>
				<Layout.Section>
					<Card title="Active announcement" sectioned>
						{isLoading && <SkeletonBodyText />}
						{currentAnnouncement && <AnnouncementBanner announcement={currentAnnouncement} onEdit={setAnnouncement} />}
						{!currentAnnouncement && !isLoading && (
							<Stack distribution="center">
								<TextStyle variation="subdued">No active announcement.</TextStyle>
							</Stack>
						)}
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card
						title="Inactive announcements"
						actions={[{ content: 'Create announcement', onAction: () => setAnnouncement(EMPTY_ANNOUNCEMENT) }]}
						sectioned>
						{isLoading && <SkeletonBodyText />}
						{!isLoading && (
							<Stack vertical>
								{announcements
									.filter(announcement => !announcement.enabled)
									.map(announcement => (
										<AnnouncementBanner key={announcement.id} announcement={announcement} onEdit={setAnnouncement} />
									))}
							</Stack>
						)}
					</Card>
				</Layout.Section>
			</Layout>
			{announcement && (
				<Modal
					title={announcement.id ? 'Edit announcement' : 'Create announcement'}
					primaryAction={{ content: 'Save announcement', onAction: onSaveAnnouncement, loading: isSaving }}
					secondaryActions={[{ content: 'Cancel', onAction: () => setAnnouncement(undefined) }]}
					onClose={() => setAnnouncement(undefined)}
					open={Boolean(announcement)}>
					<Modal.Section>
						<Form onSubmit={onSaveAnnouncement}>
							<FormLayout>
								<TextField
									label="Title"
									autoComplete="off"
									value={announcement.title}
									onChange={title => onUpdateAnnouncement({ title })}
									autoFocus
								/>
								<TextField
									label="Description"
									autoComplete="off"
									value={announcement.description}
									onChange={description => onUpdateAnnouncement({ description })}
									multiline={3}
								/>
								<TypedSelect<AnnouncementStatus>
									label="Status"
									options={[
										{ label: 'Default', value: 'DEFAULT' },
										{ label: 'Success', value: 'SUCCESS' },
										{ label: 'Info', value: 'INFO' },
										{ label: 'Warning', value: 'WARNING' },
										{ label: 'Critical', value: 'CRITICAL' }
									]}
									value={announcement.status}
									onChange={status => onUpdateAnnouncement({ status })}
								/>
							</FormLayout>
						</Form>
					</Modal.Section>
					<Modal.Section>
						<AnnouncementBanner announcement={announcement} />
					</Modal.Section>
				</Modal>
			)}
		</AdminPage>
	);
}

type AnnouncementBannerProps = {
	announcement: AnnouncementData;
	onEdit?: (announcement: AnnouncementData) => void;
};

function AnnouncementBanner({ announcement, onEdit }: AnnouncementBannerProps) {
	const { setAnnouncementEnabled, isSettingEnablement, deleteAnnouncement, isDeleting } = useAnnouncements();
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	function onToggleEnablement() {
		if (announcement.id) {
			setAnnouncementEnabled({ id: announcement.id, enabled: !announcement.enabled });
		}
	}

	function onDelete() {
		if (announcement.id) {
			deleteAnnouncement(announcement.id);
		}
	}

	return (
		<>
			<Stack spacing="extraTight" vertical>
				{onEdit && (
					<Stack alignment="center" distribution="trailing">
						<Tooltip content={announcement.enabled ? 'Hide announcement' : 'Show announcement'}>
							<Button
								icon={announcement.enabled ? HideIcon : ViewIcon}
								onClick={onToggleEnablement}
								loading={isSettingEnablement}
								variant="plain"
							/>
						</Tooltip>
						<Tooltip content="Edit">
							<Button icon={EditIcon} onClick={() => onEdit(announcement)} variant="plain" />
						</Tooltip>
						<Tooltip content="Delete">
							<Button icon={DeleteIcon} onClick={() => setShowConfirmDelete(true)} variant="plain" />
						</Tooltip>
					</Stack>
				)}
				<Banner
					title={announcement.title}
					tone={!announcement.status || announcement.status === 'DEFAULT' ? undefined : (announcement.status.toLowerCase() as BannerTone)}>
					<p>{announcement.description}</p>
				</Banner>
			</Stack>
			{showConfirmDelete && (
				<Modal
					title="Delete announcement"
					onClose={() => setShowConfirmDelete(false)}
					open={showConfirmDelete}
					primaryAction={{
						content: 'Delete announcement',
						onAction: onDelete,
						destructive: true,
						loading: isDeleting
					}}
					secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmDelete(false) }]}>
					<Modal.Section>
						<p>Are you sure you want to delete this announcement? This action cannot be undone.</p>
					</Modal.Section>
				</Modal>
			)}
		</>
	);
}
