import { Card } from '@segunosoftware/equinox';
import { Layout, Tabs } from '@shopify/polaris';
import { useFilterParameters } from '../hooks/useFilterParameters';
import AdminPage from './AdminPage';
import RecentBannerAccounts from './banner/RecentBannerAccounts';
import RecentBulkAccounts from './bulk/RecentBulkAccounts';
import RecentCanvaAccounts from './canva/RecentCanvaAccounts';
import RecentMailchimpConnectorAccounts from './mailchimpconnector/RecentMailchimpConnectorAccounts';
import RecentMarketingPlatformAccounts from './marketingplatform/RecentMarketingPlatformAccounts';
import RecentPopupAccounts from './popups/RecentPopupAccounts';
import RecentReviewAccounts from './reviews/RecentReviewAccounts';

const RECENT_LIMIT = 10;

const TABS = [
	{ id: 'marketing-platform', content: 'Email' },
	{ id: 'popup', content: 'Popups' },
	{ id: 'reviews', content: 'Reviews' },
	{ id: 'mailchimp-connector', content: 'Mail Connect' },
	{ id: 'banner', content: 'Banners' },
	{ id: 'bulk', content: 'Bulk Discount Code Bot' },
	{ id: 'canva', content: 'Canva Connect' }
];

export default function Dashboard() {
	const { searchParameters, setSearchParameters, selectedTab, setSelectedTab } = useFilterParameters('marketing-platform');

	const selectedTabIndex = TABS.findIndex(t => t.id === selectedTab);

	return (
		<AdminPage title="Dashboard">
			<Layout sectioned>
				<Card>
					<Tabs tabs={TABS} selected={selectedTabIndex} onSelect={index => setSelectedTab(TABS[index].id)} />
					{selectedTabIndex === 0 && (
						<RecentMarketingPlatformAccounts
							searchParameters={searchParameters}
							setSearchParameters={setSearchParameters}
							limit={RECENT_LIMIT}
						/>
					)}
					{selectedTabIndex === 1 && (
						<RecentPopupAccounts searchParameters={searchParameters} setSearchParameters={setSearchParameters} limit={RECENT_LIMIT} />
					)}
					{selectedTabIndex === 2 && (
						<RecentReviewAccounts searchParameters={searchParameters} setSearchParameters={setSearchParameters} limit={RECENT_LIMIT} />
					)}
					{selectedTabIndex === 3 && (
						<RecentMailchimpConnectorAccounts
							searchParameters={searchParameters}
							setSearchParameters={setSearchParameters}
							limit={RECENT_LIMIT}
						/>
					)}
					{selectedTabIndex === 4 && (
						<RecentBannerAccounts searchParameters={searchParameters} setSearchParameters={setSearchParameters} limit={RECENT_LIMIT} />
					)}
					{selectedTabIndex === 5 && (
						<RecentBulkAccounts searchParameters={searchParameters} setSearchParameters={setSearchParameters} limit={RECENT_LIMIT} />
					)}
					{selectedTabIndex === 6 && (
						<RecentCanvaAccounts searchParameters={searchParameters} setSearchParameters={setSearchParameters} limit={RECENT_LIMIT} />
					)}
				</Card>
			</Layout>
		</AdminPage>
	);
}
