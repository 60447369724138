import { Layout } from '@shopify/polaris';
import { useAllMarketingPlatformManualJobs } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import AdminPage from '../AdminPage';
import ManualJobs from './ManualJobs';

export default function AllManualJobs() {
	const { manualJobs, isLoading, fetchNextPage, hasNextPage, refetch } = useAllMarketingPlatformManualJobs();

	return (
		<AdminPage title="Manual jobs" backAction={{ content: 'Dashboard', url: '/' }}>
			<Layout>
				<Layout.Section>
					<ManualJobs
						manualJobs={manualJobs}
						isLoading={isLoading}
						onRefetch={refetch}
						onFetchNextPage={fetchNextPage}
						hasNextPage={hasNextPage}
					/>
				</Layout.Section>
			</Layout>
		</AdminPage>
	);
}
