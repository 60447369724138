import { Stack } from '@segunosoftware/equinox';
import { Pagination } from '@shopify/polaris';

export type PagerProps = {
	onChange: (page: number) => void;
	page: number;
	limit: number;
	isLoading?: boolean;
	totalLoaded?: number;
};

export default function Pager({ onChange, page, limit, isLoading = false, totalLoaded = 0 }: PagerProps) {
	const hasPrevious = !isLoading && page > 1;
	const hasNext = !isLoading && totalLoaded >= limit;

	function incrementPage(increment: number) {
		onChange(page + increment);
	}

	return (
		<Stack alignment="center" distribution="center">
			<Pagination hasPrevious={hasPrevious} hasNext={hasNext} onPrevious={() => incrementPage(-1)} onNext={() => incrementPage(1)} />
		</Stack>
	);
}
