import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_ACCOUNT_THEME_SUPPORT } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type ThemeSupport = {
	supportsBlocks: boolean;
};

export function useMarketingPlatformThemeSupport(accountId: number) {
	const { get } = useAuthenticatedFetch() as Get<ThemeSupport>;
	const { data, isFetching } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNT_THEME_SUPPORT, accountId), () =>
		get(`/marketing-platform-accounts/${accountId}/theme-support`)
	);
	return {
		themeSupport: data,
		isLoading: isFetching
	};
}
