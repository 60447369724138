import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK } from '../query-keys';
import type { Get, Post } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
import {
	useMarketingPlatformAccount,
	useUpdateAccount,
	type BillingPlan,
	type DehydratedMarketingPlatformAccount
} from './useMarketingPlatformAccount';

export type ExportActivity = {
	eventId: number;
	isCampaign: boolean;
	name: string;
};

type CancelNewsletter = { campaignId: number };

type ExportSendStats = { limit: number };

function getActionPath(id: string | number, path: string) {
	return `/marketing-platform-accounts/${id}${path}`;
}

export function useMarketingPlatformAccountActions(id: string | number) {
	const { post, get } = useAuthenticatedFetch() as Post<any, any> & Get<any>;
	const { account } = useMarketingPlatformAccount(id);
	const queryClient = useQueryClient();
	const updateAccount = useUpdateAccount(id);

	const { mutate: setSuspended, isLoading: isSettingSuspended } = useMutation(suspended =>
		post(getActionPath(id, '/set-suspended'), {
			suspended
		}).then(updateAccount)
	);

	const { mutate: setSuspicious, isLoading: isSettingSuspicious } = useMutation(suspicious =>
		post(getActionPath(id, '/set-suspicious'), {
			suspicious
		}).then(updateAccount)
	);

	const { mutate: setTrusted, isLoading: isSettingTrusted } = useMutation(trusted =>
		post(getActionPath(id, '/set-trusted'), {
			trusted
		}).then(updateAccount)
	);

	const { mutate: setTrustedImporter, isLoading: isSettingTrustedImporter } = useMutation(trustedImporter =>
		post(getActionPath(id, '/set-trusted-importer'), {
			trustedImporter
		}).then(updateAccount)
	);

	const { mutate: setListCleaningLevel, isLoading: isSettingListCleaningLevel } = useMutation(listCleaningLevel =>
		post(getActionPath(id, '/set-list-cleaning-level'), {
			listCleaningLevel
		}).then(updateAccount)
	);

	const { mutate: setSuppressionTags, isLoading: isSettingSuppressionTags } = useMutation(suppressionTags =>
		post(getActionPath(id, '/set-suppression-tags'), {
			suppressionTags
		}).then(updateAccount)
	);

	const { mutate: refreshBilling, isLoading: isRefreshingBilling } = useMutation(() =>
		post(getActionPath(id, '/update-billing'), undefined).then(updateAccount)
	);

	const { mutate: updateTrial, isLoading: isUpdatingTrial } = useMutation(newTrialDate =>
		post(getActionPath(id, '/update-trial'), newTrialDate).then(updateAccount)
	);

	const { mutate: verifyDomain, isLoading: isVerifyingDomain } = useMutation(() =>
		post(getActionPath(id, '/verify-domain'), undefined).then(updateAccount)
	);

	const { mutate: resetWarming, isLoading: isResettingWarming } = useMutation(() =>
		post(getActionPath(id, '/reset-warming'), undefined).then(updateAccount)
	);

	const { mutate: setSendingDomain, isLoading: isSettingSendingDomain } = useMutation(domain =>
		post(getActionPath(id, '/set-sending-domain'), {
			domain
		}).then(updateAccount)
	);

	const { mutate: setComplianceHold, isLoading: isSettingComplianceHold } = useMutation(complianceHold =>
		post(getActionPath(id, '/set-compliance-hold'), {
			complianceHold
		}).then(updateAccount)
	);

	const { mutate: setCsmNote, isLoading: isSettingCsmNote } = useMutation(note =>
		post(getActionPath(id, '/set-csm-note'), {
			note
		}).then(updateAccount)
	);

	const { mutate: setResourceFeedback, isLoading: isSettingResourceFeedback } = useMutation(message =>
		post(getActionPath(id, '/set-resource-feedback'), {
			message
		}).then(() => queryClient.removeQueries(queryKey(MARKETING_PLATFORM_ACCOUNT_RESOURCE_FEEDBACK)))
	);

	const { mutate: setAllowSendingOverride, isLoading: isSettingAllowSendingOverride } = useMutation(allowSendingOverride =>
		post(getActionPath(id, '/set-allow-sending-override'), {
			allowSendingOverride
		}).then(updateAccount)
	);

	const { mutate: setAllowSendingWhileWarming, isLoading: isSettingAllowSendingWhileWarming } = useMutation(allowSendingWhileWarming =>
		post(getActionPath(id, '/set-allow-sending-while-warming'), {
			allowSendingWhileWarming
		}).then(updateAccount)
	);

	const { mutate: setIpPool, isLoading: isSettingIpPool } = useMutation(ipPoolId =>
		post(getActionPath(id, `/set-ip-pool/${ipPoolId}`), undefined).then(updateAccount)
	);

	const { mutate: setUnsubscribeText, isLoading: isSettingUnsubscribeText } = useMutation(
		({ language, unsubscribeText }: { language: string; unsubscribeText: string }) =>
			post(getActionPath(id, '/set-unsubscribe'), {
				language,
				unsubscribeText
			}).then(updateAccount)
	);

	const { mutate: setContact, isLoading: isSettingContact } = useMutation(
		({ contactName, contactEmail }: { contactName: string; contactEmail: string }) =>
			post(getActionPath(id, '/set-contact'), { contactName, contactEmail }).then(updateAccount)
	);

	const { mutate: confirmDowngrade, isLoading: isConfirmingDowngrade } = useMutation(() =>
		post(getActionPath(id, '/confirm-downgrade'), undefined).then(updateAccount)
	);

	const { mutate: cancelCharge, isLoading: isCancellingCharge } = useMutation(() =>
		post(getActionPath(id, '/cancel-charge'), undefined).then(updateAccount)
	);

	const { mutate: setScreenRecord, isLoading: isSettingScreenRecord } = useMutation(screenRecord =>
		post(getActionPath(id, `/set-screen-record/${screenRecord}`), undefined).then(updateAccount)
	);

	const { adjustBillingDetails, isAdjustingBillingDetails } = useSetBillingDetails(id);

	const { adjustStoreCredits, isAdjustingStoreCredits } = useAdjustCredits(id);

	const { mutate: exportCustomers, isLoading: isExportingCustomers } = useMutation(
		({ exportType, includeTags, excludeSuppressions }: { exportType: string; includeTags: boolean; excludeSuppressions: boolean }) =>
			get(
				getActionPath(id, `/export/${exportType}?includeTags=${includeTags}&excludeSuppressions=${excludeSuppressions}`),
				{
					download: true,
					filename: `${id}_${account?.name}_${exportType}-${format(new Date(), 'yyyy-MM-dd-HHmm')}.csv`
				},
				{
					Accept: 'text/csv'
				}
			)
	);

	const { mutate: exportEmailActivity, isLoading: isExportingEmailActivity } = useMutation(
		({ eventId, isCampaign, name }: ExportActivity) =>
			get(
				getActionPath(id, `/export/${eventId}/email-activity?isCampaign=${isCampaign}`),
				{
					download: true,
					filename: `${id}_${account?.name}_${isCampaign ? 'newsletter' : 'automation'}_${name}_EXPORT-${format(
						new Date(),
						'yyyy-MM-dd-HHmm'
					)}.csv`
				},
				{
					Accept: 'text/csv'
				}
			)
	);

	const { mutate: exportNewsletterSendStats, isLoading: isExportingNewsletterSendStats } = useMutation(({ limit }: ExportSendStats) =>
		get(
			getActionPath(id, `/stats/newsletters?limit=${limit}`),
			{
				download: true,
				filename: `${id}_${account?.name}_newsletters_EXPORT-${format(new Date(), 'yyyy-MM-dd-HHmm')}.csv`
			},
			{
				Accept: 'text/csv'
			}
		)
	);

	const {
		mutate: exportAnalysis,
		isLoading: isExportingAnalysis,
		error: analysisExportError
	} = useMutation(() =>
		get(
			getActionPath(id, `/export/analysis`),
			{
				download: true,
				filename: `${id}_${account?.name}_ANALYSIS-${format(new Date(), 'yyyy-MM-dd-HHmm')}.pdf`
			},
			{
				Accept: 'application/pdf'
			}
		)
	);

	const {
		mutate: cancelNewsletter,
		isLoading: isCancellingNewsletter,
		error: cancelNewsletterError,
		reset: resetCancelNewsletter
	} = useMutation(({ campaignId }: CancelNewsletter) => post(getActionPath(id, `/campaigns/${campaignId}/cancel`), undefined));

	const {
		mutate: removeFromSpamProtect,
		isLoading: isRemovingFromSpamProtect,
		isSuccess: removeFromSpamProtectSuccess,
		error: removeFromSpamProtectError,
		reset: resetRemoveFromSpamProtect
	} = useMutation((email: string) =>
		post(getActionPath(id, '/set-global-suppression-override'), {
			email,
			globalSuppressionOverride: true
		})
	);

	return {
		setSuspended,
		isSettingSuspended,
		setSuspicious,
		isSettingSuspicious,
		setTrusted,
		isSettingTrusted,
		setTrustedImporter,
		isSettingTrustedImporter,
		setListCleaningLevel,
		isSettingListCleaningLevel,
		setSuppressionTags,
		isSettingSuppressionTags,
		verifyDomain,
		isVerifyingDomain,
		resetWarming,
		isResettingWarming,
		setSendingDomain,
		isSettingSendingDomain,
		setComplianceHold,
		isSettingComplianceHold,
		setAllowSendingOverride,
		isSettingAllowSendingOverride,
		setAllowSendingWhileWarming,
		isSettingAllowSendingWhileWarming,
		setIpPool,
		isSettingIpPool,
		setCsmNote,
		isSettingCsmNote,
		setUnsubscribeText,
		isSettingUnsubscribeText,
		setContact,
		isSettingContact,
		confirmDowngrade,
		isConfirmingDowngrade,
		cancelCharge,
		isCancellingCharge,
		setScreenRecord,
		isSettingScreenRecord,
		adjustBillingDetails,
		isAdjustingBillingDetails,
		adjustStoreCredits,
		isAdjustingStoreCredits,
		exportCustomers,
		isExportingCustomers,
		exportEmailActivity,
		isExportingEmailActivity,
		refreshBilling,
		isRefreshingBilling,
		updateTrial,
		isUpdatingTrial,
		exportNewsletterSendStats,
		isExportingNewsletterSendStats,
		exportAnalysis,
		isExportingAnalysis,
		analysisExportError,
		setResourceFeedback,
		isSettingResourceFeedback,
		cancelNewsletter,
		isCancellingNewsletter,
		cancelNewsletterError,
		resetCancelNewsletter,
		removeFromSpamProtect,
		isRemovingFromSpamProtect,
		removeFromSpamProtectSuccess,
		removeFromSpamProtectError,
		resetRemoveFromSpamProtect
	};
}

export type AdjustCreditsRequest = {
	amount: number;
};

function useAdjustCredits(id: string | number) {
	const { post } = useAuthenticatedFetch() as Post<AdjustCreditsRequest, DehydratedMarketingPlatformAccount>;
	const updateAccount = useUpdateAccount(id);
	const { mutate: adjustStoreCredits, isLoading: isAdjustingStoreCredits } = useMutation((amount: number) =>
		post(getActionPath(id, '/adjust-store-credits'), {
			amount
		}).then(updateAccount)
	);
	return { adjustStoreCredits, isAdjustingStoreCredits };
}

export type BillingDetails = {
	billingPlan: BillingPlan;
	trialDays: number;
	freeTierCap: number;
	basePrice: number;
	includedBillingUnits: number;
	billingUnit: number;
	pricePerUnit: number;
};

export type AdjustBillingDetailsParameters = {
	billingDetails: BillingDetails;
	gracePeriodDate?: Date;
};

type AdjustBillingDetailsMutation = {
	billingDetails: BillingDetails;
	gracePeriodInMillis?: number;
};

function useSetBillingDetails(id: string | number) {
	const { post } = useAuthenticatedFetch() as Post<AdjustBillingDetailsMutation, DehydratedMarketingPlatformAccount>;
	const updateAccount = useUpdateAccount(id);
	const { mutate: adjustBillingDetails, isLoading: isAdjustingBillingDetails } = useMutation(
		({ billingDetails, gracePeriodDate }: AdjustBillingDetailsParameters) =>
			post(getActionPath(id, '/set-billing-details'), { billingDetails, gracePeriodInMillis: gracePeriodDate?.getTime() }).then(
				updateAccount
			)
	);
	return { adjustBillingDetails, isAdjustingBillingDetails };
}
