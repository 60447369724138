import { FormatNumber, FormatPercent, Stack, TextStyle } from '@segunosoftware/equinox';
import { SkeletonBodyText } from '@shopify/polaris';
import type { ReactNode } from 'react';
import styled from 'styled-components';

export type Engagements = {
	impressionCount: number;
	conversionCount: number;
};

export type PopupEngagementStatsProps = {
	engagements: Engagements;
	isLoading?: boolean;
};

type Provider = (engagements: Engagements) => {
	title: string;
	value: ReactNode;
};

export default function PopupEngagementStats({ engagements, isLoading = false }: PopupEngagementStatsProps) {
	function renderStats(provider: Provider) {
		if (!engagements || isLoading) {
			return <SkeletonBodyText />;
		}
		const providerResults = provider(engagements);
		return (
			<Stack spacing="tight" alignment="center" vertical>
				<TextStyle variation="subdued">{providerResults.title}</TextStyle>
				<StatValue>
					<TextStyle variation="strong">{providerResults.value}</TextStyle>
				</StatValue>
			</Stack>
		);
	}

	return (
		<Stack alignment="center" distribution="fillEvenly" spacing="extraTight" wrap={false}>
			{renderStats(stats => ({
				title: 'Impressions',
				value: <FormatNumber value={stats.impressionCount} />
			}))}
			{renderStats(stats => ({
				title: 'Conversions',
				value: <FormatNumber value={stats.conversionCount} />
			}))}
			{renderStats(stats => ({
				title: 'Conversion rate',
				value: <FormatPercent value={(stats.conversionCount / stats.impressionCount) * 100} decimals={1} />
			}))}
		</Stack>
	);
}

const StatValue = styled.div`
	font-size: 1rem;
`;
