import { useMutation } from '@tanstack/react-query';
import type { Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export function useEraseCustomerData() {
	const { post } = useAuthenticatedFetch() as Post<null, Response>;
	const {
		mutate: eraseCustomerData,
		data,
		isLoading: isCustomerDataErasing
	} = useMutation((email: string) =>
		post(
			`/marketing-platform-accounts/customer-data-erasure?email=${encodeURIComponent(email)}`,
			null,
			{
				download: true,
				filename: `customer-data-erasure-${email}.csv`
			},
			{
				Accept: 'text/csv'
			}
		)
	);

	return { eraseCustomerData, isCustomerDataErasing, isCustomerDataErasureSuccessful: data?.ok ?? false };
}
