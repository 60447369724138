import { Card } from '@segunosoftware/equinox';
import { Badge, InlineStack, Layout, SkeletonBodyText } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBannerAccount } from '../../hooks/banner/useBannerAccount';
import { useUser } from '../../hooks/useUser';
import AccountView, { isLeadOrAdmin } from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import { DateWithDistance } from '../DateTime';
import JobsView from '../JobsView';

export default function BannerAccountView() {
	const { accountId } = useParams();
	const { account, jobs, onLoadJobs, isJobsLoading, scriptTag } = useBannerAccount(accountId);

	const user = useUser();

	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	const billingActions = [];

	function addBillingOptions(hasPermissions, options) {
		if (hasPermissions) {
			billingActions.push(...options);
			billingActions.sort((a, b) => a.content.localeCompare(b.content));
		}
	}

	if (account) {
		addBillingOptions(isLeadOrAdmin(user), [
			{
				content: 'Issue app credit',
				onAction: () => setShowAppCreditModal(true)
			}
		]);
	}

	const badges = [];
	if (account?.trialEndsAt) {
		badges.push(
			<Badge key="trial" tone="info">
				trial ends:{' '}
				{new Date(account.trialEndsAt).toLocaleDateString('en-US', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit'
				})}
			</Badge>
		);
	}

	return (
		<AdminPage
			title={
				<InlineStack align="center">
					<div>{account ? account.name : 'Banner account'}</div>
					{badges}
				</InlineStack>
			}
			pageTitle={account?.name ?? 'Banner account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			actionGroups={[{ title: 'More actions', actions: billingActions }]}>
			<Layout sectioned>
				<AccountView account={account} app="BANNER" />
				<Card title="Script tag" sectioned>
					{!scriptTag && <SkeletonBodyText lines={3} />}
					{scriptTag && (
						<div>
							<div>
								<strong>Deployed</strong>: {scriptTag.deployed ? 'Yes' : 'No'}
							</div>
							<div>
								<strong>Banner Last Updated</strong>: <DateWithDistance date={scriptTag.bannerLastUpdated} />
							</div>
							<div>
								<strong>Discounts Last Updated</strong>: <DateWithDistance date={scriptTag.discountsLastUpdated} />
							</div>
						</div>
					)}
				</Card>
				<JobsView onLoad={onLoadJobs} jobs={jobs} isLoading={isJobsLoading} />
			</Layout>
			{account && <AppCreditModal account={account} app="BANNER" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
		</AdminPage>
	);
}
