import { ScrollToTop } from '@segunosoftware/equinox';
import '@shopify/polaris/build/esm/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import APIProvider from './APIProvider';
import AccessTokenProvider, { useAccessToken } from './AccessTokenProvider';
import App from './App';

const ACCESS_TOKEN_KEY = 'seguno_admin_access_token';

const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

function ReactQueryWrapper({ children }: { children: JSX.Element }) {
	const { onLogout } = useAccessToken();
	const onErrorOption = (error: unknown) => {
		if ((error as Response).status === 403 || (error as Response).status === 401) {
			onLogout();
		}
	};
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				retry: false,
				staleTime: Infinity,
				onError: onErrorOption
			},
			mutations: {
				onError: onErrorOption
			}
		}
	});
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<AccessTokenProvider tokenKey={ACCESS_TOKEN_KEY}>
				<ReactQueryWrapper>
					<Router>
						<ScrollToTop>
							<App />
						</ScrollToTop>
					</Router>
				</ReactQueryWrapper>
			</AccessTokenProvider>
		</APIProvider>
	</StrictMode>
);
