import useGlobalAppPurchases from '../../hooks/marketingplatform/useGlobalAppPurchases';
import AdminPage from '../AdminPage';
import MarketingPlatformGlobalAppPurchases from './MarketingPlatformGlobalAppPurchases';

export default function GlobalAppPurchases() {
	const { marketingPlatformPurchases, isMarketingPlatformPurchasesLoading } = useGlobalAppPurchases();

	return (
		<AdminPage title="Application purchases" backAction={{ content: 'Dashboard', url: '/' }}>
			<MarketingPlatformGlobalAppPurchases
				marketingPlatformPurchases={marketingPlatformPurchases}
				isLoading={isMarketingPlatformPurchasesLoading}
			/>
		</AdminPage>
	);
}
