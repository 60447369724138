import { Card, Stack, TextStyle, capitalize } from '@segunosoftware/equinox';
import { Badge, ResourceList, SkeletonBodyText } from '@shopify/polaris';
import { useState } from 'react';
import styled from 'styled-components';
import FacebookDetails from '../../components/marketingplatform/FacebookDetails';
import KlaviyoDetails from '../../components/marketingplatform/KlaviyoDetails';
import MailchimpDetails from '../../components/marketingplatform/MailchimpDetails';
import { useMarketingPlatformAccount, useMarketingPlatformConnectionList, type ConnectionDescription } from './useMarketingPlatformAccount';

export default function MarketingPlatformConnections({ accountId }: { accountId: string | number }) {
	const { account } = useMarketingPlatformAccount(accountId);
	const connections = useMarketingPlatformConnectionList(accountId);

	if (!account || !connections) {
		return (
			<Card title="Connections">
				<Card.Section>
					<SkeletonBodyText lines={5} />
				</Card.Section>
			</Card>
		);
	}

	return (
		<Card title="Connections">
			<ConnectionListPadding />
			<ResourceList
				resourceName={{ singular: 'connection', plural: 'connections' }}
				items={connections}
				renderItem={connection => <ConnectionItem accountId={accountId} connection={connection} />}></ResourceList>
		</Card>
	);
}

function ConnectionItem({
	accountId,
	connection: { id, name, connected, hasDetails }
}: {
	accountId: string | number;
	connection: ConnectionDescription;
}) {
	const { account } = useMarketingPlatformAccount(accountId);
	const [showDetails, setShowDetails] = useState(false);
	const reviewService = capitalize(account?.shopifyReviewServiceType.toLowerCase() ?? '');

	const action = hasDetails
		? [{ content: `${showDetails ? 'Hide' : 'View'} details`, onAction: () => setShowDetails(!showDetails) }]
		: undefined;

	function getStatusBadge(connected: boolean) {
		return connected ? <Badge tone="success">connected</Badge> : <Badge tone="new">disabled</Badge>;
	}

	return (
		<>
			<Card.Section
				title={
					<Stack alignment="center" spacing="tight">
						<div>
							<strong>{name}</strong>: {id === 'REVIEW_SERVICE' ? <TextStyle>{reviewService}</TextStyle> : getStatusBadge(connected)}
						</div>
					</Stack>
				}
				key={id}
				actions={action}>
				{id === 'FACEBOOK' && showDetails && <FacebookDetails accountId={accountId} />}
				{id === 'MAILCHIMP' && showDetails && <MailchimpDetails accountId={accountId} />}
				{id === 'KLAVIYO' && showDetails && <KlaviyoDetails accountId={accountId} />}
			</Card.Section>
			{id !== 'REVIEW_SERVICE' && <GapCloser />}
		</>
	);
}

const ConnectionListPadding = styled.div`
	margin-bottom: 0.5rem;
`;

const GapCloser = styled.div`
	margin-bottom: -0.5rem;
`;
