import { isNotBlank, usePrevious } from '@segunosoftware/equinox';
import { Banner, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useToast } from '../ToastProvider';
import { useAppCredit } from '../hooks/useAppCredit';
import type { App } from '../hooks/useApps';
import { getAppDetails, useApps } from '../hooks/useApps';

export type AppCreditModalProps = {
	account: {
		id: number;
		shop: string;
	};
	app: App;
	onClose: () => void;
	open?: boolean;
};

export default function AppCreditModal({ account, app, onClose, open = false }: AppCreditModalProps) {
	const showToast = useToast();
	const { apps } = useApps(account?.shop);
	const installedApp = apps.find(a => a.app === app);
	const { issueAppCredit, isLoading, successful, reset } = useAppCredit(
		installedApp ? getAppDetails(installedApp).urlPrefix : undefined,
		account?.id
	);

	const [amount, setAmount] = useState(0);
	const [description, setDescription] = useState('');
	const [failedToCredit, setFailedToCredit] = useState(false);

	useEffect(() => {
		if (open) {
			setAmount(0);
			setDescription('');
			setFailedToCredit(false);
			reset();
		}
	}, [open, reset]);

	useEffect(() => {
		if (successful) {
			showToast('Application credit applied');
			onClose();
			reset();
		}
	}, [successful, reset, onClose, showToast]);

	const previouslyLoading = usePrevious(isLoading);

	useEffect(() => {
		if (previouslyLoading && !successful) {
			setFailedToCredit(true);
		}
	}, [previouslyLoading, successful]);

	const isValid = amount > 0 && isNotBlank(description);

	function onPerformCredit() {
		if (isValid) {
			setFailedToCredit(false);
			issueAppCredit({ amount, description });
		}
	}

	return (
		<Modal
			title="Issue app credit"
			primaryAction={{ content: 'Issue credit', onAction: onPerformCredit, loading: isLoading, disabled: !isValid }}
			secondaryActions={[{ content: 'Cancel', onAction: onClose, disabled: isLoading }]}
			onClose={onClose}
			open={open}>
			<Modal.Section>
				<Form onSubmit={onPerformCredit}>
					<FormLayout>
						{failedToCredit && (
							<Banner tone="critical">
								<p>
									We were unable to credit that amount to the account. Verify the account was charged this month and all applied credits do
									not exceed that amount.
								</p>
							</Banner>
						)}
						<TextField
							label="Amount"
							autoComplete="off"
							type="number"
							prefix="$"
							suffix="USD"
							min={0}
							value={amount.toString()}
							onChange={amount => setAmount(Number(amount))}
							autoFocus
						/>
						<TextField label="Description" autoComplete="off" value={description} onChange={setDescription} />
					</FormLayout>
				</Form>
			</Modal.Section>
		</Modal>
	);
}
