import type { FormatMoneyProps } from '@segunosoftware/equinox';
import { FormatMoney, Stack } from '@segunosoftware/equinox';
import { Button } from '@shopify/polaris';
import { CurrencyConvertIcon } from '@shopify/polaris-icons';
import { CurrencyCode } from '@shopify/react-i18n';

const DEFAULT_CURRENCY = CurrencyCode.Usd;

export default function ConversionFormatMoney({ code = DEFAULT_CURRENCY, amount, ...rest }: FormatMoneyProps) {
	return (
		<Stack spacing="tight" distribution="trailing" wrap={false}>
			<FormatMoney {...rest} amount={amount} code={code} />
			{code !== DEFAULT_CURRENCY && (
				<Button
					icon={CurrencyConvertIcon}
					url={`https://www.google.com/search?q=${amount}+${code}+to+usd`}
					target="_blank"
					variant="plain"
				/>
			)}
		</Stack>
	);
}
