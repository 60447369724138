import { Stack, TextStyle } from '@segunosoftware/equinox';
import { Badge } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useRecentMailchimpConnectorAccounts } from '../../hooks/mailchimpconnector/useRecentMailchimpConnectorAccounts';
import RecentAccounts, { BILLING_STATUS_FILTER } from '../RecentAccounts';

const MAILCHIMP_CONNECTOR_FILTERS = [BILLING_STATUS_FILTER];

RecentBannerAccounts.propTypes = {
	searchParameters: PropTypes.shape({
		appliedFilters: PropTypes.object.isRequired,
		searchText: PropTypes.string.isRequired,
		recentPage: PropTypes.number.isRequired
	}).isRequired,
	setSearchParameters: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
};

export default function RecentBannerAccounts({ searchParameters, setSearchParameters, limit }) {
	const { accounts, count, load, isLoading } = useRecentMailchimpConnectorAccounts(
		searchParameters.appliedFilters,
		searchParameters.searchText,
		searchParameters.recentPage,
		limit
	);

	return (
		<RecentAccounts
			accountType="mail connect"
			recentLimit={limit}
			filters={MAILCHIMP_CONNECTOR_FILTERS}
			onSearchParametersChange={setSearchParameters}
			searchParameters={searchParameters}
			onLoadRecentAccounts={load}
			getAccountViewUrl={id => `/mailchimp-connector-accounts/${id}`}
			recentAccounts={accounts}
			isRecentAccountsLoading={isLoading}
			recentAccountsCount={count}>
			{item => (
				<Stack spacing="tight" vertical>
					<Stack alignment="center">
						<TextStyle variation="strong">
							{item.name} ({item.shop})
						</TextStyle>
						<div>{item.platformPlan}</div>
						<div>{item.billingStatus}</div>
					</Stack>
					<Stack spacing="tight" alignment="center">
						<Badge
							size="small"
							tone={
								item.syncStatus.initialSyncComplete
									? 'success'
									: item.mailChimpConnected && item.syncStatus.initialSyncStarted
										? 'warning'
										: 'new'
							}>
							sync status:{' '}
							{item.syncStatus.initialSyncComplete
								? 'completed'
								: item.mailChimpConnected && item.syncStatus.initialSyncStarted
									? 'running'
									: 'not started'}
						</Badge>
						{!item.trialCompleted && item.trialEndsAt && (
							<Badge size="small" tone="info">
								trial ends:{' '}
								{new Date(item.trialEndsAt).toLocaleDateString('en-US', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								})}
							</Badge>
						)}
					</Stack>
				</Stack>
			)}
		</RecentAccounts>
	);
}
