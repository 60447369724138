import { Card, FormatMoney, FormatNumber, LocalDate, Stack, TextStyle, usePrevious } from '@segunosoftware/equinox';
import { Button, Modal, ProgressBar, SkeletonBodyText } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { usePopupAccount } from '../../hooks/popups/usePopupAccount';
import { usePopupAccountActions } from '../../hooks/popups/usePopupAccountActions';
import { usePopupAccountDetails } from '../../hooks/popups/usePopupAccountDetails';
import { usePopupBillingUsageSummary } from '../../hooks/popups/usePopupBillingUsageSummary';
import { usePopupEngagements } from '../../hooks/popups/usePopupEngagements';
import PopupEngagementStats from './PopupEngagementStats';
import { renderDate } from '../../utils/dateUtils';

const CRITICAL_THRESHOLD = 85;

PopupBillingAndUsageSummary.propTypes = {
	account: PropTypes.shape({
		id: PropTypes.number.isRequired,
		createdAt: PropTypes.number.isRequired,
		trialEndsAt: PropTypes.number,
		trialCompleted: PropTypes.bool
	}).isRequired
};

export default function PopupBillingAndUsageSummary({ account }) {
	const { details, onLoadDetails } = usePopupAccountDetails(account.id);
	const { summary, isLoading: isUsageLoading } = usePopupBillingUsageSummary(account.id);
	const { engagements, isLoading: isEngagementsLoading } = usePopupEngagements(
		account.id,
		LocalDate.from(account?.createdAt ?? LocalDate.now()),
		LocalDate.now(),
		'all',
		[]
	);
	const { cancelCharge, isCancellingCharge } = usePopupAccountActions(account.id);
	const { billingDate } = usePopupAccount(account.id);

	const [showCancelChargeModal, setShowCancelChargeModal] = useState(false);

	const previousIsCancellingCharge = usePrevious(isCancellingCharge);

	useEffect(() => {
		if (!isCancellingCharge && previousIsCancellingCharge) {
			setShowCancelChargeModal(false);
			onLoadDetails();
		}
	}, [onLoadDetails, isCancellingCharge, previousIsCancellingCharge]);

	const isLoadingSummary = !summary;
	const progress = isLoadingSummary ? 0 : Math.min((summary.usage / summary.allowance) * 100.0, 100);
	const isCritical = isLoadingSummary ? false : !summary.unlimitedAllowance && progress > CRITICAL_THRESHOLD;
	const trialEndsAtDate = account.trialEndsAt ? new Date(account.trialEndsAt) : null;
	const isTrialRunning = !account.trialCompleted && trialEndsAtDate && trialEndsAtDate.getTime() >= new Date().getTime();

	return (
		<Card title="Billing and usage">
			<Card.Section>
				<div>
					<strong>Trial days</strong>: <FormatNumber value={account.trialDays} />
				</div>
				<div>
					<strong>Base price</strong>: <FormatMoney amount={account.basePrice} decimals={0} />
				</div>
				<div>
					<strong>Free with Seguno</strong>: {account.freeWithSeguno ? 'Yes' : 'No'}
				</div>
				<div>
					<strong>Upgraded Early:</strong> {account.upgradedEarly ? 'Yes' : 'No'}
				</div>
				{account.upgradedEarly && account.upgradedReason && (
					<div>
						<strong>Upgrade reason:</strong> {account.upgradedReason}
					</div>
				)}
				<div>
					<strong>Active monthly charge</strong>: <FormatMoney amount={details?.activeChargeAmount} />{' '}
					{details?.activeChargeAmount > 0 && (
						<Button onClick={() => setShowCancelChargeModal(true)} variant="plain" tone="critical">
							(cancel charge)
						</Button>
					)}
				</div>
				{billingDate && (
					<div>
						<strong>Next billing date</strong>: {renderDate(billingDate)}
					</div>
				)}
				<div>
					<strong>Capped amount</strong>: <FormatMoney amount={account.cappedAmount} decimals={0} />
				</div>
				<div>
					<strong>Additional impression size</strong>: <FormatNumber value={account.unitAmount} />
				</div>
				<div>
					<strong>Included impressions (free)</strong>: <FormatNumber value={account.freeIncludedUnits} />
				</div>
				<div>
					<strong>Included impressions (paid)</strong>: <FormatNumber value={account.includedUnits} />
				</div>
			</Card.Section>
			<Card.Section title="Usage">
				{isUsageLoading && <SkeletonBodyText />}
				{!isUsageLoading && (
					<Stack spacing="extraTight" vertical>
						{!isTrialRunning && (
							<ProgressBar progress={summary.unlimitedAllowance ? 0 : progress} size="small" tone={isCritical ? 'critical' : 'highlight'} />
						)}
						<TextStyle variation="subdued">
							<FormatNumber value={summary.usage} />
							{!isTrialRunning && (
								<span> of {summary.unlimitedAllowance ? 'Unlimited' : <FormatNumber value={summary.allowance} />}</span>
							)}{' '}
							impressions
						</TextStyle>
					</Stack>
				)}
			</Card.Section>
			<Card.Section title="Overall engagement">
				<PopupEngagementStats engagements={engagements?.all} isLoading={isEngagementsLoading} />
			</Card.Section>
			<Modal
				title="Confirm charge cancellation"
				primaryAction={{
					content: 'Confirm',
					onAction: cancelCharge,
					destructive: true,
					loading: isCancellingCharge
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowCancelChargeModal(false) }]}
				onClose={() => setShowCancelChargeModal(false)}
				open={showCancelChargeModal}>
				<Modal.Section>
					<p>
						This will cancel the charge with Shopify. If the merchant is in the free trial, the charge will be cancelled. If not in the free
						trial, the charge will be cancelled and the account may become frozen if they are over their impression limit.
					</p>
				</Modal.Section>
			</Modal>
		</Card>
	);
}
