import { Stack } from '@segunosoftware/equinox';
import { Button, ChoiceList } from '@shopify/polaris';

const DEFAULT_OPTIONS = {
	shortcut: false
};

function getValueAsArray(value) {
	return Array.isArray(value) ? value : [value];
}

export function createBasicFilter(key, label, options = {}) {
	return {
		key,
		label,
		filterParameters: [key],
		...{ ...DEFAULT_OPTIONS, ...options }
	};
}

export function createMultipleChoiceListFilter(key, label, choices, options = {}) {
	return createBasicFilter(key, label, {
		appliedLabel: appliedFilters => appliedFilters[key]?.length > 0 && `${label} is ${getValueAsArray(appliedFilters[key]).join(' or ')}`,
		filter: (appliedFilters, onChange) => (
			<Stack vertical>
				<ChoiceList
					title={label}
					choices={choices}
					selected={appliedFilters[key] ?? []}
					onChange={value => onChange({ [key]: value })}
					titleHidden
					allowMultiple
				/>
				<Button
					onClick={() => onChange({ [key]: choices.map(c => c.value) })}
					disabled={getValueAsArray(appliedFilters[key]).length === choices.length}
					variant="plain">
					Select all
				</Button>
			</Stack>
		),
		...options
	});
}

export function createSingleChoiceFilter(key, label, choices, options = {}) {
	return createBasicFilter(key, label, {
		...options,
		appliedLabel: appliedFilters => appliedFilters[key] && `${label} is ${appliedFilters[key]}`,
		filter: (appliedFilters, onChange) => (
			<ChoiceList
				title={label}
				choices={choices.map(o => ({
					label: o,
					value: o
				}))}
				selected={appliedFilters[key] ? [appliedFilters[key]] : []}
				onChange={value => onChange({ [key]: value.length > 0 ? value[0] : undefined })}
				titleHidden
			/>
		)
	});
}
