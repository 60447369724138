import styled from 'styled-components';

const Sus = styled.img.attrs(props => ({
	src: '/images/sus.png'
}))`
	display: inline-block;
	width: 12px;
	height: 12px;
	position: relative;
	top: 1px;
`;

export default Sus;
