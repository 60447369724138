import { Card, Heading, Stack, TextStyle } from '@segunosoftware/equinox';
import { Button, DataTable, Pagination, Tooltip } from '@shopify/polaris';
import { format } from 'date-fns';
import { useState } from 'react';
import { useDraftNewsletters } from '../../hooks/marketingplatform/useNewsletters';
import SlimPolarisTableButtons from '../SlimPolarisTableButtons';
import { truncateText } from './Automations';
import TargetRecipientsDescription from './TargetRecipientsDescription';

export type DraftNewslettersProps = {
	accountId: number;
};

export default function DraftNewsletters({ accountId }: DraftNewslettersProps) {
	const { draftNewsletters, refetch, isLoading, hasNextPage, fetchNextPage } = useDraftNewsletters(accountId);
	const [currentPage, setCurrentPage] = useState(0);
	const currentNewsletters = draftNewsletters[currentPage] ?? [];

	return (
		<Card
			title={
				<Stack alignment="center">
					<Stack.Item fill>
						<Heading>Draft newsletters</Heading>
					</Stack.Item>
					<Button onClick={refetch} loading={isLoading} variant="plain">
						Refresh
					</Button>
					<Pagination
						hasPrevious={currentPage > 0}
						onPrevious={() => setCurrentPage(currentPage - 1)}
						hasNext={hasNextPage || currentPage !== draftNewsletters.length - 1}
						onNext={() => {
							const nextPage = currentPage + 1;
							setCurrentPage(nextPage);
							if (nextPage === draftNewsletters.length) {
								fetchNextPage();
							}
						}}
					/>
				</Stack>
			}
			sectioned>
			<SlimPolarisTableButtons>
				<DataTable
					verticalAlign="middle"
					columnContentTypes={['text', 'text', 'text', 'text', 'text']}
					headings={['Created at', 'Last updated at', 'Name', 'Status', 'Recipients', 'Discount']}
					rows={currentNewsletters.map(newsletter => {
						const { name, campaignStatus, createdAt, updatedAt, discountCodeType, discountCode, targetRecipients } = newsletter;
						return [
							<Stack spacing="extraTight">
								{createdAt && (
									<Tooltip content={format(createdAt, 'eeee, MMMM do yyyy, h:mm:ss a')}>
										<TextStyle>{format(createdAt, "yyyy-MM-dd 'at' h:mm a")}</TextStyle>
									</Tooltip>
								)}
							</Stack>,
							<Stack spacing="extraTight">
								{updatedAt && (
									<Tooltip content={format(updatedAt, 'eeee, MMMM do yyyy, h:mm:ss a')}>
										<TextStyle>{format(updatedAt, "yyyy-MM-dd 'at' h:mm a")}</TextStyle>
									</Tooltip>
								)}
							</Stack>,
							<Tooltip content={name}>
								{targetRecipients === 'INHERITED_NON_OPENS' ? (
									<TextStyle variation="strong">{truncateText(name)}</TextStyle>
								) : (
									truncateText(name)
								)}
							</Tooltip>,
							campaignStatus,
							<TargetRecipientsDescription accountId={accountId} newsletter={newsletter} />,
							discountCode ? (
								<Tooltip content={discountCode}>
									<p>{discountCodeType}</p>
								</Tooltip>
							) : (
								<p>{discountCodeType}</p>
							)
						];
					})}
					hideScrollIndicator
					truncate
					fixedFirstColumns={2}
				/>
			</SlimPolarisTableButtons>
		</Card>
	);
}
