import { TextStyle } from '@segunosoftware/equinox';

export const renderDate = (dateProp: number | Date | string) => {
	const date = new Date(dateProp);
	return (
		<span>
			{date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}
			<TextStyle variation="subdued"> at {date.toLocaleTimeString('en-US', { timeStyle: 'long' })}</TextStyle>
		</span>
	);
};

export const renderDateShort = (dateProp: number | Date | string) => {
	const date = new Date(dateProp);
	return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export const renderTime = (dateProp: number | Date | string) => {
	const date = new Date(dateProp);
	return date.toLocaleTimeString('en-US', { timeStyle: 'long' });
};
