import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_ACCOUNTS_IP_POOLS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useIpPools() {
	const { get } = useAuthenticatedFetch();
	const { data: ipPools, isFetching: isIpPoolsLoading } = useQuery(queryKey(MARKETING_PLATFORM_ACCOUNTS_IP_POOLS), () =>
		get('/marketing-platform-accounts/ip-pools')
	);
	return { ipPools, isIpPoolsLoading };
}
