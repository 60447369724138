import { Heading } from '@segunosoftware/equinox';
import { DescriptionList, Modal } from '@shopify/polaris';
import { useRecentNewsletterCustomerGroups, type Segment } from '../../hooks/marketingplatform/useNewsletters';

interface CustomerGroupsModalProps {
	open: boolean;
	onClose: () => void;
	accountId: number;
	newsletter?: { id: number; name: string };
}

export function CustomerGroupsModal({ open, onClose, accountId, newsletter }: CustomerGroupsModalProps) {
	const { customerGroups, isLoadingCustomerGroups } = useRecentNewsletterCustomerGroups(
		Number(accountId),
		newsletter ? Number(newsletter.id) : undefined
	);

	function getSelectedCustomerGroupList() {
		return customerGroups.map(({ name, query, exclusion }: Segment) => ({ term: name, description: query, exclusion }));
	}

	return (
		<Modal
			title={`Customer groups - ${newsletter?.name}`}
			open={open}
			onClose={onClose}
			loading={isLoadingCustomerGroups}
			secondaryActions={[{ content: 'Close', onAction: onClose }]}>
			<Modal.Section>
				<Heading>Inclusions</Heading>
				<DescriptionList gap="tight" items={getSelectedCustomerGroupList().filter(({ exclusion }) => !exclusion)} />
			</Modal.Section>
			<Modal.Section>
				<Heading>Exclusions</Heading>
				<DescriptionList gap="tight" items={getSelectedCustomerGroupList().filter(({ exclusion }) => exclusion)} />
			</Modal.Section>
		</Modal>
	);
}
