import { Card, Subheading } from '@segunosoftware/equinox';
import { Badge, SkeletonBodyText } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { DateWithDistance, Duration } from './DateTime';

const BASE_DATA_ATTRS = [
	'lastExecutionStartTime',
	'lastExecutionEndTime',
	'lastSuccessfulExecutionStartTime',
	'lastSuccessfulExecutionEndTime',
	'lastExecutionSuccessful',
	'lastExecutionErrorMessage'
];

JobsView.propTypes = {
	onLoad: PropTypes.func.isRequired,
	jobs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			data: PropTypes.shape({
				lastExecutionStartTime: PropTypes.number,
				lastExecutionEndTime: PropTypes.number,
				lastSuccessfulExecutionStartTime: PropTypes.number,
				lastSuccessfulExecutionEndTime: PropTypes.number,
				lastExecutionSuccessful: PropTypes.bool,
				lastExecutionErrorMessage: PropTypes.string
			}),
			schedule: PropTypes.shape({
				scheduleState: PropTypes.string.isRequired,
				previousFireTime: PropTypes.number,
				nextFireTime: PropTypes.number
			})
		})
	),
	isLoading: PropTypes.bool
};

export default function JobsView({ jobs = [], isLoading = false, onLoad }) {
	function getJobStatus(job) {
		const state = job.schedule.scheduleState;
		switch (state) {
			case 'NONE':
				return 'default';
			case 'PAUSED':
				return 'attention';
			case 'ERROR':
			case 'BLOCKED':
				return 'warning';
			default:
				return 'success';
		}
	}

	function normalizeValue(value) {
		switch (typeof value) {
			case 'boolean':
				return value ? 'true' : 'false';
			case 'string':
				if (value && value.startsWith('{') && value.endsWith('}')) {
					return (
						<pre>
							<code>{JSON.stringify(JSON.parse(value), undefined, 2)}</code>
						</pre>
					);
				}
				return value;
			default:
				return value;
		}
	}

	return (
		<Card title="Jobs" actions={[{ content: 'Refresh', onAction: onLoad, disabled: isLoading, loading: isLoading }]}>
			{jobs.length === 0 && (
				<Card.Section>
					<SkeletonBodyText />
				</Card.Section>
			)}
			{jobs.map(job => {
				const remainingDataKeys = Object.keys(job.data)
					.filter(key => !BASE_DATA_ATTRS.includes(key))
					.sort((a, b) => a.localeCompare(b));
				return (
					<Card.Section key={job.name} title={job.name}>
						<div>
							<strong>Status</strong>: <Badge tone={getJobStatus(job)}>{job.schedule.scheduleState.toLowerCase()}</Badge>
						</div>
						<div>
							<strong>Next Run</strong>: <DateWithDistance date={job.schedule.nextFireTime} />
						</div>
						<div>
							<strong>Last Start</strong>: <DateWithDistance date={job.data.lastExecutionStartTime} />
						</div>
						<div>
							<strong>Last End</strong>: <DateWithDistance date={job.data.lastExecutionEndTime} />
						</div>
						<div>
							<strong>Last Duration</strong>:{' '}
							{job.data.lastExecutionEndTime ? <Duration amount={job.data.lastExecutionEndTime - job.data.lastExecutionStartTime} /> : '--'}
						</div>
						<div>
							<strong>Last Result</strong>:{' '}
							{job.data.lastExecutionEndTime ? (job.data.lastExecutionSuccessful ? 'Successful' : 'Failed') : '--'}
						</div>
						{job.data.lastExecutionErrorMessage && (
							<div>
								<strong>Last Error</strong>: {job.data.lastExecutionErrorMessage}
							</div>
						)}
						{job.data.lastSuccessfulExecutionStartTime && job.data.lastSuccessfulExecutionStartTime !== job.data.lastExecutionStartTime && (
							<div>
								<div>
									<strong>Last Successful Start</strong>: <DateWithDistance date={job.data.lastSuccessfulExecutionStartTime} />
								</div>
								<div>
									<strong>Last Successful End</strong>: <DateWithDistance date={job.data.lastSuccessfulExecutionEndTime} />
								</div>
								<div>
									<strong>Last Successful Duration</strong>:{' '}
									{job.data.lastSuccessfulExecutionEndTime ? (
										<Duration amount={job.data.lastSuccessfulExecutionEndTime - job.data.lastSuccessfulExecutionStartTime} />
									) : (
										'--'
									)}
								</div>
							</div>
						)}
						{remainingDataKeys.length > 0 && (
							<div style={styles.additionalDataHeader}>
								<Subheading>Job Data</Subheading>
							</div>
						)}
						{remainingDataKeys.map(key => (
							<div key={key}>
								<strong>{key}</strong>: {normalizeValue(job.data[key])}
							</div>
						))}
					</Card.Section>
				);
			})}
		</Card>
	);
}

const styles = {
	additionalDataHeader: {
		margin: '10px 0'
	}
};
