import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import type { BaseAccount, Job } from '../../lib/shared-types';
import { CANVA_ACCOUNT, CANVA_ACCOUNT_JOBS, CANVA_ACCOUNT_USERS, RECENT_CANVA_ACCOUNTS } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';
import { useRecentAccounts } from '../useRecentAccounts';

export type CanvaAuditExport = {
	canvaUserId: string;
	gid: string;
	mediaType: string;
	url: string;
	createdAt: number;
	updatedAt: number;
};

export type CanvaUser = {
	userId: string;
	loginAt: number;
	createdAt: number;
	exports: CanvaAuditExport[];
};

export type CanvaAccount = BaseAccount & {
	customerEmail: string;
	syncToken: string;
};

const ACCOUNT_PATH = '/canva-accounts';

export function useCanvaAccount(id: string = '') {
	const { get } = useAuthenticatedFetch() as Get<CanvaAccount>;

	const { data: account } = useQuery(queryKey(CANVA_ACCOUNT, id), () => get(`${ACCOUNT_PATH}/${id}`));

	return { account };
}

export function useCanvaAccountUsers(id: string = '') {
	const { get } = useAuthenticatedFetch() as Get<CanvaUser[]>;

	const { data, isFetching: isCanvaUsersLoading } = useQuery(queryKey(CANVA_ACCOUNT_USERS, id), () => get(`${ACCOUNT_PATH}/${id}/users`));

	return { users: data ?? [], isCanvaUsersLoading };
}

export function useCanvaAccountJobs(id: string = '') {
	const { get } = useAuthenticatedFetch() as Get<Job[]>;

	const {
		data,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery(queryKey(CANVA_ACCOUNT_JOBS, id), () => get(`${ACCOUNT_PATH}/${id}/jobs`));

	return { jobs: data ?? [], onLoadJobs, isJobsLoading };
}

export function useRecentCanvaAccounts(filters: any, search?: string, page?: number, limit?: number) {
	return useRecentAccounts(RECENT_CANVA_ACCOUNTS, ACCOUNT_PATH, filters, search, page, limit);
}
