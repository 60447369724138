import { formatDistance, formatDistanceToNow } from 'date-fns';

export type DateOrDefaultProps = {
	millis: number;
};

export const DateOrDefault = ({ millis }: DateOrDefaultProps) => <span>{millis ? new Date(millis).toLocaleString() : '--'}</span>;

export type DurationProps = {
	amount: number;
};

export const Duration = ({ amount }: DurationProps) => <span>{formatDistance(0, amount, { includeSeconds: true })}</span>;

export type DateWithDistanceProps = {
	date: number;
};

export const DateWithDistance = ({ date }: DateWithDistanceProps) => (
	<span>
		<DateOrDefault millis={date} /> {date && `(${formatDistanceToNow(new Date(date), { addSuffix: true })})`}
	</span>
);
