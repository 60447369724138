import styled from 'styled-components';

const PausedIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Zm2,6a1,1,0,0,0-1,1h0v5.65a1,1,0,0,0,2,0h0V7A1,1,0,0,0,12,6ZM8,6A1,1,0,0,0,7,7H7v5.65a1,1,0,0,0,2,0H9V7A1,1,0,0,0,8,6Z"
			fill="#b98a01"
		/>
	</svg>
);

const ScheduledIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Zm0,2a8,8,0,1,0,5.66,2.34A8,8,0,0,0,10,2Zm0,2a6,6,0,1,1-6,6A6,6,0,0,1,10,4Zm0,2A1,1,0,0,0,9,7H9v3a1,1,0,0,0,.29.71h0l2,2a1,1,0,1,0,1.42-1.42h0L11,9.59V7A1,1,0,0,0,10,6Z"
			fill="#03a0ac"
		/>
	</svg>
);

const DisabledIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M2,10A7.92,7.92,0,0,1,3.69,5.1L14.9,16.31A8,8,0,0,1,2,10m14.31,4.9L5.1,3.69A7.92,7.92,0,0,1,10,2a8,8,0,0,1,6.31,12.9M0,10A10,10,0,1,0,10,0,10,10,0,0,0,0,10"
			fill="#637382"
		/>
	</svg>
);

const SuccessIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Zm3.8,6.8L9,11.6,6.7,9.3a1,1,0,0,0-1.4,1.4l3,3a1,1,0,0,0,1.4,0l5.5-5.5a1,1,0,0,0-1.4-1.4Z"
			fill="#008060"
		/>
	</svg>
);

const ClosedIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Zm4,9H6a1,1,0,0,0-1,1H5v.12A1,1,0,0,0,6,11h8a1,1,0,0,0,1-1h0V9.88A1,1,0,0,0,14,9Z"
			fill="#03a0ac"
		/>
	</svg>
);

export type StatusIconProps = {
	status: 'successful' | 'scheduled' | 'paused' | 'disabled' | 'closed';
};

export default function StatusIcon({ status }: StatusIconProps) {
	switch (status) {
		case 'paused':
			return (
				<IconContainer>
					<PausedIcon />
				</IconContainer>
			);
		case 'scheduled':
			return (
				<IconContainer>
					<ScheduledIcon />
				</IconContainer>
			);
		case 'closed':
			return (
				<IconContainer>
					<ClosedIcon />
				</IconContainer>
			);
		case 'successful':
			return (
				<IconContainer>
					<SuccessIcon />
				</IconContainer>
			);
		case 'disabled':
			return (
				<IconContainer>
					<DisabledIcon />
				</IconContainer>
			);
		default:
			return null;
	}
}

const IconContainer = styled.div`
	width: 20px;
	height: 20px;
`;
