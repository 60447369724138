import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { POPUP_ACCOUNT_DETAILS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function usePopupAccountDetails(id) {
	const { get } = useAuthenticatedFetch();
	const { data: details, refetch: onLoadDetails } = useQuery(queryKey(POPUP_ACCOUNT_DETAILS, id), () =>
		get(`/popup-accounts/${id}/details`)
	);
	return { details, onLoadDetails };
}
