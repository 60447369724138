import { FormatNumber, Stack, TextStyle } from '@segunosoftware/equinox';
import { Badge, FormLayout, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useIpPools } from '../../hooks/marketingplatform/useIpPools';
import { useRecentMarketingPlatformAccounts } from '../../hooks/marketingplatform/useRecentMarketingPlatformAccounts';
import { createBasicFilter, createMultipleChoiceListFilter, createSingleChoiceFilter } from '../../lib/filter-creator';
import RecentAccounts, { BILLING_STATUS_FILTER, INSTALL_DATE_FILTER, SHOP_PLAN_FILTER, basicFilterChoiceMapper } from '../RecentAccounts';
import Sus from '../Sus';

function getSelectedIpPoolNames(ipPools, appliedFilters) {
	const filteredIpPools = appliedFilters['ipPools'];
	return ipPools.filter(p => filteredIpPools.includes(p.id)).map(p => p.name);
}

function usePlatformFilters() {
	const { ipPools = [], isIpPoolsLoading } = useIpPools();

	return [
		BILLING_STATUS_FILTER,
		createMultipleChoiceListFilter(
			'complianceHold',
			'Compliance hold',
			basicFilterChoiceMapper([
				'NONE',
				'UNTRUSTED_CONTENT',
				'AWAITING_SURVEY',
				'PENDING_REVIEW',
				'AWAITING_CUSTOMER',
				'SUBSCRIBER_IMPORT',
				'UNUSUAL_ACTIVITY'
			])
		),
		SHOP_PLAN_FILTER,
		createMultipleChoiceListFilter('billingPlan', 'Billing plan', basicFilterChoiceMapper(['LEGACY', 'STANDARD', 'ADVANCED'])),
		createMultipleChoiceListFilter('ipPools', 'IP pools', isIpPoolsLoading ? [] : ipPools.map(p => ({ label: p.name, value: p.id })), {
			appliedLabel: appliedFilters =>
				appliedFilters['ipPools']?.length > 0 && `IP pools is ${getSelectedIpPoolNames(ipPools, appliedFilters).join(' or ')}`
		}),
		createSingleChoiceFilter('suspended', 'Suspended', ['true', 'false']),
		createSingleChoiceFilter('suspicious', 'Suspicious', ['true', 'false']),
		createSingleChoiceFilter('newShop', 'New shop', ['true', 'false']),
		createSingleChoiceFilter('fastInstall', 'Fast install', ['true', 'false']),
		createSingleChoiceFilter('authenticatedDomain', 'Authenticated domain', ['true', 'false']),
		createSingleChoiceFilter('hasSuppressionTags', 'Suppression groups', ['true', 'false']),
		createBasicFilter('subscribersMin', 'Subscribers', {
			filterParameters: ['subscribersMin', 'subscribersMax'],
			appliedLabel: appliedFilters =>
				appliedFilters.subscribersMin &&
				appliedFilters.subscribersMax &&
				Number(appliedFilters.subscribersMin) <= Number(appliedFilters.subscribersMax) &&
				`Subscribers between ${appliedFilters.subscribersMin} and ${appliedFilters.subscribersMax}`,
			filter: (appliedFilters, onChange) => (
				<FormLayout>
					<TextField
						label="Greater than or equal to"
						type="number"
						min={0}
						value={appliedFilters.subscribersMin || ''}
						onChange={value => onChange({ subscribersMin: value })}
					/>
					<TextField
						label="Less than or equal to"
						type="number"
						min={0}
						value={appliedFilters.subscribersMax || ''}
						onChange={value => onChange({ subscribersMax: value })}
					/>
				</FormLayout>
			)
		}),
		INSTALL_DATE_FILTER
	];
}

RecentMarketingPlatformAccounts.propTypes = {
	searchParameters: PropTypes.shape({
		appliedFilters: PropTypes.object.isRequired,
		searchText: PropTypes.string.isRequired,
		recentPage: PropTypes.number.isRequired
	}).isRequired,
	setSearchParameters: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
};

export default function RecentMarketingPlatformAccounts({ searchParameters, setSearchParameters, limit }) {
	const { accounts, count, load, isLoading } = useRecentMarketingPlatformAccounts(
		searchParameters.appliedFilters,
		searchParameters.searchText,
		searchParameters.recentPage,
		limit
	);

	return (
		<RecentAccounts
			accountType="marketing platform"
			recentLimit={limit}
			filters={usePlatformFilters()}
			onSearchParametersChange={setSearchParameters}
			searchParameters={searchParameters}
			onLoadRecentAccounts={load}
			getAccountViewUrl={id => `/marketing-platform-accounts/${id}`}
			recentAccounts={accounts}
			isRecentAccountsLoading={isLoading}
			recentAccountsCount={count}>
			{item => (
				<Stack spacing="tight" vertical>
					<Stack alignment="center">
						<TextStyle variation="strong">
							{item.name} ({item.shop})
						</TextStyle>
						<div>{item.platformPlan}</div>
						<div>{item.billingStatus}</div>
					</Stack>
					<Stack spacing="tight" alignment="center">
						<TextStyle variation="subdued">
							<FormatNumber value={item.billedSubscribers} /> subscribers
						</TextStyle>
						{item.newShop && (
							<Badge size="small" tone="info">
								new shop:{' '}
								{new Date(item.shopCreatedAt).toLocaleDateString('en-US', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								})}
							</Badge>
						)}
						{item.fastInstall && (
							<Badge size="small" tone="warning">
								fast install
							</Badge>
						)}
						{item.suspended && (
							<Badge size="small" tone="warning">
								suspended
							</Badge>
						)}
						{item.suspicious && (
							<Badge size="small" tone="warning">
								<Stack alignment="center" spacing="extraTight">
									<span>kinda sus</span>
									<Sus />
								</Stack>
							</Badge>
						)}
						{item.complianceHold && (
							<Badge size="small" tone="attention">
								compliance: {item.complianceHoldStatus}
							</Badge>
						)}
						{item.trusted && (
							<Badge size="small" tone="success">
								trusted
							</Badge>
						)}
						{item.risky && (
							<Badge size="small" tone="warning">
								risky
							</Badge>
						)}
						{!item.trialCompleted && item.trialEndsAt && (
							<Badge size="small" tone="info">
								trial ends:{' '}
								{new Date(item.trialEndsAt).toLocaleDateString('en-US', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								})}
							</Badge>
						)}
					</Stack>
				</Stack>
			)}
		</RecentAccounts>
	);
}
