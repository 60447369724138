import { Card, FormatNumber, Stack } from '@segunosoftware/equinox';
import { Badge, Link, Modal, SkeletonBodyText, Tag } from '@shopify/polaris';
import { useState } from 'react';
import { useMarketingPlatformFacebookDetails, type FacebookPage } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { renderDate } from '../../utils/dateUtils';

const FACEBOOK_URL = 'https://www.facebook.com/';

export default function FacebookDetails({ accountId }: { accountId: string | number }) {
	const { facebookPages, isFacebookPagesLoading } = useMarketingPlatformFacebookDetails(accountId);

	if (!facebookPages || isFacebookPagesLoading) {
		return <SkeletonBodyText lines={3} />;
	}
	if (facebookPages.length === 0) {
		return <Card.Section>No Facebook pages found</Card.Section>;
	}
	return facebookPages.map((page: FacebookPage) => <FacebookPageView page={page} />);
}

function FacebookPageView({
	page: { id, enabled, tokenPresent, numImports, tags = [], forms, createdAt, updatedAt }
}: {
	page: FacebookPage;
}) {
	const [showForms, setShowForms] = useState(false);

	function onHideForms() {
		setShowForms(false);
	}

	function getStatusBadge() {
		return enabled ? (
			<Badge tone="success">enabled</Badge>
		) : tokenPresent ? (
			<Badge tone="attention">paused</Badge>
		) : (
			<Badge tone="new">disabled</Badge>
		);
	}

	return (
		<Card.Section
			key={id}
			title={
				<Stack alignment="center" spacing="tight">
					<div>
						<strong>Page</strong>:
					</div>
					<Link target="_blank" url={FACEBOOK_URL.concat(id)}>
						{id}
					</Link>
				</Stack>
			}
			actions={[{ content: 'View forms', onAction: () => setShowForms(true), disabled: showForms }]}>
			<Stack vertical spacing="none">
				<div>
					<strong>Status</strong>: {getStatusBadge()}
				</div>
				<div>
					<strong>Imports</strong>: {<FormatNumber value={numImports} />}
				</div>
				{tags.length > 0 && (
					<div>
						<strong>Tags</strong>:{' '}
						{tags.map(tag => (
							<Tag key={tag}>{tag}</Tag>
						))}
					</div>
				)}
				<div>
					<strong>Last updated</strong>: {renderDate(updatedAt)}
				</div>
				<div>
					<strong>Created</strong>: {renderDate(createdAt)}
				</div>
			</Stack>
			<Modal
				title="Facebook lead forms"
				open={showForms}
				onClose={onHideForms}
				secondaryActions={[{ content: 'Close', onAction: onHideForms }]}>
				{forms.length === 0 && <Modal.Section>No forms found</Modal.Section>}
				{forms.map(({ id, formId, tags = [], numImports, testPassed, testedAt, testPassedAt, createdAt, updatedAt }) => (
					<Modal.Section key={id}>
						<div>
							<strong>Form ID</strong>: {formId}
						</div>
						{tags.length > 0 && (
							<div>
								<strong>Tags</strong>:{' '}
								{tags.map(tag => (
									<Tag key={tag}>{tag}</Tag>
								))}
							</div>
						)}
						<div>
							<strong>Imports</strong>: {<FormatNumber value={numImports} />}
						</div>
						{testedAt && (
							<>
								<div>
									<strong>Last test result</strong>:{' '}
									{testPassed ? <Badge tone="success">passed</Badge> : <Badge tone="critical">failed</Badge>}
								</div>
								<div>
									<strong>Last tested at</strong>: {renderDate(testedAt)}
								</div>
								{testPassedAt && (
									<div>
										<strong>Test last passed at</strong>: {renderDate(testPassedAt)}
									</div>
								)}
							</>
						)}
						<div>
							<strong>Created at</strong>: {renderDate(createdAt)}
						</div>
						<div>
							<strong>Last updated</strong>: {renderDate(updatedAt)}
						</div>
					</Modal.Section>
				))}
			</Modal>
		</Card.Section>
	);
}
