import { ChoiceList, Filters } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { APP_PURCHASE_BILLING_STATUS, APP_PURCHASE_TYPE } from '../hooks/marketingplatform/useGlobalAppPurchases';

AppPurchaseFilters.propTypes = {
	identifier: PropTypes.string,
	type: PropTypes.string,
	status: PropTypes.string,
	onChangeFilter: PropTypes.func.isRequired,
	onClearAll: PropTypes.func.isRequired,
	showStatus: PropTypes.bool
};

export default function AppPurchaseFilters({ identifier, type, status, onChangeFilter, onClearAll, showStatus = true }) {
	const appliedFilters = [];

	if (type) {
		appliedFilters.push({
			key: 'type',
			label: `Type is ${type[0] === APP_PURCHASE_TYPE.TEMPLATE ? 'template' : 'campaign'}`,
			onRemove: () => onChangeFilter('type')
		});
	}

	if (showStatus && status) {
		appliedFilters.push({
			key: 'status',
			label: `Status is ${status[0] === APP_PURCHASE_BILLING_STATUS.ACTIVE ? 'active' : 'pending'}`,
			onRemove: () => onChangeFilter('status')
		});
	}

	const filters = [
		{
			key: 'type',
			label: 'Type',
			shortcut: true,
			pinned: true,
			filter: (
				<ChoiceList
					label="Type"
					choices={[
						{ label: 'Template', value: APP_PURCHASE_TYPE.TEMPLATE },
						{ label: 'Campaign', value: APP_PURCHASE_TYPE.CAMPAIGN }
					]}
					selected={type || ''}
					onChange={type => onChangeFilter('type', type)}
					placeholder="Select type"
					labelHidden
				/>
			)
		}
	];

	if (showStatus) {
		filters.push({
			key: 'status',
			label: 'Status',
			shortcut: true,
			filter: (
				<ChoiceList
					label="Status"
					choices={[
						{ label: 'Active', value: APP_PURCHASE_BILLING_STATUS.ACTIVE },
						{ label: 'Pending', value: APP_PURCHASE_BILLING_STATUS.PENDING }
					]}
					selected={status || ''}
					onChange={status => onChangeFilter('status', status)}
					placeholder="Select status"
					labelHidden
				/>
			)
		});
	}

	return (
		<Filters
			appliedFilters={appliedFilters}
			filters={filters}
			onClearAll={onClearAll}
			queryValue={identifier ?? ''}
			onQueryChange={identifier => onChangeFilter('identifier', identifier)}
			onQueryClear={() => onChangeFilter('identifier')}
		/>
	);
}
