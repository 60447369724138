import { Form, FormLayout, Layout, Modal, TextField } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBulkAccount } from '../../hooks/bulk/useBulkAccount';
import { useBulkAccountActions } from '../../hooks/bulk/useBulkAccountActions';
import { useUser } from '../../hooks/useUser';
import AccountView, { isLeadOrAdmin } from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import JobsView from '../JobsView';
import BulkBillingAndUsageSummary from './BulkBillingAndUsageSummary';
import DiscountSets from './DiscountSets';

export default function BulkAccountView() {
	const { accountId } = useParams();
	const { account, jobs, onLoadJobs, isJobsLoading } = useBulkAccount(accountId);
	const { setContact, isSettingContact } = useBulkAccountActions(accountId);
	const user = useUser();

	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	const [showSetContactModal, setShowSetContactModal] = useState(false);
	const [contactName, setContactName] = useState('');
	const [contactEmail, setContactEmail] = useState('');

	const billingActions = [];

	function addBillingOptions(hasPermissions, options) {
		if (hasPermissions) {
			billingActions.push(...options);
			billingActions.sort((a, b) => a.content.localeCompare(b.content));
		}
	}

	function onShowSetContactModal() {
		setContactName(account.contactName);
		setContactEmail(account.contactEmail);
		setShowSetContactModal(true);
	}

	if (account) {
		addBillingOptions(isLeadOrAdmin(user), [
			{
				content: 'Issue app credit',
				onAction: () => setShowAppCreditModal(true)
			}
		]);
		addBillingOptions(true, [{ content: 'Set contact', onAction: onShowSetContactModal, disabled: isSettingContact }]);
	}

	function performSetContact() {
		setContact({ contactName, contactEmail });
	}

	return (
		<AdminPage
			title={account ? account.name : 'Bulk account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			actionGroups={[{ title: 'More actions', actions: billingActions }]}>
			<Layout sectioned>
				<AccountView account={account} app="BULK" />
				{account && <BulkBillingAndUsageSummary account={account} />}
				<DiscountSets accountId={Number(accountId)} />
				<JobsView jobs={jobs} onLoad={onLoadJobs} isLoading={isJobsLoading} />
			</Layout>
			{account && <AppCreditModal account={account} app="BULK" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
			{account && (
				<Modal
					title="Set contact"
					primaryAction={{
						content: 'Set',
						onAction: performSetContact,
						loading: isSettingContact
					}}
					secondaryActions={[{ content: 'Cancel', onAction: () => setShowSetContactModal(false) }]}
					onClose={() => setShowSetContactModal(false)}
					open={showSetContactModal}>
					<Modal.Section>
						<Form onSubmit={performSetContact}>
							<FormLayout>
								<TextField label="Name" autoComplete="off" value={contactName} onChange={setContactName} autoFocus />
								<TextField type="email" label="Email" autoComplete="off" value={contactEmail} onChange={setContactEmail} />
							</FormLayout>
						</Form>
					</Modal.Section>
				</Modal>
			)}
		</AdminPage>
	);
}
